<div class="header-row">
    <h1>Projects overview</h1>
    <nuc-button-bar>
        @if (permissions.POST_PROJECT | userIsAllowedTo) {
            <nuc-button-primary text="Add project"
                                (click)="openProjectForm()">
            </nuc-button-primary>
        }
    </nuc-button-bar>
</div>

@if (permissions.GET_PROJECTS | userIsAllowedTo) {
    <nuc-table [tableDataProvider]="projectDataProvider"
           [columns]="columns"
           [actions]="actions"
           [disableSort]="!!applyLastViewedItemIds"
           [emptyStateTitle]="searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'"
           (itemClicked)="onTableRowClicked($event)"
           [tableId]="tableId"
           [prefixStorageKey]="storageKey"
           (sortOptionChange)="onSortOptionChanged($event)"
           (actionClicked)="handleTableAction($event)">
        <ng-container button-bar-left>

            <div class="button-bar-left-container">
                <nuc-input [(data)]="projectDataProvider.searchValue"
                           prefix="nucicon_search"
                           (debounceValueChanged)="onSearchBarValueUpdated()"
                           placeholder="Search projects by name">
                </nuc-input>

                @if (lastViewedItemIds?.length) {
                    <last-viewed-items-checkbox [text]="'Only show last viewed projects'"
                                                [context]="context">
                    </last-viewed-items-checkbox>
                }

                <div class="vertical-line"></div>
            </div>

        </ng-container>
        <ng-container button-bar-right>
            <paginator [viewId]="tableId"
                       [disableNextPage]="projectDataProvider.disableNextPage"
                       [loading]="projectDataProvider.loading">
            </paginator>
        </ng-container>
    </nuc-table>
} @else {
    <no-permission-state-component></no-permission-state-component>
}

