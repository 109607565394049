import {Component, EventEmitter, Output} from '@angular/core';
import {AnimationEvent} from '@angular/animations';
import {Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {
    DEFAULT_CONTAINER_ANIMATION_SPEED,
    DEFAULT_CONTENT_ANIMATION_DELAY,
    DEFAULT_CONTENT_ANIMATION_SPEED,
    subMenuAnimations
} from '../menu.animations';
import {IMenuComponent} from '../menu-component.interface';

@Component({
    selector: 'rl-sub-menu-container',
    templateUrl: './sub-menu-container.component.html',
    styleUrls: ['./sub-menu-container.component.scss'],
    animations: [subMenuAnimations.container, subMenuAnimations.content, subMenuAnimations.change],
    standalone: false
})
export class SubMenuContainerComponent implements IMenuComponent {

    @Output() public animationStateChanged = new EventEmitter<AnimationEvent>();

    public contentOutAnimationDone = new Subject<void>();
    public contentInAnimationDone = new Subject<void>();
    public inAnimationDone = new Subject<void>();
    public outAnimationDone = new Subject<void>();

    public contentState: 'void' | 'changing-in' | 'changing-out' = 'void';
    public containerState: 'void' | 'open' | 'close' = 'open';

    public containerSpeed: number = DEFAULT_CONTAINER_ANIMATION_SPEED;
    public contentSpeed: number = DEFAULT_CONTENT_ANIMATION_SPEED;
    public contentDelay: number = DEFAULT_CONTENT_ANIMATION_DELAY;

    public onAnimationDone(event: AnimationEvent): void {
        if (event.triggerName === 'changeAnimation' && event.toState === 'changing-out') {
            this.contentOutAnimationDone.next();
        }
        if (event.triggerName === 'changeAnimation' && event.toState === 'changing-in') {
            this.contentInAnimationDone.next();
        }
        if (event.triggerName === 'containerAnimation' && event.toState === 'close') {
            this.outAnimationDone.next();
        }
        if (event.triggerName === 'containerAnimation' && event.toState === 'open') {
            this.inAnimationDone.next();
        }
        this.animationStateChanged.emit(event);
    }

    public animateContentOut(): Observable<void> {
        this.contentState = 'changing-out';
        return this.contentOutAnimationDone.asObservable();
    }

    public animateContentIn(): Observable<void> {
        this.contentState = 'changing-in';
        return this.contentInAnimationDone.asObservable();
    }

    public animateOut(speed): Observable<void> {
        if (speed) {
            this.updateAnimationTimings(speed);
            this.outAnimationDone
                .pipe(take(1))
                .subscribe(() => this.resetAnimationTimings());
        }
        this.containerState = 'close';
        return this.outAnimationDone.asObservable();
    }

    public animateIn(speed?: number): Observable<void> {
        if (speed) {
            this.updateAnimationTimings(speed);
            this.outAnimationDone
                .pipe(take(1))
                .subscribe(() => this.resetAnimationTimings());
        }
        this.containerState = 'open';
        return this.inAnimationDone.asObservable();
    }

    private resetAnimationTimings(): void {
                this.containerSpeed = DEFAULT_CONTAINER_ANIMATION_SPEED;
                this.contentSpeed = DEFAULT_CONTENT_ANIMATION_SPEED;
                this.contentDelay = DEFAULT_CONTENT_ANIMATION_DELAY;
    }

    private updateAnimationTimings(speed: number): void {
        this.containerSpeed = speed;
        this.contentSpeed = speed * .6;
        this.contentDelay = speed * .6;
    }
}
