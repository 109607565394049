import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../classes/toaster.class';
import {AppConstants} from '../../../app.constants';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {
    EColumnDataType,
    ESelectionMode,
    ISortOptionEvent, ITableAction,
    ITableColumn
} from '@relayter/rubber-duck';
import {takeUntil} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {Subject, Subscription} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AuditTrailService} from '../../../api/services/audit-trail.service';
import {AuditTrailModel} from '../../../models/api/audit-trail.model';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';

@Component({
    selector: 'rl-audit-trail-overview',
    templateUrl: 'audit-trail-overview.component.html',
    styleUrls: ['audit-trail-overview.component.scss'],
    providers: [PaginatorService],
    standalone: false
})

export class AuditTrailOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'audit-trail-overview';
    public items: AuditTrailModel[];
    public isLoading = false;
    public total: number;
    public subscription: Subscription;

    public columns: ITableColumn[] = [
        {
            title: 'API Path',
            key: 'path',
            sortProperty: 'path',
            sortDuplicates: true,
        },
        {
            title: 'HTTP Method',
            key: 'method',
            sortProperty: 'method',
            sortDuplicates: true,
        },
        {
            title: 'User',
            key: 'userFullName',
            sortProperty: 'userFullName',
            sortDuplicates: true,
        },
        {
            title: 'Date',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.AUDIT_TRAIL)
        }
    ];
    public actions: ITableAction[];
    public pageSize: number;
    public pageIndex: number;
    private onDestroySubject = new Subject<void>();
    public disableNextPage = true;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                private auditTrailService: AuditTrailService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;

                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getAuditTrail();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
        this.setPageIndex();
    }

    public resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, 1); // reset pageIndex
    }

    /**
     * Gets roles from API
     */
    private getAuditTrail(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_AUDIT_TRAIL)) return;

        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);

        this.subscription = this.auditTrailService.find(
            this.pageSize, null, this.tableSortOptions, cursor, this.searchValue)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);

        this.resetPageIndex();
    }

    /**
     * Create a MatrixUrlModel so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Updates the url when properties changes (pageIndex / pageSize)
     * Called by onPaginationChanged, reload, onTableOptionsChanged
     * Forces an update of the data through the route params subscription
     */
    protected updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.searchValue);
        this.router.navigate([AppConstants.CONTEXT_URL.SETTINGS,
            AppConstants.CONTEXT_URL.AUDIT_TRAIL, matrixUrl], {replaceUrl: true});
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;
        this.setPageIndex();
    }

    protected readonly ESelectionMode = ESelectionMode;
}
