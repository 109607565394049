<form [formGroup]="formGroup">

    <nuc-form-field label="Content rule name">
        <nuc-input formControlName="name" placeholder="Provide a name for the content rule"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Collection">
        <nuc-dropdown formControlName="collection" [items]="collections" placeholder="Select the collection this rule applies to"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Values">
        <nuc-suggestive-input formControlName="values" [options]="tagOptions" (searchTermChange)="onTagChanged($event)" placeholder="When to apply this rule, if empty the rule is always applied"></nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Content groups">
        <nuc-dropdown-multiselect
            formControlName="groups"
            [items]="groups"
            placeholder="Select the content group(s) this rule applies to">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field class="access-level-container">
        <p class="section-header">Access levels</p>
        <p>The content groups in this content rule have permission to read the content, do you want to give an extra permission for editing content?</p>
        <nuc-checkbox text="Give permission to edit content" formControlName="writeAccess"></nuc-checkbox>
    </nuc-form-field>

</form>
