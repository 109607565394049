import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {
    ConnectionPositionPair,
    OriginConnectionPosition,
    Overlay,
    OverlayConfig,
    OverlayConnectionPosition,
    OverlayRef
} from '@angular/cdk/overlay';
import {AnimationEvent} from '@angular/animations';
import {hasModifierKey, ESCAPE} from '@angular/cdk/keycodes';
import {TemplatePortal} from '@angular/cdk/portal';
import {Subject, Subscription} from 'rxjs';
import {EMenuItemPosition, MenuItem} from '../models/ui/menu-item.model';
import {NavigationStart, Router, UrlTree} from '@angular/router';
import {distinctUntilChanged, filter, take} from 'rxjs/operators';
import {MenuSection} from '../models/ui/menu-section.model';
import {MenuConstants} from '../pages/relayter/menu.constants';
import {IMenuComponent} from './menu-component.interface';
import {NotificationsDataService} from '../api/services/notifications.data-service';

const enum EMenuState {OPENING, OPEN, CLOSING, CLOSED}

@Component({
    selector: 'rl-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class MenuComponent implements OnInit, OnDestroy {
    public readonly MENU_COMPONENTS = MenuConstants.MENU_COMPONENTS;

    // Templates to inject in the portal
    @ViewChild('subMenu', {static: true, read: TemplateRef}) public subMenu: TemplateRef<any>;
    @ViewChild('notificationsSubMenu', {static: true, read: TemplateRef}) public notificationsSubMenu: TemplateRef<any>;

    // Currently open menu item
    @ViewChild('activeItem', {static: false}) public activeItem: IMenuComponent;

    // Reference to origin rectangle to which attach the sub menu
    @ViewChild('origin', {static: true}) public origin: HTMLElement;
    // Container Ref needed to create the portal
    @ViewChild('origin', {static: true, read: ViewContainerRef}) public container: ViewContainerRef;

    @Input() public sections: MenuSection[] = [];

    private originPos: OriginConnectionPosition = {
        originX: 'end',
        originY: 'top'
    };
    private overlayPos: OverlayConnectionPosition = {
        overlayX: 'start',
        overlayY: 'top'
    };

    private position: ConnectionPositionPair = new ConnectionPositionPair(
        this.originPos, this.overlayPos, 0, 0);
    private positions = [this.position];

    private overlayRef: OverlayRef;
    private backdropSubscription = Subscription.EMPTY;
    private keydownSubscription = Subscription.EMPTY;

    private state: EMenuState = EMenuState.CLOSED;
    private overlayClosed = new Subject();

    public selectedMenuItem: MenuItem;

    public bottomMenuItems: MenuItem[] = [];
    public topMenuItems: MenuItem[] = [];

    public rootRoute: string;

    constructor(private _overlay: Overlay, private router: Router, public notificationDataService: NotificationsDataService) {

        this.setCurrentRoute(this.router.parseUrl(this.router.url));

        this.router.events.pipe(
            distinctUntilChanged(),
            filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => this.setCurrentRoute(this.router.parseUrl(event.url)));

        this.overlayClosed.subscribe(() => this.overlayRef.detach());

    }

    public ngOnInit(): void {
        this.buildMenuItems();
    }

    /**
     * On menu item clicked:
     *  - If there is no sub items, if necessary, navigate to page and close the sub menu
     *  - if there are, open the sub menu or change the content if it's already open
     */
    public handleItemClick(menuItem: MenuItem): void {
        if (this.selectedMenuItem !== menuItem) {
            MenuComponent.hasComponent(menuItem) ?
                this.handleComponentClick(menuItem) :
                this.navigateToRoute(menuItem);
        }
    }

    public handleComponentClick(menuItem: MenuItem): void {
        switch (this.state) {
            case EMenuState.CLOSING:
            case EMenuState.CLOSED:
                this.attachToOverlay(menuItem);
                break;
            case EMenuState.OPEN:
            case EMenuState.OPENING:
                this.replaceMenuItem(menuItem);
        }
    }

    private replaceMenuItem(menuItem: MenuItem): void {
        if (this.selectedMenuItem) {
            this.selectedMenuItem.component === menuItem.component ?
                this.handleSameComponentClick(menuItem) :
                this.handleDifferentComponentClick(menuItem);
        }
    }

    /**
     * Quickly animates out the current component, then animates in the clicked component
     */
    private handleDifferentComponentClick(menuItem: MenuItem): void {
        this.activeItem.animateOut(100).pipe(
            take(1)
        ).subscribe(() => {
            this.selectedMenuItem = menuItem;
            this.attachToOverlay(menuItem);
        });
    }

    /**
     * Fades content current out, then fades clicked content in
     */
    private handleSameComponentClick(menuItem: MenuItem): void {
        this.activeItem.animateContentOut().pipe(
            take(1)
        ).subscribe(() => {
            this.selectedMenuItem = menuItem;
            this.activeItem.animateContentIn();
        });
    }

    /**
     * Attaches a template reference to the overlay.
     * The correct template ref is inferred by the component type specified in the menuItem.
     */
    private attachToOverlay(menuItem: MenuItem): void {
        const templatePortal = new TemplatePortal(this.getTemplateFromMenuItem(menuItem), this.container);
        if (!this.overlayRef) {
            this.createOverlay();
        }

        this.overlayRef.attachments().pipe(
            take(1)
        ).subscribe(() => this.selectedMenuItem = menuItem);

        this.overlayRef.hasAttached() ?
            this.replacePortal(templatePortal) :
            this.overlayRef.attach(templatePortal);
    }

    /**
     * Animates out the currently active component. Waits for animation to finish,
     * then attaches new template to overlay (which starts animating in)
     */
    private replacePortal(templatePortal): void {
        this.overlayClosed.pipe(
            take(1)
        ).subscribe(() => {
            if (!this.overlayRef.hasAttached()) {
                this.overlayRef.attach(templatePortal);
            }
        });

        this.activeItem.animateOut();
    }

    /**
     * Reads and set first path in route
     */
    private setCurrentRoute(url: UrlTree): void {
        // When navigation fails url is not defined
        if (url && url.root.children.primary) {
            this.rootRoute = '/' + url.root.children.primary.segments[0].path;
        }
    }

    private navigateToRoute(menuItem: MenuItem): void {

        this.state === EMenuState.OPENING || this.state === EMenuState.OPEN ?
            this.closeAndUnsetSelectedItem() :
            this.selectedMenuItem = null;

        if (menuItem.route !== this.router.url) {
            this.router.navigate([menuItem.route]);
        }
    }

    public closeAndUnsetSelectedItem(): void {
        this.activeItem.animateOut().pipe(
            take(1)
        ).subscribe(() => this.selectedMenuItem = null);
    }

    /**
     * Returns true if the menuItem needs to load a component (instead of just navigating to url)
     */
    private static hasComponent(menuItem: MenuItem): boolean {
        return !!menuItem.component;
    }

    /**
     * Returns a template ref based on what is specified in the component property of the menuItem
     */
    private getTemplateFromMenuItem(menuItem: MenuItem): TemplateRef<any> {
        switch (menuItem.component) {
            case this.MENU_COMPONENTS.NOTIFICATIONS:
                return this.notificationsSubMenu;
            case this.MENU_COMPONENTS.SUB_MENU:
                return this.subMenu;
            default:
                throw new Error(`Unhandled menu component: ${menuItem.component}`);
        }
    }

    /**
     * Loads menu items from constants
     */
    private buildMenuItems(): void {
        const items = this.sections
            .map((section) => section.items)
            .reduce((acc, menuItem) => acc.concat(menuItem), []);

        this.topMenuItems = items.filter((menuItem) => menuItem.position === EMenuItemPosition.TOP);
        this.bottomMenuItems = items.filter((menuItem) => menuItem.position === EMenuItemPosition.BOTTOM);
    }

    /**
     * Creates overlay, sets up keydown events and backdrop subscriptions
     */
    private createOverlay(): void {
        this.overlayRef = this._overlay.create(this.buildConfig());

        this.backdropSubscription =
            this.overlayRef.backdropClick()
                .subscribe(() => this.closeAndUnsetSelectedItem());

        this.keydownSubscription =
            this.overlayRef.keydownEvents()
                .subscribe((event: KeyboardEvent) => {
                    if (event.keyCode === ESCAPE && !hasModifierKey(event) && this.state === EMenuState.OPEN) {
                        event.preventDefault();
                        this.closeAndUnsetSelectedItem();
                    }
                });
    }

    private buildConfig(): OverlayConfig {
        return new OverlayConfig({
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this.origin)
                .withPositions(this.positions),
            hasBackdrop: true,
            height: '100vh',
            minHeight: '100vh',
            backdropClass: 'menu-backdrop',
            panelClass: 'menu-overlay-panel'
        });
    }

    /**
     * Listens to animation changes to update the container state
     */
    private onContainerAnimationChange(event: AnimationEvent): void {
        if (event.toState === 'close' && event.phaseName === 'start' && this.state === EMenuState.OPEN) {
            this.state = EMenuState.CLOSING;
        }
        if (event.toState === 'close' && event.phaseName === 'done' && this.state === EMenuState.CLOSING) {
            this.state = EMenuState.CLOSED;
            this.overlayClosed.next(null);
        }
        if (event.toState === 'open' && event.phaseName === 'start' && this.state === EMenuState.CLOSED) {
            this.state = EMenuState.OPENING;
        }
        if (event.toState === 'open' && event.phaseName === 'done' && this.state === EMenuState.OPENING) {
            this.state = EMenuState.OPEN;
        }
    }

    public onAnimationStateChange(event: AnimationEvent): void {
        if (event.triggerName === 'containerAnimation') {
            this.onContainerAnimationChange(event);
        }
    }

    public ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        this.keydownSubscription.unsubscribe();
        this.backdropSubscription.unsubscribe();
    }
}
