import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {MasterPageModel} from '../../models/api/master-page.model';
import {ApiConstants} from '../api.constant';

@Injectable({providedIn: 'root'})
export class MasterPageApiService extends ApiService<MasterPageModel> {
    constructor() {
        super([ApiConstants.API_GROUP_MASTER_PAGES], MasterPageModel);
    }
}
