import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CollectionOptionsModel} from '../../../models/api/collection-options.model';
import {TIME_OUT_TYPES, Toaster, ToastOptionModel} from '../../../classes/toaster.class';
import {AppConstants} from '../../../app.constants';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {InviteUserFormComponent} from '../../../forms/invite-user-form/invite-user-form.component';
import {TabBarRoutableItem} from '../team-settings/tab-bar-routable-item.model';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {Subject} from 'rxjs';
import {UserManagementDataService} from './user-management.data-service';
import {ErrorConstants} from '../../../api/error.constants';

@Component({
    selector: 'rl-user-management',
    templateUrl: 'user-management.component.html',
    styleUrls: ['user-management.component.scss'],
    providers: [UserManagementDataService],
    standalone: false
})
export class UserManagementComponent implements OnInit, OnDestroy {
    public permissions = AppConstants.PERMISSIONS;
    public options: CollectionOptionsModel = new CollectionOptionsModel();
    public isLoading: boolean = false;

    public usersTab = new TabBarRoutableItem(
        'Users',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.USER_MANAGEMENT, AppConstants.CONTEXT_URL.USERS_OVERVIEW],
        [AppConstants.PERMISSIONS.GET_USERS]
    );
    public invitesTab = new TabBarRoutableItem(
        'Pending invites',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.USER_MANAGEMENT, AppConstants.CONTEXT_URL.PENDING_INVITES],
        [AppConstants.PERMISSIONS.GET_INVITES]
    );
    // we always want to show the tabs but with no permission state inside the view
    public tabs = [this.usersTab, this.invitesTab];
    public activeTab: TabBarRoutableItem;
    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                private fullModalService: FullModalService,
                private userManagementDataService: UserManagementDataService) {
    }

    public ngOnInit(): void {
        if (this.tabs.length) {
            this.setActiveTabFromUrl(this.router.routerState.snapshot.url);

            // This reloads the users overview if breadcrumb is clicked
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd),
                filter((event) =>
                    event.urlAfterRedirects === `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.USER_MANAGEMENT}`),
                takeUntil(this.onDestroySubject),
            ).subscribe((event: NavigationEnd) => {
                this.router.navigate(this.tabs[0].url, {replaceUrl: true});
                this.setActiveTabFromUrl(event.url);
            });
        }
    }

    public openInviteUserModal(): void {
        const config = new FullModalConfig(
            'Invite new user',
            'Fill in the information below to invite a new user.');

        this.fullModalService.open(InviteUserFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                if (result.email) {
                    Toaster.success(`${result.email} invited.`);
                    this.userManagementDataService.announceInvitesRefresh();
                } else if (result.error) {
                    if (result.error.code === ErrorConstants.API_ERROR_CODES.BAD_REQUEST_USER_ALREADY_IN_OTHER_TEAM ||
                    result.error.code === ErrorConstants.API_ERROR_CODES.BAD_REQUEST_USER_ALREADY_IN_TEAM) {
                        const toastOptions = new ToastOptionModel();
                        toastOptions.timeout = TIME_OUT_TYPES.LONG;
                        Toaster.handleApiWarning(result.error, toastOptions);
                    } else {
                        Toaster.handleApiError(result.error);
                    }
                }
            }
        });
    }

    public onActiveTabChanged(activeTab: ITabBarItem): void {
        if (activeTab) {
            const routableTab = activeTab as TabBarRoutableItem;
            this.router.navigate(routableTab.url, {replaceUrl: true});
        }
    }

    private setActiveTabFromUrl(url: string): void {
        const tabUrl = url?.split(';')[0]; // Get url without matrix parameters
        const tabFromRoute = this.tabs.find((tab) => tab.urlString === tabUrl);
        this.activeTab = tabFromRoute ? tabFromRoute : this.tabs[0];
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
