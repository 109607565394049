import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {PackageRuleModel, PackageSetupModel} from '../../../../models/api/package-setup.model';
import {ActivatedRoute, Router} from '@angular/router';
import {EPackageSetupJobType, PackageSetupService} from '../../../../api/services/package-setups.service';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode, ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent, ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {IPackageRuleFormData, PackageRuleFormComponent} from '../../../../forms/package-rule-form/package-rule-form.component';
import {finalize, takeUntil} from 'rxjs/operators';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';

@Component({
    selector: 'rl-package-setup-details',
    templateUrl: './package-setup-details.component.html',
    styleUrls: ['./package-setup-details.component.scss'],
    providers: [PaginatorService],
    standalone: false
})
export class PackageSetupDetailsComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId: string;
    public readonly viewId = 'package-setup-details-table';

    public defaultColumns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        size: EColumnSize.LARGE,
        sortProperty: 'name'
    }, {
        title: 'Conditions',
        key: 'conditions',
        format: (value) => value ? value.length : 0
    }];
    public columns: ITableColumn[] = [];
    public pageIndex: number;
    public pageSize: number;
    public totalItems: number;
    public actions: ITableAction[] = [];

    public ESelectionMode = ESelectionMode;

    public packageSetupSubscription: Subscription;
    public packageSetupRulesSubscription: Subscription;
    public packageSetup: PackageSetupModel;
    public rules: PackageRuleModel[] = [];
    private packageSetupId: string;
    public loading: boolean;
    public disableNextPage = true;
    private onDestroySubject = new Subject<void>();

    constructor(private packageSetupService: PackageSetupService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService,
                private route: ActivatedRoute,
                private monitoredJobsService: MonitoredJobsService,
                private router: Router,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.initPaginator();
        this.setTableActions();
        this.getPackageSetup();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        this.packageSetupId = params['packageSetupId'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : '';
        this.searchValue = params['search'];

        this.setPageIndex(pageIndex);
    }

    private initPaginator(): void {
        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;

                this.updateUrl();
                this.getPackageSetupRules();
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_PACKAGE_SETUP_RULE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.POST_PACKAGE_SETUP_JOB,
            AppConstants.PERMISSIONS.COPY_PACKAGE_SETUP_RULE])) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.COPY);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_PACKAGE_SETUP_RULE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

        if (!this.actions.length) this.actions = null;
    }

    public updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.EXPORT_SETUPS,
            AppConstants.CONTEXT_URL.CAMPAIGN_PACKAGE_SETUPS,
            this.packageSetup._id,
            this.createMatrixUrl()
        ], {replaceUrl: true});
    }

    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.viewId, pageIndex);
    }

    private getPackageSetup(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_SETUPS)) {
            return;
        }

        this.packageSetupSubscription = this.packageSetupService.getPackageSetup(this.packageSetupId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (packageSetup) => {
                    this.initPackageSetup(packageSetup);
                    this.packageSetup = packageSetup;
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.router.navigate([AppConstants.PACKAGE_SETUPS_PATH]);
                }
            });
    }

    private getPackageSetupRules(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_SETUP_RULES)) {
            return;
        }

        if (this.packageSetupRulesSubscription) this.packageSetupRulesSubscription.unsubscribe();

        this.packageSetupRulesSubscription = this.packageSetupService.getPackageSetupRules(this.packageSetupId,
            this.pageSize, (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder, this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(result => {
                this.rules = result.items;
                this.totalItems = result.total;
                this.disableNextPage = this.pageIndex * this.pageSize >= this.totalItems;
            }, Toaster.handleApiError);
    }

    private initPackageSetup(packageSetup: PackageSetupModel): void {
        // Column set up based on package setup material data fields
        this.tableId = `${this.viewId}-${packageSetup._id}`;

        const dataFieldColumns = packageSetup.materialDataFields.map((dataField) => {
            return {
                title: dataField.name,
                key: `dataFields.${dataField.fieldName}`,
                type: EColumnType.DEFAULT,
                sortProperty: `dataFields.${dataField.fieldName}`
            } as ITableColumn;
        });
        this.columns = [...this.defaultColumns, ...dataFieldColumns];
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const clickedItem = event.item as PackageRuleModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.loading = true;
            this.packageSetupService.getPackageSetup(this.packageSetupId)
                .pipe(
                    finalize(() => this.loading = false),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe((packageSetup: PackageSetupModel) => {
                        const packageRule = packageSetup.rules.find((rule) => rule._id === clickedItem._id);
                        this.openPackageRuleModal(packageRule);
                    },
                    (error) => Toaster.handleApiError(error));
        }

        if (event.action === AppConstants.TABLE_ACTION_TYPES.COPY) {
            this.openCopyDialog(clickedItem);
        }

        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteTableRowDialog(clickedItem);
        }
    }

    public onCreatePackageRuleClicked(): void {
        this.openPackageRuleModal();
    }

    private openPackageRuleModal(packageRule?: PackageRuleModel): void {
        const modalTitle = packageRule ? 'Edit package rule' : 'Add package rule';
        const modalConfig = new FullModalConfig(modalTitle,
            'Select your material that goes into the package and specify material package information.');
        modalConfig.confirmClose = true;
        modalConfig.data = {packageSetup: this.packageSetup, packageRule} as IPackageRuleFormData;

        const modalRef = this.fullModalService.open(PackageRuleFormComponent, modalConfig);
        modalRef.afterClosed().subscribe((template) => template ? this.setPageIndex() : null);
    }

    private openCopyDialog(rule: PackageRuleModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Duplicate ${rule.name}`,
            'You are about to duplicate a package rule. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyPackageRule(rule._id);
        });
    }

    private copyPackageRule(ruleId: string): void {
        const jobData = {
            packageSetupId: this.packageSetupId,
            ruleId
        };

        this.loading = true;
        this.packageSetupService.postJob(EPackageSetupJobType.PACKAGE_SETUP_RULE_COPY_JOB, jobData)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getItemMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.setPageIndex();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    private openDeleteTableRowDialog(packageRule: PackageRuleModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete package rule', `Please confirm that you wish to delete ${packageRule.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deletePackageRule(packageRule._id);
        });
    }

    private deletePackageRule(packageRuleId: string): void {
        this.loading = true;
        this.packageSetupService.deletePackageSetupRule(this.packageSetup._id, packageRuleId)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe(
                () => {
                    Toaster.success('Package setup rule removed successfully');
                    this.setPageIndex();
                },
                (error) => Toaster.handleApiError(error));
    }

    public onSortOptionChange(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex(AppConstants.PAGE_INDEX_DEFAULT);
    }
}
