import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NUCComponentsModule,
    NUCInputsModule
} from '@relayter/rubber-duck';
import {ComponentsModule} from '../../components/components.module';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {FileTypeUtil} from '../../classes/file-type.util';
import {distinctUntilChanged, finalize, map, startWith} from 'rxjs/operators';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {
    AfterEffectsProjectFilesApiService,
    EAfterEffectsProjectFilesJobType,
    IAddAfterEffectsProjectFileJobData, IAfterEffectsProjectFileJobData, IUpdateAfterEffectsProjectFileJobData
} from '../../api/services/after-effects-project-files.api.service';
import {JobModel} from '../../models/api/job.model';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {AfterEffectsProjectFileModel} from '../../models/api/after-effects-project-file.model';
import {EFormStatus} from '../../app.enums';

class AfterEffectsProjectFileFormGroup {
    name: FormControl<string>
}

interface IAfterEffectsProjectFileModalData {
    afterEffectsProjectFile?: AfterEffectsProjectFileModel;
}

@Component({
    selector: 'after-effects-project-file-form-component',
    templateUrl: 'after-effects-project-file-form.component.html',
    styleUrls: ['after-effects-project-file-form.component.scss'],
    imports: [
        ComponentsModule,
        NUCComponentsModule,
        NUCInputsModule,
        ReactiveFormsModule
    ]
})
export class AfterEffectsProjectFileFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private fullModalService = inject(FullModalService);
    private afterEffectsProjectFilesService = inject(AfterEffectsProjectFilesApiService);
    private modalData: IAfterEffectsProjectFileModalData = inject(NUC_FULL_MODAL_DATA, {optional: true});

    public saveButton: ButtonConfig;
    public formGroup: FormGroup<AfterEffectsProjectFileFormGroup>

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);
    public uploadUpdate: IUploadUpdate;
    public fileTypeCategories = [FileTypeUtil.CATEGORIES.AFTER_EFFECTS_PROJECT_FILE];

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
        this.trackStatus();
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Close');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);

        const cancel = new FullModalActionModel(cancelButton);
        const save = new FullModalActionModel(this.saveButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        save.observable.subscribe(() => this.onSaveAfterEffectsProjectFile());

        const actions = [cancel, save];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        this.formGroup = new FormGroup<AfterEffectsProjectFileFormGroup>({
            name: new FormControl<string>(this.modalData.afterEffectsProjectFile?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });
    }

    private trackStatus(): void {
        combineLatest([
            this.formGroup.statusChanges
                .pipe(
                    startWith(this.formGroup.status),
                    distinctUntilChanged(),
                    map((status) => status === EFormStatus.VALID)
                ),
            this.uploadUpdateSubject
                .pipe(
                    map((uploadUpdate) => {
                        this.uploadUpdate = uploadUpdate;
                        return uploadUpdate?.status === EUploadStatus.Done || (this.modalData.afterEffectsProjectFile && !uploadUpdate);
                    })
                )
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    public onSaveAfterEffectsProjectFile(): void {
        this.saveButton.loading = true;

        const name = this.formGroup.value.name.trim();
        const s3Key = this.uploadUpdate?.s3Key;
        const jobData: IAfterEffectsProjectFileJobData = this.modalData.afterEffectsProjectFile ? {
            itemId: this.modalData.afterEffectsProjectFile._id,
            name, s3Key
        } as IAddAfterEffectsProjectFileJobData : {name, s3Key} as IUpdateAfterEffectsProjectFileJobData;

        if (jobData.s3Key) {
            const jobType = this.modalData.afterEffectsProjectFile ? EAfterEffectsProjectFilesJobType.UPDATE_AFTER_EFFECTS_PROJECT_FILE_JOB :
                EAfterEffectsProjectFilesJobType.ADD_AFTER_EFFECTS_PROJECT_FILE_JOB;
            this.afterEffectsProjectFilesService.postJob(jobType, jobData)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (result) => {
                        if (result instanceof JobModel) {
                            ARLogger.debug('After Effects project file Job scheduled: ' + result._id);
                            this.fullModalService.close({jobId: result._id});
                        }
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.afterEffectsProjectFilesService.patch(this.modalData.afterEffectsProjectFile._id, {name})
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('After Effects project file updated successfully');
                        this.fullModalService.close({});
                    },
                    error: Toaster.handleApiError
                });
        }
    }
}
