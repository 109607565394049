import {autoserialize} from 'cerialize';

export class TeamSettingsDataFilterValue {
    value: string;
    property: string;
}
export class TeamSettingsDataFilterModel {
    @autoserialize _id: string;
    @autoserialize name: string;
    @autoserialize value: TeamSettingsDataFilterValue[];
}

export class TeamSettingModel {
    @autoserialize public _id: string;
    @autoserialize public dataFilters: TeamSettingsDataFilterModel[];
}
