import {Component, inject, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    DropdownComponent,
    FullModalService,
    InputComponent,
    NUC_FULL_MODAL_DATA,
    NUCButtonsModule,
    NUCComponentsModule,
    NUCInputsModule
} from '@relayter/rubber-duck';
import {AnimatedContentRulesetAssetModel, AnimatedContentRulesetRuleModel} from '../../../models/api/animated-content-ruleset.model';
import {AfterEffectsProjectFileCompositionModel} from '../../../models/api/after-effects-project-file.model';
import {
    BaseRulesetItemFormComponent,
    IFormatRulesetComponentData,
    ITagValueSelection,
} from '../../../forms/rule-form/base-ruleset-item-form.component';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {ConditionGroups} from '../../../forms/rule-form/condition-group-form/condition-group-form.component';
import {ValueForm, ValuesFormComponent} from '../../../forms/rule-form/values-form/values-form.component';
import {StaticContentRulesetsModule} from '../../static-content-rulesets/static-content-rulesets.module';
import {PropertyControlValidator} from '../../../classes/validators/property-control.validator';
import {PropertyControlComponent} from '../../../components/property-control/property-control.component';
import {distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {ValueModel} from '../../static-content-rulesets/models/api/ruleset-value.model';
import {Toaster} from '../../../classes/toaster.class';
import {AnimatedContentRulesetApiService} from '../../../api/services/animated-content-ruleset.api.service';
import {FormComponentsModule} from '../../../forms/form-components.module';

export interface IAnimatedContentRulesetRuleFormComponentData extends IFormatRulesetComponentData {
    ruleSetId: string;
    item?: AnimatedContentRulesetRuleModel;
    ruleProperties: RulePropertyModel[];
    assetRuleProperties: RulePropertyModel[];
    compositions: AfterEffectsProjectFileCompositionModel[];
}

interface AnimatedContentRulesetRuleFormGroup {
    name: FormControl<string>;
    composition: FormControl<ITagValueSelection>;
    conditions: ConditionGroups
    values: FormArray<FormGroup<ValueForm>>;
    assets: FormArray<FormGroup<ValueForm>>;
}

@Component({
    selector: 'animated-content-ruleset-rule-form',
    templateUrl: './animated-content-ruleset-rule-form.component.html',
    styleUrl: './animated-content-ruleset-rule-form.component.scss',
    imports: [
        ReactiveFormsModule,
        NUCComponentsModule,
        NUCInputsModule,
        NUCButtonsModule,
        StaticContentRulesetsModule,
        FormComponentsModule,
        InputComponent,
        DropdownComponent
    ]
})
export class AnimatedContentRulesetRuleFormComponent extends BaseRulesetItemFormComponent implements OnInit {
    public animatedContentRulesetApiService = inject(AnimatedContentRulesetApiService);

    public formGroup: FormGroup<AnimatedContentRulesetRuleFormGroup>;

    public compositions: DropdownItem<AfterEffectsProjectFileCompositionModel>[] = [];

    public modalData: IAnimatedContentRulesetRuleFormComponentData;

    public assetGroups = new FormArray<FormGroup<ValueForm>>([]);
    public assetTags: DropdownItem<string>[] = [];

    public assetRuleProperties: RulePropertyModel[];

    constructor(fullModalService: FullModalService,
                protected dataCollectionService: DataCollectionService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: IAnimatedContentRulesetRuleFormComponentData) {
        super(fullModalService, dataCollectionService);
        if (modalData && modalData.compositions) {
            this.modalData = modalData;
            this.compositions = modalData.compositions.map(composition =>
                new DropdownItem<AfterEffectsProjectFileCompositionModel>(composition.getTitle(), composition));
        }

        this.formGroup = new FormGroup({
            name: this.nameControl,
            composition: this.itemControl,
            conditions: this.conditionGroups,
            values: this.valueGroups,
            assets: this.assetGroups
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    protected initForm(): void {
        const modalData = this.modalData as IAnimatedContentRulesetRuleFormComponentData;
        this.assetRuleProperties = modalData.assetRuleProperties;

        this.itemControl.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((tagValue: AfterEffectsProjectFileCompositionModel) => {
            this.assetTags = tagValue?.assetTags.map((tag: string) => new DropdownItem<string>(tag, tag)) || [];
            this.assetGroups.controls.forEach((item) => {
                const tagControl = item.controls.tag;
                const tagValue = tagControl.value;

                if (!this.assetTags.find((tag) => tagValue === tag.getValue())) {
                    tagControl.patchValue(null);
                }
            });
        });

        if (modalData.item && modalData.item instanceof AnimatedContentRulesetRuleModel) {
            const foundComposition = this.modalData.compositions.find((item) => item.name === modalData.item.composition);
            this.tags = foundComposition?.tags.map((tag) => new DropdownItem<string>(tag, tag)) || [];
            const patch = {
                name: modalData.item.name,
                composition: foundComposition
            };

            this.formGroup.patchValue(patch);

            modalData.item.assets.forEach((value) => {
                this.addAssetGroup(value);
            });
        }

        super.initForm();
    }

    protected getAssetValues(): ValueModel[] {
        return this.assetGroups.value.map((value) => {
            return new ValueModel(value.tag, value.property.path);
        });
    }

    protected saveRule(): void {
        const conditions = this.getConditions();
        const values = this.getValues();
        const assets = this.getAssetValues();

        const rulesetRule = {
            name: this.formGroup.value.name,
            composition: (this.formGroup.value.composition as AfterEffectsProjectFileCompositionModel)?.name,
            conditions,
            values,
            assets
        };

        const observable = this.modalData.item
            ? this.animatedContentRulesetApiService.updateAnimatedContentRulesetRule(this.modalData.ruleSetId, this.modalData.item._id, rulesetRule)
            : this.animatedContentRulesetApiService.createAnimatedContentRulesetRule(this.modalData.ruleSetId, rulesetRule);

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    Toaster.success(`Successfully ${this.modalData.item ? 'updated' : 'created'} rule: ${rulesetRule.name}`);
                    this.fullModalService.close(result);
                },
                error: Toaster.handleApiError
            });
    }

    public deleteAssetClicked(index: number): void {
        this.assetGroups.removeAt(index);
    }

    public addAssetGroup(value?: AnimatedContentRulesetAssetModel): void {
        const assetValueGroup = new FormGroup<ValueForm>({
            tag: new FormControl(null, Validators.required),
            property: new FormControl(null, [Validators.required, PropertyControlValidator()])
        }, Validators.required);

        this.assetGroups.push(assetValueGroup);

        if (value) {
            const propertyValue = PropertyControlComponent.getPropertyValueModel(value?.property, this.assetRuleProperties, [],
                ValuesFormComponent.PropertyControlOptions);
            assetValueGroup.patchValue({
                tag: this.assetTags.find((tag) => tag.getValue() === value.tag)?.getValue(),
                property: propertyValue
            }, {emitEvent: false});
        }
    }
}
