<form [formGroup]="formGroup">

    <nuc-form-field label="Name">
        <nuc-input formControlName="name" placeholder="The name of the project"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Status">
        <nuc-dropdown
            formControlName="status"
            placeholder="The status of the project"
            [items]="statusOptions">
        </nuc-dropdown>
    </nuc-form-field>

    @if (variants?.length) {
        <nuc-form-field label="Variants" icon="nucicon_variant" iconColorClass="variant-highlight">
            <nuc-dropdown-multiselect
                [placeholder]="modalData.project ? 'No variants' : 'Select variants'"
                formControlName="variants"
                [items]="variants">
            </nuc-dropdown-multiselect>
        </nuc-form-field>
    }
</form>
