import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../../../api/services/last-viewed-items.service';

@Component({
    selector: 'standard-campaign-detail-base-component',
    templateUrl: './campaign-detail-base.component.html',
    styleUrls: [],
    standalone: false
})
export class CampaignDetailBaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private lastViewedItemsService: LastViewedItemsService) {
    }

    public ngOnInit(): void {
        const campaign = this.route.snapshot.data.campaign;
        this.lastViewedItemsService.addItem(ELastViewedItemsContext.CAMPAIGN, campaign._id);
    }

}
