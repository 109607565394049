import {Component, OnDestroy, OnInit} from '@angular/core';
import {EColumnSize, ESelectionMode, ESortOrder, FullModalConfig, FullModalService, ITableAction, ITableColumn} from '@relayter/rubber-duck';
import {SortDirection} from '@angular/material/sort';
import {AppConstants} from '../../../app.constants';
import {VariantFormComponent} from './variant-form/variant-form.component';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {Subject, Subscription} from 'rxjs';
import {VariantModel} from '../../../models/api/variant.model';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {IPaginator, PaginatorService} from '../../../components/paginator/paginator.service';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {VariantService} from '../../../api/services/variant.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {takeUntil} from 'rxjs/operators';
import {CursorArray} from '../../../api/api-cursor';
import {IActionClickEvent, ISortOptionEvent} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';

@Component({
    selector: 'variants-overview-component',
    templateUrl: 'variants-overview.component.html',
    styleUrls: ['variants-overview.component.scss'],
    providers: [PaginatorService],
    standalone: false
})
export class VariantsOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'variants-table';
    public readonly selectionMode: ESelectionMode = ESelectionMode.EMIT;
    public readonly permissions = AppConstants.PERMISSIONS;

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL
        }, {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL
        }
    ];

    public variants: VariantModel[];
    public disableNextPage = true;
    public subscription: Subscription;
    private onDestroySubject = new Subject<void>();

    public allowedActions: ITableAction[];
    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    private apiCursor: CursorArray;

    constructor(private fullModalService: FullModalService,
                private route: ActivatedRoute,
                private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                private variantService: VariantService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();
        this.listenToPagination();
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_VARIANT)) {
            this.allowedActions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : '';
        this.paginatorService.setPageIndex(this.tableId, this.pageIndex);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result: IPaginator) => {
                if (result.pageIndex === 1 || result.pageSize !== this.pageSize) {
                    this.apiCursor = new CursorArray(this.pageIndex);
                }
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;
                this.updateUrl();
                this.getVariants();
            });
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.SETTINGS,
            AppConstants.CONTEXT_URL.VARIANTS,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder)]);
    }

    private getVariants(): void {
        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.apiCursor.getCursor(this.pageIndex);
        const offset = cursor._id ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.variantService.getVariants(null, this.pageSize, offset, cursor, this.sortProperty, this.sortOrder)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: ARPagedResponseDataModel<VariantModel>) => {
                    this.variants = res.items;
                    this.disableNextPage = !res.hasNext;

                    if (this.variants?.length > 0) {
                        const item = this.variants[this.variants.length - 1];
                        this.apiCursor.setCursor(this.pageIndex, this.sortProperty, item);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        const sortColumn = event.column?.sortProperty ? event.column : null;
        this.sortProperty = sortColumn?.sortProperty || '';
        this.sortOrder = (sortColumn?.sortProperty && event.sortOrder === ESortOrder.ASC) ? 'asc' : 'desc';

        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public handleAction(event: IActionClickEvent): void {
        const clickedItem = event.item as VariantModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.variantService.getVariant(clickedItem._id)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe((variant) => {
                    this.openVariantForm(variant);
                }, Toaster.handleApiError);
        } else {
            Toaster.notYetImplementedError();
        }
    }

    public openVariantForm(variant?: VariantModel): void {
        const config = new FullModalConfig(
            variant
                ? 'Edit variant'
                : 'Add variant',
            variant
                ? 'Edit the information for the selected variant.'
                : 'Fill in the information below to create a new variant.',
            {variant});
        this.fullModalService.open(VariantFormComponent, config).afterClosed().subscribe((result) => {
            if (result) this.paginatorService.setPageIndex(this.tableId, 1);
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
