import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VariantService} from '../../../../api/services/variant.service';
import {VariantModel, VariantPatchModel, VariantPostModel} from '../../../../models/api/variant.model';
import {Toaster} from '../../../../classes/toaster.class';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EFormStatus} from '../../../../app.enums';

export interface IVariantFormComponentData {
    variant?: VariantModel;
}

@Component({
    selector: 'variant-form-component',
    templateUrl: 'variant-form.component.html',
    styleUrls: ['variant-form.component.scss'],
    standalone: false
})
export class VariantFormComponent implements OnInit, OnDestroy {
    private onDestroySubject = new Subject<void>();
    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    constructor(private fullModalService: FullModalService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IVariantFormComponentData) {
    }

    public ngOnInit(): void {
        this.initButtons();
        this.setupForm();
        this.trackStatus();
    }

    private setupForm(): void {
        this.formGroup = new FormGroup({
            name: new FormControl(this.modalData.variant?.name, Validators.required)
        });
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const save = new FullModalActionModel(this.saveButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, false));
        save.observable.subscribe(() => this.saveVariant());

        const actions = [cancel, save];
        this.fullModalService.setModalActions(actions);
    }

    private trackStatus(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.saveButton.disabled = !isValid);
    }

    private saveVariant(): void {
        const variantName = this.formGroup.value.name;
        const observable = this.modalData.variant
            ? this.variantService.patchVariant(this.modalData.variant._id, new VariantPatchModel(variantName))
            : this.variantService.postVariant(new VariantPostModel(variantName));

        observable.pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: VariantModel) => {
                    Toaster.success(`Variant ${this.modalData.variant ? 'updated' : 'created'} successfully`);
                    this.fullModalService.close(result);
                },
                error: Toaster.handleApiError
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

}
