import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../../../api/services/last-viewed-items.service';

@Component({
    selector: 'project-detail-base-component',
    templateUrl: './project-detail-base.component.html',
    imports: [RouterOutlet]
})
export class ProjectDetailBaseComponent implements OnInit {
    constructor(private route: ActivatedRoute,
                private lastViewedItemsService: LastViewedItemsService) {
    }

    public ngOnInit(): void {
        const project = this.route.snapshot.data.project;
        this.lastViewedItemsService.addItem(ELastViewedItemsContext.PROJECT, project._id);
    }
}
