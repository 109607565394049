import {Component, inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    EColumnDataType,
    EColumnType,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent, InputComponent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NUCButtonBarModule,
    NUCButtonsModule,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService, NUCDirectivesModule,
    NUCEmptyStateModule,
    NUCInputsModule,
    NUCTableModule
} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../pipes/pipes.module';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {AfterEffectsProjectFileFormComponent} from '../../../../forms/after-effects-project-file-form/after-effects-project-file-form.component';
import {filter} from 'rxjs/operators';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {AfterEffectsProjectFileModel} from '../../../../models/api/after-effects-project-file.model';
import {Toaster} from '../../../../classes/toaster.class';
import {ComponentsModule} from '../../../../components/components.module';
import {Subscription} from 'rxjs';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {AppConstants} from '../../../../app.constants';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {AfterEffectsProjectFilesApiService} from '../../../../api/services/after-effects-project-files.api.service';
import {AfterEffectsProjectFileUsageModel} from '../../../../models/api/after-effects-project-file-usage.model';
import {TemplateDetailsModule} from '../../templates/static-content-template-form/static-content-template-form.module';
import {IListDialogData, ListDialogComponent} from '../../../../components/dialog/list/list-dialog.component';

@Component({
    selector: 'after-effects-project-files-overview-component',
    templateUrl: 'after-effects-project-files-overview.component.html',
    styleUrls: ['after-effects-project-files-overview.component.scss'],
    imports: [
        NUCButtonBarModule,
        NUCButtonsModule,
        PipesModule,
        ComponentsModule,
        NUCEmptyStateModule,
        NUCInputsModule,
        NUCTableModule,
        TemplateDetailsModule,
        InputComponent,
        NUCDirectivesModule
    ],
    providers: [PaginatorService]
})
export class AfterEffectsProjectFilesOverviewComponent extends RLTableComponent implements OnInit  {
    public tableId = 'after-effects-project-files-table';

    private fullModalService = inject(FullModalService);
    private monitoredJobsService = inject(MonitoredJobsService);
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService)
    private paginatorService = inject(PaginatorService);
    private userIsAllowedPipe = inject(UserIsAllowedToPipe);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private dialogService = inject(NucDialogService);
    private dialogCustomContentService = inject(NucDialogCustomContentService);

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    protected readonly ESelectionMode = ESelectionMode;
    private dataSubscription: Subscription;
    private deleteSubscription: Subscription;

    public items: AfterEffectsProjectFileModel[];
    public actions: ITableAction[] = [];

    public get loading(): boolean {
        return !this.dataSubscription?.closed || (this.deleteSubscription && !this.deleteSubscription.closed);
    }

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    constructor() {
        super(inject(UserSettingsStorageService));
    }

    public ngOnInit(): void {
        if (this.userIsAllowedPipe.transform(this.permissions.POST_AFTER_EFFECTS_PROJECT_FILE_JOBS) &&
            this.userIsAllowedPipe.transform(this.permissions.GET_AFTER_EFFECTS_PROJECT_FILES)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }

        if (this.userIsAllowedPipe.transform(this.permissions.DELETE_AFTER_EFFECTS_PROJECT_FILE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

        this.initFromRoute();
        this.listenToPagination();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
        this.setPageIndex();
        // we don't set pageSize from route
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getData();
            });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.searchValue);
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.AFTER_EFFECTS_PROJECT_FILES, matrixUrl], {replaceUrl: true});
    }

    private getData(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.afterEffectsProjectFilesApiService.find(this.pageSize, offset, this.tableSortOptions, cursor, this.searchValue)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getItemMonitor(jobId)
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.setPageIndex();
                }
            });
    }

    public onActionClicked(event: IActionClickEvent): void {
        const item = event.item as AfterEffectsProjectFileModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.openAfterEffectsProjectFile(item);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.checkUsageAndDeleteAfterEffectsProjectFileDialog(item);
                break;
            }
            default:
                return Toaster.notYetImplementedError();
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }
    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;
        this.setPageIndex();
        }

    /**
     * On table view row clicked navigate to after effect project detail page
     */
    public onTableRowClicked(event: IItemClickEvent): void {
        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.GET_AFTER_EFFECTS_PROJECT_FILE)) {
            this.router.navigate([
                AppConstants.CONTEXT_URL.TEMPLATING,
                AppConstants.CONTEXT_URL.AFTER_EFFECTS_PROJECT_FILES, event.item._id, AppConstants.CONTEXT_URL.LAYERS], {skipLocationChange: false});
        }
    }

    public openAfterEffectsProjectFile(afterEffectsProjectFile?: AfterEffectsProjectFileModel): void {
        const modalConfig = afterEffectsProjectFile ?
            new FullModalConfig('Update an After Effects project file',
                'You can edit the information of the After Effects project file or upload a new file.') :
            new FullModalConfig('Add an After Effects project file',
                'We expect a ZIP file containing your complete project.');
        modalConfig.confirmClose = true;
        modalConfig.data = { ...(afterEffectsProjectFile && {afterEffectsProjectFile: afterEffectsProjectFile})};

        this.fullModalService.open(AfterEffectsProjectFileFormComponent, modalConfig)
            .afterClosed()
            .pipe(filter((result: Record<string, string>) => !!result))
            .subscribe((result) => {
                if (result) {
                    if (!!result.jobId) {
                        this.monitorJob(result.jobId);
                    } else {
                        this.setPageIndex()
                    }
                }
            });
    }

    private checkUsageAndDeleteAfterEffectsProjectFileDialog(afterEffectsProjectFile: AfterEffectsProjectFileModel): void {
        this.afterEffectsProjectFilesApiService.getAfterEffectsProjectFileUsage(afterEffectsProjectFile._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (usage: AfterEffectsProjectFileUsageModel) => {
                    if (usage.templates.length > 0 || usage.rulesets.length > 0) {
                        this.openAfterEffectsProjectFileInUseModal(usage);
                    } else {
                        this.openDeleteDialog(afterEffectsProjectFile);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private openAfterEffectsProjectFileInUseModal(afterEffectsProjectFileUsage: AfterEffectsProjectFileUsageModel): void {
        const dialogData: IListDialogData = {listGroups: []};

        if (afterEffectsProjectFileUsage.templates.length > 0) {
            dialogData.listGroups.push({
                title: 'Animated content template',
                list: afterEffectsProjectFileUsage.templates.map(template => template.name)
            });
        }
        if (afterEffectsProjectFileUsage.rulesets.length > 0) {
            dialogData.listGroups.push({
                title: 'Animated content rulesets',
                list: afterEffectsProjectFileUsage.rulesets.map(ruleset => ruleset.name)
            });
        }

        const dialogConfig = new DialogCustomContentConfig('Delete After Effects project file',
            'This After Effects project file is being used and cannot be deleted',
            dialogData);

        this.dialogCustomContentService.open(ListDialogComponent, dialogConfig);
    }

    private openDeleteDialog(afterEffectsProjectFile: AfterEffectsProjectFileModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete After Effects project file',
            `Please confirm that you wish to delete After Effects project file ${afterEffectsProjectFile.name}.`);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteAfterEffectsProjectFile(afterEffectsProjectFile);
        });
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
    }

    /**
     * Invokes the deletion of the After Effects project file
     * @param {AfterEffectsProjectFileModel} afterEffectsProjectFile
     * @returns {void}
     */
    public deleteAfterEffectsProjectFile(afterEffectsProjectFile: AfterEffectsProjectFileModel): void {
        this.afterEffectsProjectFilesApiService.deleteOne(afterEffectsProjectFile._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: boolean) => {
                    if (res) {
                        Toaster.success('After Effects project file successfully deleted');
                        this.paginatorService.setPageIndex(this.tableId, 1);
                    } else {
                        Toaster.error('There was an error while deleting the After Effects project file');
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
