import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ProjectModel} from '../../models/api/project.model';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent, DropdownMultiSelectComponent,
    FullModalActionModel,
    FullModalService, InputComponent,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {EFormStatus} from '../../app.enums';
import {Toaster} from '../../classes/toaster.class';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {WorkflowModel, WorkflowPostModel} from '../../models/api/workflow.model';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {WorkflowsApiService} from '../../api/services/workflows.api.service';

export interface IWorkflowFormData {
    projectId?: string;
    workflow?: WorkflowModel;
}

interface WorkflowFormGroup {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    workflowConfiguration: FormControl<string>;
    variants: FormControl<string[]>;
    deadline: FormControl<Date>;
}

@Component({
    selector: 'workflow-form-component',
    templateUrl: 'workflow-form.component.html',
    styleUrls: ['workflow-form.component.scss'],
    imports: [
        ReactiveFormsModule,
        RDModule,
        DropdownComponent,
        InputComponent,
        DropdownMultiSelectComponent
    ]
})

export class WorkflowFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    private fullModalService: FullModalService = inject(FullModalService);
    private workflowsApiService: WorkflowsApiService = inject(WorkflowsApiService);
    private variantService: VariantService = inject(VariantService);
    public modalData: IWorkflowFormData = inject(NUC_FULL_MODAL_DATA);
    private workflowConfigurationService: WorkflowConfigurationsService = inject(WorkflowConfigurationsService);

    public saveConfig: ButtonConfig;
    public formGroup: FormGroup<WorkflowFormGroup>

    private readonly projectId: string;
    private readonly workflow: WorkflowModel;
    public workflowConfigurations: WorkflowConfigurationModel[] = [];

    public tagOptions: DropdownItem<string>[];
    public variants: VariantModel[] = [];

    constructor() {
        this.projectId = this.modalData.projectId;
        this.workflow = this.modalData.workflow;
    }

    public ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.saveWorkflow());

        this.fullModalService.setModalActions([cancelAction, saveAction]);

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
    }

    private initForm(): void {
        this.formGroup = new FormGroup<WorkflowFormGroup>({
            name: new FormControl(this.workflow?.name || '', Validators.required),
            tags: new FormControl(this.workflow?.tags.map((tag) => new DropdownItem(tag, tag)) || []),
            workflowConfiguration: new FormControl(undefined, Validators.required),
            variants: new FormControl([]),
            deadline: new FormControl(this.workflow?.deadline, [this.validateDeadline()])
        })

        this.getVariants();
        this.getWorkflowConfigurations();
    }

    private validateDeadline(): ValidatorFn {
        return (control: AbstractControl) => {
            return !control.pristine && control.value && control.value < new Date() ? {invalidDate: {value: control.value}} : null;
        };
    }

    public getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    this.variants = results.items;

                    if (this.workflow?.variants?.length) {
                        const selectedVariants = this.variants.filter((variant) =>
                            this.workflow.variants.some((projectVariantId) => projectVariantId === variant._id))
                            .map(variant => variant.key);
                        this.formGroup.patchValue({variants: selectedVariants});
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public getWorkflowConfigurations(): void {
        this.workflowConfigurationService.findAll()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    this.workflowConfigurations = results;

                    if (this.workflow?.workflowConfiguration) {
                        this.formGroup.patchValue({workflowConfiguration: this.workflow.workflowConfiguration._id});
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public saveWorkflow(): void {
        const formValue = this.formGroup.value;
        const body = new WorkflowPostModel(
            formValue.name,
            formValue.workflowConfiguration,
            formValue.tags.map((tag: DropdownItem<string>) => tag.getValue()),
            this.variants.filter(variant => formValue.variants.includes(variant.key))
                .map(variant => variant._id),
            formValue.deadline
        );

        const workflowObservable = this.workflow
            ? this.workflowsApiService.postWorkflowForProject(this.projectId, body)
            : this.workflowsApiService.postWorkflowForProject(this.projectId, body)

        workflowObservable.subscribe({
            next: (project: ProjectModel) => {
                const operation = this.workflow ? 'updated' : 'created';
                Toaster.success(`Successfully ${operation} workflow`);
                this.fullModalService.close(project);
            },
            error: Toaster.handleApiError
        });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }
}
