import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '../../../../models/api/user.model';
import {UserService} from '../../../../api/services/users.service';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    EColumnSize,
    ESelectionMode,
    IActionClickEvent,
    IItemClickEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {IPaginator, PaginatorService} from '../../../../components/paginator/paginator.service';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RoleModel} from '../../../../models/api/role.model';
import {UserDataProvider} from '../../../../api/data-providers/user.data-provider';

@Component({
    selector: 'rl-users-overview',
    templateUrl: 'users-overview.component.html',
    styleUrls: ['users-overview.component.scss'],
    providers: [PaginatorService],
    standalone: false
})

export class UsersOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'users-overview-table';
    public readonly selectionMode: ESelectionMode = ESelectionMode.EMIT;

    public users: UserModel[] = [];
    public disableNextPage = true;

    public usersDataProvider = new UserDataProvider(this.tableSortOptions);

    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            size: EColumnSize.LARGE,
            sortProperty: 'fullName',
            sortDuplicates: true
        },
        {
            title: 'Email',
            key: 'email',
            size: EColumnSize.BASE,
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'roles',
            size: EColumnSize.BASE,
            format: (roles: RoleModel[]) => roles?.map(role => role.name).join(', ')
        },
        {
            title: 'Last session activity',
            key: 'lastSession.updatedAt',
            sortProperty: 'lastSession.updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            sortDuplicates: true
        },
        {
            title: 'Session oauth client',
            key: 'lastSession.oauthClient.name',
            sortProperty: 'lastSession.oauthClient.name',
            sortDuplicates: true
        }
    ];
    public actions: ITableAction[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private userService: UserService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();
        this.listenToPagination();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
        this.usersDataProvider.sortOptions.fromRoute(params, this.columns);
        this.usersDataProvider.searchValue = params['search'];

        this.setPageIndex(pageIndex);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId).subscribe((result: IPaginator) => {
            this.usersDataProvider.pageIndex = result.pageIndex;
            this.usersDataProvider.pageSize = result.pageSize;

            this.updateUrl();

            this.getUsers();
        });
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private updateUrl(): void {
        this.router.navigate([this.getMatrixUrl()], {relativeTo: this.route, replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.usersDataProvider.pageIndex, null, this.tableSortOptions.sortPropertiesAsString,
            this.tableSortOptions.sortOrder, this.usersDataProvider.searchValue);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_USER)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private getUsers(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_USERS)) return;

        this.usersDataProvider.retrieveData();
    }

    public onRowClicked(event: IItemClickEvent): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_USER_DETAILS)) {
            this.router.navigate([`/${AppConstants.CONTEXT_URL.SETTINGS}`,
                AppConstants.CONTEXT_URL.USER_MANAGEMENT, 'users-overview', event.item._id]);
        }
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const user = event.item as UserModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(user);
        }
    }

    private openDeleteDialog(user: UserModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete user',
            `Please confirm that you wish to delete ${user.fullName}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteUser(user);
        });
    }

    private deleteUser(user: UserModel): void {
        this.userService.deleteUser(user._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('User removed successfully');
                    this.setPageIndex();
                },
                error: Toaster.handleApiError
            });
    }
}
