import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
    DataFilterTemplateComponent
} from '../../../../components/data-filter/data-filter-template/data-filter-template.component';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {ActivatedRoute} from '@angular/router';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../classes/toaster.class';
import {DataFilterUtil} from '../../../../components/data-filter/data-filter.util';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {RDModule} from '@relayter/rubber-duck';
import {forkJoin, Subscription} from 'rxjs';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {TeamSettingsDataFiltersApiService} from '../../../../api/services/team-settings-data-filters.api.service';
import {TeamSettingsDataFilterModel} from '../../../../models/api/team-setting.model';
import {DataFilterModel} from '../../../../models/ui/data-filter.model';
import {PipesModule} from '../../../../pipes/pipes.module';
import {AppConstants} from '../../../../app.constants';
import {ComponentsModule} from '../../../../components/components.module';

interface IDataFieldFilterForm {
    [key: string]: FormGroup<Record<string, any>>;
}

interface IFilterForm {
    dataFilters?: FormGroup<IDataFieldFilterForm>;
}

@Component({
  selector: 'filter-overview',
  standalone: true,
    imports: [
        DataFilterTemplateComponent,
        RDModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule
    ],
  templateUrl: './filter-overview.component.html',
  styleUrl: './filter-overview.component.scss'
})
export class FilterOverviewComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef)
    private route: ActivatedRoute = inject(ActivatedRoute)
    private dataFieldsApiService: DataFieldsApiService = inject(DataFieldsApiService)
    private teamSettingsDataFiltersApiService: TeamSettingsDataFiltersApiService = inject(TeamSettingsDataFiltersApiService)

    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    public dataSubscription: Subscription;
    public collection: EDataFieldCollectionName;
    public filters: DataFilterModel[] = [];
    public dataFilters: TeamSettingsDataFilterModel;
    public formGroup: FormGroup<IFilterForm>;

    public ngOnInit(): void {
        this.collection = this.route.snapshot.data['collectionName'];
        this.getData();
    }

    private getData (): void {
        this.dataSubscription = forkJoin([
            this.dataFieldsApiService.getAllDataFields(this.collection),
            this.teamSettingsDataFiltersApiService.findDataFilterByCollectionName(this.collection)
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([dataFields, dataFilters]: [DataFieldModel[], TeamSettingsDataFilterModel]) => {
                    this.filters = DataFilterUtil.createDataFilters(undefined, dataFields);
                    this.dataFilters = dataFilters;
                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm (): void {
        const dataFilterValues: Record<string, any> = this.dataFilters.value?.reduce((accum, entry) =>
            {accum[entry.property] = entry.value; return accum}, {});
        this.formGroup = new FormGroup<IFilterForm>({
            dataFilters: new FormGroup<IDataFieldFilterForm>({
                [this.collection]: DataFilterUtil.createFilterFormGroup(this.filters, dataFilterValues || [])
            })
        });
    }

    public saveDataFilter (): void {
        const body = this.createDataFiltersBody();

        if (this.dataFilters?._id) {
            this.teamSettingsDataFiltersApiService.patch(this.dataFilters._id, body)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => Toaster.success('Successfully updated data filter'),
                    error: Toaster.handleApiError
                });
        } else {
            this.teamSettingsDataFiltersApiService.create(body)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => Toaster.success('Successfully created data filter'),
                    error: Toaster.handleApiError
                });
        }
    }

    private createDataFiltersBody(): TeamSettingsDataFilterModel {
        let formattedDataFilters;

        for (const [optionName, value] of Object.entries(this.formGroup.controls.dataFilters?.value || {})) {
            if (!value || (Array.isArray(value) && value.length === 0)) continue;

            const formattedValue = DataFilterUtil.getFilterValues(value);
            formattedDataFilters = {
                name: optionName,
                value: Object.keys(formattedValue).map(key => ({property: key, value: formattedValue[key]}))
            };
        }

        return formattedDataFilters;
    }
}
