// Modules
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownComponent, DropdownMultiSelectComponent, InputComponent, RDModule} from '@relayter/rubber-duck';
import {CommonModule} from '@angular/common';
import {StaticContentTemplateFormComponent} from './static-content-template-form.component';
import {StaticContentTemplatePresetComponent} from './static-content-template-preset/static-content-template-preset.component';
import {StaticContentTemplateSizeComponent} from './static-content-template-size/static-content-template-size.component';
import {StaticContentTemplateContentComponent} from './static-content-template-content/static-content-template-content.component';
import {ComponentsModule} from '../../../../components/components.module';
import {StaticContentTemplateVariantsComponent} from './static-content-template-variants/static-content-template-variants.component';
import {TemplateVariantPresetComponent} from './static-content-template-variants/preset/template-variant-preset.component';

// Pages

const components = [
    StaticContentTemplateFormComponent,
    StaticContentTemplatePresetComponent,
    StaticContentTemplateSizeComponent,
    StaticContentTemplateContentComponent,
    StaticContentTemplateVariantsComponent,
    TemplateVariantPresetComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RDModule,
        ComponentsModule,
        DropdownMultiSelectComponent,
        DropdownComponent,
        InputComponent
    ],
    providers: [],
    declarations: components,
    exports: components
})

export class TemplateDetailsModule {
}
