<div class="header-row">
    <h1>Workflow configuration overview</h1>
    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_WORKFLOW_CONFIGURATION | userIsAllowedTo"
                            text="Add configuration"
                            nucTooltip="Add configuration"
                            (click)="openWorkflowConfigurationForm()" />
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_WORKFLOW_CONFIGURATIONS | userIsAllowedTo else noPermissionState">
    <nuc-table [items]="workflows"
               [columns]="columns"
               [loading]="!subscription?.closed"
               (sortOptionChange)="onSortOptionChanged($event)"
               [actions]="allowedActions"
               (actionClicked)="handleAction($event)"
               (itemClicked)="itemClicked($event)"
               [emptyStateTitle]="search ? 'No matching results.' : 'This section is empty. Add the first item.'"
               [selectionMode]="selectionMode"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey">
        <nuc-input button-bar-left
                   [data]="search"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search workflow configuration by name">
        </nuc-input>
        <ng-container button-bar-right>
            <paginator [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="!subscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
