import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {subMenuAnimations} from '../menu.animations';
import {MenuItem} from '../../models/ui/menu-item.model';
import {SubMenuItem} from '../../models/ui/sub-menu-item.model';
import {UserService} from '../../api/services/users.service';
import {ARUserStorage} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ISegmentService, SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';

@Component({
    selector: 'rl-sub-menu',
    templateUrl: './sub-menu.component.html',
    styleUrls: ['./sub-menu.component.scss'],
    animations: [subMenuAnimations.container, subMenuAnimations.content, subMenuAnimations.change],
    standalone: false
})
export class SubMenuComponent {
    @Input() public menuItem: MenuItem;
    @Output() public closeClicked = new EventEmitter();

    constructor(private userService: UserService,
                @Inject(SEGMENT_SERVICE) protected segmentService: ISegmentService) {
    }

    // TODO: Remove actions from subMenutItem. This is a quick fix and will be replaced by a new user badge component compatible with the new menu
    public handleSubItemAction(subItem: SubMenuItem): void {
        if (subItem.action === 'logout') {
            const token = ARUserStorage.getAccessToken();
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(token);
            if (!decodedToken) {
                // if there is no token, we can skip the api call
                this.userService.logoutAndRedirectToLogin();
                this.userService.logoutAuth0();
                this.segmentService.reset();
                return;
            }
            this.userService.logout().subscribe(() => {
                this.userService.logoutAndRedirectToLogin();
                this.userService.logoutAuth0();
                this.segmentService.reset();
            }, (error) => {
                Toaster.handleApiError(error);
            });
        }
    }
}
