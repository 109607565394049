import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../../../app.constants';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {LinkedAssetsToProductsModel} from '../../../../models/api/linked-assets-to-products.model';
import {Toaster} from '../../../../classes/toaster.class';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {ProductService} from '../../../../api/services/products.service';
import {ProductModel} from '../../../../models/api/product.model';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EPropertySettingsContext} from '../../../../components/property-settings/property-settings.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {CursorArray} from '../../../../api/api-cursor';
import {SearchIndexTableConfig} from '../../../../classes/table-config/search-index.table-config';

export interface ILinkAssetsToProductViewData {
    selectedAssets: string[];
}

@Component({
    selector: 'link-assets-to-products-view',
    templateUrl: 'link-assets-to-products-view.component.html',
    styleUrls: ['link-assets-to-products-view.component.scss'],
    providers: [PaginatorService],
    standalone: false
})

export class LinkAssetsToProductsViewComponent implements OnInit, OnDestroy {
    private static readonly DEFAULT_SORT_OPTIONS = [
        new DropdownItem('Date modified', 'updatedAt'),
        new DropdownItem('Date created', 'createdAt')
    ];
    private static readonly SEARCH_SORT_OPTIONS = [new DropdownItem<string>('Relevance', 'relevance')];

    public readonly tableId = 'link-assets-to-products-view-table';
    public readonly EPropertySettingsContext = EPropertySettingsContext;

    public searchValue: string;
    public selectedProducts: string[] = [];
    private selectedProductIdsSubject = new Subject<string[]>();
    public permissions = AppConstants.PERMISSIONS;

    public selectedAssets: string[];
    private linkButton: ButtonConfig;

    public products: ProductModel[] = [];
    public sortingOptions = LinkAssetsToProductsViewComponent.DEFAULT_SORT_OPTIONS;

    public selectAllChecked: boolean = false;
    public pageIndex: number;
    public pageSize: number;
    private cursorArray: CursorArray;

    public sortDescending: boolean = true;
    public beforeSearchSortDescending: boolean;
    public sort = this.sortingOptions[0];
    public beforeSearchSort: DropdownItem<string>;
    public totalItemCount: number;
    public disableNextPage: boolean;
    private onDestroySubject = new Subject<void>();
    public productServiceSubscription: Subscription;

    constructor(private productService: ProductService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: ILinkAssetsToProductViewData,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService) {
        this.selectedAssets = modalData.selectedAssets;
    }

    public ngOnInit(): void {
        this.cursorArray = new CursorArray(1, true);

        this.selectedProductIdsSubject.pipe(takeUntil(this.onDestroySubject))
            .subscribe((productIds) => {
                this.selectedProducts = productIds;
                this.selectAllChecked = !!this.selectedProducts.length && this.selectedProducts.length === this.totalItemCount;
                this.linkButton.disabled = this.selectedProducts.length === 0;
            });

        this.initButtons();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((paginator) => {
                if (this.pageSize !== paginator.pageSize) {
                    this.pageSize = paginator.pageSize;
                }
                if (this.pageIndex !== paginator.pageIndex) {
                    this.pageIndex = paginator.pageIndex;
                }
                this.getProducts();
            });

        this.setPageIndex();
    }

    public onSelectAllChanged(allSelected: boolean): void {
        if (this.selectAllChecked !== allSelected) {
            this.selectAllChecked = allSelected;
            allSelected ? this.selectAllProducts() : this.selectedProductIdsSubject.next([]);
        }
    }

    public initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.linkButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Link', null, null, true);

        const cancel = new FullModalActionModel(cancelButton);
        const link = new FullModalActionModel(this.linkButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        link.observable.subscribe(() => this.onLinkButtonClicked());

        const actions = [cancel, link];
        this.fullModalService.setModalActions(actions);
    }

    public onLinkButtonClicked(): void {
        this.linkButton.loading = true;
        const linkedAssetsToProducts = new LinkedAssetsToProductsModel(this.selectedAssets, this.selectedProducts);

        this.productService.linkAssetsToProducts(linkedAssetsToProducts).subscribe(
            (result) => {
                this.linkButton.loading = false;
                this.fullModalService.close(result);
                Toaster.success('Linked assets to products successfully');
            },
            (error: ARApiError) => {
                this.linkButton.loading = false;
                Toaster.handleApiError(error);
            });
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, SearchIndexTableConfig.SEARCH_INDEX_SCORE_COLUMN.sortProperty,
                this.products[this.products.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sort.getValue(), this.products[this.products.length - 1]);
        }
    }

    public onSearchBarValueUpdated(value: string): void {
        if (value !== this.searchValue) {
            this.searchValue = value;
            this.selectedProductIdsSubject.next([]);
            this.setPageIndex();
        }

        this.updateSortOptions();
    }

    private updateSortOptions(): void {
        if (this.searchValue) {
            // Preserve values
            this.beforeSearchSort = this.sort;
            this.beforeSearchSortDescending = this.sortDescending;
            // Show sort on relevance
            this.sortingOptions = LinkAssetsToProductsViewComponent.SEARCH_SORT_OPTIONS;
            this.sort = this.sortingOptions[0];
            this.sortDescending = true;
        } else {
            this.sortingOptions = LinkAssetsToProductsViewComponent.DEFAULT_SORT_OPTIONS;
            this.sortDescending = this.beforeSearchSortDescending;
            this.sort = this.sortingOptions.find(option => option.getValue() === this.beforeSearchSort?.getValue()) ||
                this.sortingOptions[0];
        }
    }

    public selectAllProducts(): void {
        this.productService.getProductIds(this.searchValue, AppConstants.PAGE_SIZE_MAX)
            .subscribe(
                (res: string[]) => this.selectedProductIdsSubject.next(res),
                (err: ARApiError) => Toaster.handleApiError(err));
    }

    public getProducts(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCTS)) return;
        this.productServiceSubscription?.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.productServiceSubscription = this.productService.getData(
            this.sortDescending, !this.searchValue ? this.sort.getValue() : null, this.searchValue,
            this.pageSize, 0, null, cursor)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((res: ARPagedResponseDataModel<ProductModel>) => {
                this.products = res.items;
                this.disableNextPage = !res.hasNext;
                // When searching, res.metaData.searchItemCount has the exact item count up to 1000, otherwise it will be a rough estimate
                this.totalItemCount = this.searchValue ? this.products.length : null;
                if (res.items.length > 0) {
                    this.setCursor();
                }
            }, Toaster.handleApiError);
    }

    /**
     * Handle page event
     */
    public onCollectionViewOptionsChanged(): void {
        this.setPageIndex();
    }

    public onCollectionViewItemClicked(product: ProductModel): void {
        const currentlySelected = [...this.selectedProducts];
        const isPresent = currentlySelected.findIndex((productId) => productId === product._id) !== -1;
        isPresent ?
            this.selectedProductIdsSubject.next(currentlySelected.filter((productId) => productId !== product._id)) :
            this.selectedProductIdsSubject.next(currentlySelected.concat(product._id));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
