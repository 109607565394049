import { Component } from '@angular/core';

@Component({
    selector: 'standard-team-settings-overview',
    templateUrl: './team-settings-overview.component.html',
    styleUrls: ['./team-settings-overview.component.scss'],
    standalone: false
})
export class TeamSettingsOverviewComponent {

  constructor() { }
}
