import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItem} from '../../models/ui/menu-item.model';

@Component({
    selector: 'rl-menu-icon',
    templateUrl: './menu-icon.component.html',
    styleUrls: ['./menu-icon.component.scss'],
    standalone: false
})
export class MenuIconComponent {
    @Input() public menuItem: MenuItem;
    @Input() public selectedMenuItem: MenuItem;
    @Input() public currentRoute: string;
    @Output() public onClick = new EventEmitter<MenuItem>();
}
