import {Component, input, OnInit} from '@angular/core';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';
import {DropdownMultiSelectComponent, RDModule} from '@relayter/rubber-duck';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';
import {IAccountNotificationPreference} from '../../account.component';
import {ENotificationDestination, NotificationTypesConfig} from '../../../../../models/api/notification.model';

@Component({
    selector: 'notification-preference',
    templateUrl: 'notification-preference.component.html',
    styleUrls: ['notification-preference.component.scss'],
    imports: [
        RDModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownMultiSelectComponent
    ]
})
export class NotificationPreferenceComponent extends RLBaseComponent implements OnInit {
    public formGroup = input<FormGroup<IAccountNotificationPreference>>();

    protected readonly NotificationTypesConfig = NotificationTypesConfig;

    public options: DropdownItem<ENotificationDestination>[];

    public ngOnInit() {
        this.options = NotificationTypesConfig[this.formGroup().controls.type.value].destinations.map(item =>
            new DropdownItem<ENotificationDestination>(this.getDestinationTitle(item), ENotificationDestination[item]));
    }

    private getDestinationTitle(destination: ENotificationDestination): string {
        switch (destination) {
            case ENotificationDestination.EMAIL:
                return 'E-mail';
            case ENotificationDestination.NOTIFICATION_CENTRE:
                return 'Notification centre';
            case ENotificationDestination.TOASTER:
                return 'Toaster';
        }
    }
}
