<nuc-table [items]="items"
           [columns]="columns"
           [actions]="actions"
           [loading]="!subscription?.closed"
           [tableId]="tableId"
           [emptyStateTitle]="searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'"
           [prefixStorageKey]="storageKey"
           useButtonBar="true"
           [orderRows]="true"
           (enableOrderRows)="startOrderingItems()"
           (sortOptionChange)="onSortOptionChanged($event)"
           (actionClicked)="handleTableRowAction($event)"
           (orderChanged)="orderChanged($event)">
    <ng-container button-bar-left>

        <div class="button-bar-left-container">
            <nuc-input
                [data]="searchValue"
                prefix="nucicon_search"
                (debounceValueChanged)="onSearchBarValueUpdated($event)"
                [placeholder]="'Search items by name'">
            </nuc-input>


            <nuc-dropdown placeholder="Select an item group"
                          [items]="itemGroups"
                          (selectedOption)="itemGroupChange($event)">
            </nuc-dropdown>
            <div class="vertical-line"></div>
        </div>

    </ng-container>
    <ng-container button-bar-right>
        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!subscription?.closed">
        </paginator>
    </ng-container>
</nuc-table>
