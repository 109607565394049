import {inject, Pipe, PipeTransform} from '@angular/core';
import {ENotificationDestination, ENotificationStatus, NotificationModel} from '../models/api/notification.model';
import {UserService} from '../api/services/users.service';

@Pipe({
    name: 'unreadNotifications',
    standalone: false
})
export class UnreadNotificationsPipe implements PipeTransform {
  private userService = inject(UserService);

  public transform(notifications: NotificationModel[]): boolean {
    return notifications.filter((notification) =>
        this.userService.hasNotificationPreference(notification.type, ENotificationDestination.NOTIFICATION_CENTRE) &&
          notification.status === ENotificationStatus.UNREAD).length > 0;
  }

}
