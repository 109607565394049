import {Component, inject, OnInit} from '@angular/core';
import {ComponentsModule} from '../../../components/components.module';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnType,
    FullModalConfig,
    FullModalService, IActionClickEvent,
    IItemClickEvent, InputComponent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NUCButtonBarModule,
    NUCButtonsModule, NucDialogConfigModel, NucDialogService, NUCDirectivesModule,
    NUCInputsModule,
    NUCTableModule
} from '@relayter/rubber-duck';
import {PipesModule} from '../../../pipes/pipes.module';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {filter, switchMap} from 'rxjs/operators';
import {AnimatedContentRulesetModel} from '../../../models/api/animated-content-ruleset.model';
import {
    AnimatedContentRulesetFormComponent,
    IAnimatedContentRulesetFormComponentData
} from '../animated-content-ruleset-form/animated-content-ruleset-form.component';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {AppConstants} from '../../../app.constants';
import {Toaster} from '../../../classes/toaster.class';
import {Subscription} from 'rxjs';
import {AnimatedContentRulesetApiService} from '../../../api/services/animated-content-ruleset.api.service';
import {ItemUsedModel} from '../../../models/api/item-used.model';

@Component({
    selector: 'animated-content-rulesets-overview',
    templateUrl: './animated-content-rulesets-overview.component.html',
    styleUrl: './animated-content-rulesets-overview.component.scss',
    imports: [
        ComponentsModule,
        NUCButtonBarModule,
        NUCButtonsModule,
        PipesModule,
        NUCInputsModule,
        NUCTableModule,
        NUCDirectivesModule,
        InputComponent
    ],
    providers: [PaginatorService]
})
export class AnimatedContentRulesetsOverviewComponent extends RLTableComponent implements OnInit {
    public tableId = 'animated-content-rulesets-overview-table';

    private fullModalService = inject (FullModalService);
    private paginatorService = inject(PaginatorService);
    private userIsAllowedPipe = inject(UserIsAllowedToPipe);
    private animatedContentRulesetsApiService = inject(AnimatedContentRulesetApiService)
    private dialogService = inject(NucDialogService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    private dataSubscription: Subscription;

    public items: AnimatedContentRulesetModel[];
    public actions: ITableAction[] = [];

    public get loading(): boolean {
        return !this.dataSubscription?.closed;
    }

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Engine type',
            key: 'engineType',
            sortProperty: 'engineType',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    constructor(userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.listenToPagination();
        this.setTableActions();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getData();
            });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.searchValue);
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.ANIMATED_CONTENT_RULESETS,
            matrixUrl], {replaceUrl: true});
    }

    private getData(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.animatedContentRulesetsApiService.find(this.pageSize, offset, this.tableSortOptions, cursor, this.searchValue)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public openForm(animatedContentRuleset?: AnimatedContentRulesetModel): void {
        const data = { animatedContentRuleset } as IAnimatedContentRulesetFormComponentData;
        const config = new FullModalConfig(animatedContentRuleset ? 'Edit animated content ruleset' : 'Create animated content ruleset',
            'Setup your animated content ruleset details', data);
        config.confirmClose = true;

        this.fullModalService.open(AnimatedContentRulesetFormComponent, config).afterClosed()
            .pipe(
                filter((result) => !!result),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => {
                if (result) {
                    this.setPageIndex();
                }
            });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }
    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;
        this.setPageIndex();
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.GET_ANIMATED_CONTENT_RULESETS)) {
            this.router.navigate([
                AppConstants.CONTEXT_URL.TEMPLATING,
                AppConstants.CONTEXT_URL.ANIMATED_CONTENT_RULESETS,
                event.item._id
            ]);
        }
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        this.animatedContentRulesetsApiService.findOne(event.item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (animatedContentRuleset) => {
                    switch (event.action) {
                        case AppConstants.TABLE_ACTION_TYPES.EDIT:
                            this.openForm(animatedContentRuleset);
                            break;
                        case AppConstants.TABLE_ACTION_TYPES.DELETE:
                            this.openDeleteConfirmationDialog(animatedContentRuleset);
                            break;
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.PATCH_ANIMATED_CONTENT_RULESET)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }

        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.DELETE_ANIMATED_CONTENT_RULESET)) {
            this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) || [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private openDeleteConfirmationDialog(ruleset: AnimatedContentRulesetModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete animated content ruleset',
            `Please confirm that you wish to delete the animated content ruleset: ${ruleset.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).pipe(
            switchMap(() => {
                deleteDialog.close();
                return this.animatedContentRulesetsApiService.getAnimatedContentRulesetUsage(ruleset._id);
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (result: ItemUsedModel) => {
                result.usage ? this.openCannotDeleteDialog(ruleset) : this.deleteRuleset(ruleset._id);
            },
            error: Toaster.handleApiError
        });
    }

    private openCannotDeleteDialog(ruleset: AnimatedContentRulesetModel): void {
        const cannotDeleteDialogConfig = new NucDialogConfigModel('Cannot delete', `The animated content ruleset '${ruleset.name}' is in use.`);
        const cannotDeleteDialog = this.dialogService.openDialog(cannotDeleteDialogConfig);
        cannotDeleteDialogConfig.addAction('Close', BUTTON_TYPE.SECONDARY).subscribe(() => cannotDeleteDialog.close());
    }

    private deleteRuleset(rulesetId: string): void {
        this.animatedContentRulesetsApiService.deleteOne(rulesetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Successfully deleted animated content ruleset');
                    this.setPageIndex();
                },
                error: Toaster.handleApiError
            });
    }
}
