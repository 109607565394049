import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {TeamSettingsDataFilterModel} from '../../models/api/team-setting.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeamSettingsDataFiltersApiService extends ApiService<TeamSettingsDataFilterModel> {
    constructor() {
        super([ApiConstants.API_TEAM_SETTINGS, ApiConstants.API_DATA_FILTERS], TeamSettingsDataFilterModel);
    }

    findDataFilterByCollectionName (collectionName): Observable<TeamSettingsDataFilterModel> {
        const request = new ApiDetailsRequest(this.groups, TeamSettingsDataFilterModel, null);
        request.additionalQueryParams.addParam('collectionName', collectionName);

        return this.apiRequestService.findOne(request);
    }
}
