<div class="project-info">
    <div class="header">
        <h1>{{project?.name}}</h1>

        @if (project) {
            <nuc-button-bar >
                @if (permissions.POST_WORKFLOW | userIsAllowedTo) {
                    <nuc-button-primary text="Add workflow"
                                        (click)="openAddWorkflowModal()">
                    </nuc-button-primary>
                }
            </nuc-button-bar>
        }
    </div>
    @if (permissions.GET_WORKFLOWS | userIsAllowedTo) {
        <div class="detail">
            <p class="body-strong">Status</p>
            <div>
                <nuc-badge-primary>{{project?.status | titlecase}}</nuc-badge-primary>
            </div>
        </div>

        <rl-collection-view [items]="workflows"
                            [propertySettingsContext]="EPropertySettingsContext.PROJECT"
                            (collectionViewOptionsChanged)="setPageIndex()"
                            [disableSort]="!!searchValue"
                            [isLoading]="!workflowsSubscription?.closed"
                            [isSearching]="!!searchValue"
                            [searchPlaceholder]="'Search projects'"
                            [searchValue]="searchValue"
                            (onSearchValueUpdated)="onSearch($event)"
                            [disableNextPage]="disableNextPage"
                            [disablePageOptions]="!!searchValue"
                            [viewId]="viewId">
        </rl-collection-view>
    } @else {
        <no-permission-state-component></no-permission-state-component>
    }
</div>
