<div class="header">
    <h1>{{group?.name | emptyValueFormatter:'Content group details'}}</h1>
</div>

<ng-container *ngIf="permissions.GET_USERS | userIsAllowedTo else noPermissionState">
    <nuc-table [tableDataProvider]="usersDataProvider"
               [tableSortOptions]="usersDataProvider.sortOptions"
               [items]="usersDataProvider.data"
               [columns]="columns"
               [loading]="usersDataProvider.loading"
               [tableId]="tableId"
               [emptyStateTitle]="usersDataProvider.searchValue ? 'No item matches your search criteria.' : null"
               [prefixStorageKey]="storageKey"
               (tableSortOptionsChange)="setPageIndex()">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="usersDataProvider.searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search users">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="usersDataProvider.disableNextPage"
                   [loading]="usersDataProvider.loading">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
