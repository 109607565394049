import {Component, inject, OnInit} from '@angular/core';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {AfterEffectsProjectFileCompositionModel} from '../../../../../models/api/after-effects-project-file.model';
import {Toaster} from '../../../../../classes/toaster.class';
import {ComponentsModule} from '../../../../../components/components.module';
import {Subscription} from 'rxjs';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {AfterEffectsProjectFilesApiService} from '../../../../../api/services/after-effects-project-files.api.service';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';

@Component({
    selector: 'after-effects-project-files-composition-layers-component',
    templateUrl: 'after-effects-project-files-composition-preview.component.html',
    styleUrls: ['after-effects-project-files-composition-preview.component.scss'],
    imports: [
        ComponentsModule,
        PipesModule
    ],
    providers: [PaginatorService]
})
export class AfterEffectsProjectFilesCompositionPreviewComponent extends RLBaseComponent implements OnInit  {
    private afterEffectsProjectFileId: string;
    private afterEffectsProjectFileCompositionId: string;
    protected afterEffectsProjectFileComposition: AfterEffectsProjectFileCompositionModel;

    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService)
    private route = inject(ActivatedRoute);

    public compositionDataSubscription: Subscription;

    public ngOnInit(): void {
        this.initFromRoute();
    }

    private initFromRoute(): void {
        // Get After Effects project file id from parent route
        this.afterEffectsProjectFileId = this.route.parent.snapshot.params['afterEffectsProjectFileId'];

        // Listen to composition id updates on the route
        this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
            if (this.afterEffectsProjectFileCompositionId !== params['itemId']) {
                this.afterEffectsProjectFileCompositionId = params['itemId'];
                this.getComposition();
            }
        });
    }

    public getComposition(): void {
        if (this.compositionDataSubscription) this.compositionDataSubscription.unsubscribe();

        this.compositionDataSubscription =
            this.afterEffectsProjectFilesApiService.getAfterEffectsProjectFileComposition(
                this.afterEffectsProjectFileId,
                this.afterEffectsProjectFileCompositionId
            )
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.afterEffectsProjectFileComposition = result;
                    },
                    error: Toaster.handleApiError
                });
    }
}
