<form [formGroup]="formGroup">
    <nuc-form-field label="Animated content ruleset name">
        <nuc-input formControlName="name" placeholder="Fill in a name"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Engine type">
        <nuc-dropdown
            formControlName="engineType"
            placeholder="Select an engine type"
            [nullOption]="false"
            [items]="engineTypes"
            [required]="true">
        </nuc-dropdown>
    </nuc-form-field>

    @if (formGroup.controls.engineType.value === 'AFTER_EFFECTS') {
        <nuc-form-field label="Master After Effects project files">
            <p class="description">Select After Effects project file(s) to act as the main structure of the compositions and layers</p>
            <nuc-dropdown-multiselect
                formControlName="afterEffectsProjectFiles"
                placeholder="Select After Effects project files"
                [searchable]="true"
                [items]="afterEffectsProjectFiles"
                [total]="afterEffectsProjectFilesTotalItems"
                (requestData)="requestAfterEffectsProjectFiles($event)"
                [required]="true">
            </nuc-dropdown-multiselect>
        </nuc-form-field>
    }
</form>
