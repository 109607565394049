import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RolesService} from '../../../api/services/roles.service';
import {ARApiError} from '@relayter/core';
import {RoleModel} from '../../../models/api/role.model';
import {Toaster} from '../../../classes/toaster.class';
import {ErrorConstants} from '../../../api/error.constants';
import {AppConstants} from '../../../app.constants';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {filter, map, takeUntil} from 'rxjs/operators';
import {TabBarRoutableItem} from '../team-settings/tab-bar-routable-item.model';
import {Subject} from 'rxjs';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {RoleDetailContainerService} from './role-detail-container.service';

@Component({
    selector: 'rl-role-detail-container-component',
    templateUrl: 'role-detail-container.component.html',
    styleUrls: ['role-detail-container.component.scss'],
    providers: [RoleDetailContainerService],
    standalone: false
})

/**
 * RoleDetailContainerComponent
 */
export class RoleDetailContainerComponent extends RLBaseComponent implements OnInit, OnDestroy {
    public roleId: string;
    public role = new RoleModel();
    private onDestroySubject = new Subject<void>();

    public permissionsTab = new TabBarRoutableItem(
        'Permissions',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ROLE_MANAGEMENT],
        [AppConstants.PERMISSIONS.GET_ROLE]
    );
    public usersTab = new TabBarRoutableItem(
        'Users',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ROLE_MANAGEMENT],
        [AppConstants.PERMISSIONS.GET_ROLE]
    );
    public tabs = [this.permissionsTab, this.usersTab].filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));
    public _activeTab: TabBarRoutableItem;
    public get activeTab(): ITabBarItem {
        return this._activeTab;
    }

    public set activeTab(val: ITabBarItem) {
        if (this._activeTab !== val) {
            this._activeTab = val as TabBarRoutableItem;
            this.router.navigate(this._activeTab.url, {replaceUrl: true});
        }
    }

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private rolesService: RolesService,
                private rolesOverviewService: RoleDetailContainerService) {
        super();
    }

    public ngOnInit(): void {
        this.roleId = this.route.snapshot.params['id'];
        this.rolesOverviewService.setRoleId(this.roleId);

        this.getRole();
        // FIXME: temporary solution, we need id to form the url
        this.permissionsTab.url.push(this.roleId, AppConstants.CONTEXT_URL.PERMISSIONS);
        this.usersTab.url.push(this.roleId, AppConstants.CONTEXT_URL.USERS);

        if (this.tabs.length) {
            this.setActiveTabFromUrl(this.router.routerState.snapshot.url);

            // This reloads the role detail view if breadcrumb is clicked
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd),
                filter((event) => event.urlAfterRedirects === `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.ROLE_MANAGEMENT}`),
                takeUntil(this.onDestroySubject),
            ).subscribe(() => this.router.navigate(this.tabs[0].url, {replaceUrl: true}));
        }
    }

    private setActiveTabFromUrl(url: string): void {
        const tabUrl = url?.split(';')[0]; // Get url without matrix parameters
        const tabFromRoute = this.tabs.find((tab) => tab.urlString === tabUrl);
        this.activeTab = tabFromRoute ? tabFromRoute : this.tabs[0];
    }

    private getRole(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ROLE)) {
            this.router.navigate([AppConstants.ROLES_PATH]);
            return;
        }
        this.rolesService.getRoleWithId(this.roleId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (role: RoleModel) => {
                    this.role = role;
                },
                (err: ARApiError) => {
                    Toaster.handleApiError(err);
                    if (err.code === ErrorConstants.API_ERROR_CODES.NOT_FOUND_ROLE ||
                        err.code !== ErrorConstants.API_ERROR_CODES.NOT_FOUND_DEFAULT) {
                        this.router.navigate([AppConstants.ROLES_PATH]);
                    }
                }
            );
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
