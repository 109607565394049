import {Component, computed, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent,
    EditorReadOnlyLines,
    FullModalActionModel,
    FullModalService, InputComponent,
    JavascriptCodeEditorComponent,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize, map, startWith} from 'rxjs/operators';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ComponentsModule} from '../../../../components/components.module';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {EPropertyContext, PropertyService} from '../../../../api/services/property.service';
import {ARLogger} from '@relayter/core';
import {combineLatest, forkJoin} from 'rxjs';
import {VariantModel} from '../../../../models/api/variant.model';
import {Toaster} from '../../../../classes/toaster.class';
import {ConnectionApiService} from '../../../../api/services/connection.api.service';
import {EDataFieldCollectionName, EFormStatus} from '../../../../app.enums';
import {
    ConnectionEndpointBody,
    ConnectionEndpointModel,
    EProducerAction,
    EWebhookProducerCollectionNames
} from '../../../../models/api/connection.model';
import {PropertyValueModel} from '../../../../models/ui/property-value.model';
import {StringUtil} from '../../../../classes/string-util';
import {PropertyControlComponent} from '../../../../components/property-control/property-control.component';
import {PropertyControlOptions} from '../../../../components/property-control/property-control.options';
import {VariantsApiService} from '../../../../api/services/variants.api.service';
import {Diagnostic} from '@codemirror/lint';
import {defaultAssetScript, defaultCampaignItemScript, defaultProductScript} from './default-scripts';
import {AutoCompleteFactory} from './datafields.autocomplete';
import {CompletionResult} from '@codemirror/autocomplete';

interface MappingFormModel {
    path: FormControl<string>;
    property: FormControl<PropertyValueModel>
}

export interface EndpointFormModel {
    model: FormControl<EWebhookProducerCollectionNames>;
    action: FormControl<EProducerAction>;
    path: FormControl<string>;
    field: FormControl<string>;
    mappings?: FormArray<FormGroup<MappingFormModel>>;
    script: FormControl<string>;
    masterBriefing?: FormControl<boolean>;
    campaignNamePath?: FormControl<string>;
    campaignStartDatePath?: FormControl<string>;
    campaignEndDatePath?: FormControl<string>;
    assetPath?: FormControl<string>;
}

export interface IProducerWebhookEndpointFormComponentData {
    connectionId: string;
    endpointData?: ConnectionEndpointModel;
}

@Component({
    selector: 'producer-webhook-endpoint-form',
    templateUrl: './producer-webhook-endpoint-form.component.html',
    styleUrls: ['./producer-webhook-endpoint-form.component.scss'],
    providers: [ConnectionApiService],
    imports: [
        ReactiveFormsModule,
        RDModule,
        PipesModule,
        ComponentsModule,
        JavascriptCodeEditorComponent,
        DropdownComponent,
        InputComponent
    ]
})
export class ProducerWebhookEndpointFormComponent implements OnInit {
    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;
    private endpoint: ConnectionEndpointModel;
    protected readonly propertyControlOptions = new PropertyControlOptions(true, true);

    // Injections =================
    private destroyRef: DestroyRef = inject(DestroyRef);
    private fullModalService: FullModalService = inject(FullModalService);
    private connectionApiService: ConnectionApiService = inject(ConnectionApiService);
    private dataFieldDataService: DataFieldsApiService = inject(DataFieldsApiService);
    private propertyService: PropertyService = inject(PropertyService);
    private variantsApiService: VariantsApiService = inject(VariantsApiService);
    private modalData: IProducerWebhookEndpointFormComponentData = inject<IProducerWebhookEndpointFormComponentData>(NUC_FULL_MODAL_DATA);

    public models: IDropdownItem<EWebhookProducerCollectionNames>[] = [
        new DropdownItem('Briefing items', EWebhookProducerCollectionNames.CAMPAIGN_ITEM),
        new DropdownItem('Product', EWebhookProducerCollectionNames.PRODUCT),
        new DropdownItem('Asset', EWebhookProducerCollectionNames.ASSET)
    ];
    public actions: IDropdownItem<EProducerAction>[] = [
        new DropdownItem('Upsert', EProducerAction.UPSERT),
        new DropdownItem('Delete', EProducerAction.DELETE)
    ];

    public form: FormGroup<EndpointFormModel>;
    public selectedModel = signal<EWebhookProducerCollectionNames>(null);
    public selectedAction = signal<EProducerAction>(null);
    public properties = computed<RulePropertyModel[]>(() => {
        switch (this.selectedModel()) {
            case EWebhookProducerCollectionNames.CAMPAIGN_ITEM:
                return this.campaignItemProperties;
            case EWebhookProducerCollectionNames.PRODUCT:
                return this.productProperties;
            case EWebhookProducerCollectionNames.ASSET:
                return this.assetProperties;
            default:
                return [];
        }
    });
    public campaignItemProperties: RulePropertyModel[] = [];
    public productProperties: RulePropertyModel[] = [];
    public assetProperties: RulePropertyModel[] = [];
    public identifierFields = computed<IDropdownItem<string>[]>(() => {
        switch (this.selectedModel()) {
            case EWebhookProducerCollectionNames.CAMPAIGN_ITEM:
                return this.campaignItemDataFields;
            case EWebhookProducerCollectionNames.PRODUCT:
                return this.productDataFields;
            case EWebhookProducerCollectionNames.ASSET:
                return this.assetDataFields;
            default:
                return [];
        }
    });
    public campaignItemDataFields: IDropdownItem<string>[] = [];
    public productDataFields: IDropdownItem<string>[] = [];
    public assetDataFields: IDropdownItem<string>[] = [];

    public searchIdentifier = signal<string>(null);
    public selectedIdentifierFields = computed<IDropdownItem<string>[]>(
        () => {
            const regex = this.searchIdentifier() ? new RegExp(StringUtil.escapeRegExp(this.searchIdentifier()), 'i') : null;
            return this.identifierFields().filter(field => !regex || field.getTitle().match(regex)?.length > 0)
        });
    public variants = signal<VariantModel[]>([]);
    public readonly EProducerAction = EProducerAction;
    public loading = true;

    protected autocompleteExtensions = computed<((context) => CompletionResult | null)[]>(() => {
        const factory = new AutoCompleteFactory(this.identifierFields(), this.variants());
        return [factory.dataFieldsAutocomplete];
    });
    protected disabledScriptLines = new EditorReadOnlyLines(7, 2);
    protected lintDiagnotics = signal<Diagnostic[]>([]);
    private lintDiagnoticsObservable = toObservable(this.lintDiagnotics);

    public ngOnInit(): void {
        this.endpoint = this.modalData.endpointData;
        this.getData();
    }

    private getData(): void {
        forkJoin([
            this.variantsApiService.findAll(),
            this.dataFieldDataService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            this.dataFieldDataService.getAllDataFields(EDataFieldCollectionName.PRODUCT),
            this.dataFieldDataService.getAllDataFields(EDataFieldCollectionName.ASSET),
            this.propertyService.getProperties(EPropertyContext.WEBHOOK_PRODUCER_CAMPAIGN_ITEM),
            this.propertyService.getProperties(EPropertyContext.WEBHOOK_PRODUCER_PRODUCT),
            this.propertyService.getProperties(EPropertyContext.WEBHOOK_PRODUCER_ASSET)
        ])
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([
                    variants,
                    campaignItemDataFields,
                    productDataFields,
                    assetDataFields,
                    campaignItemProperties,
                    productProperties,
                    assetProperties]) =>
                {
                    this.variants.set(variants);

                    // datafields
                    this.campaignItemDataFields = campaignItemDataFields.map(campaignItemDataField => {
                        return new DropdownItem<string>(campaignItemDataField.name, `dataFields.${campaignItemDataField.fieldName}`)
                    } );
                    this.productDataFields = productDataFields.map(productDataField => {
                        return new DropdownItem<string>(productDataField.name, `dataFields.${productDataField.fieldName}`)}
                    );
                    this.assetDataFields = assetDataFields.map(assetDataField => {
                        return new DropdownItem<string>(assetDataField.name, `dataFields.${assetDataField.fieldName}`)}
                    );
                    this.assetDataFields.push(new DropdownItem('Name', 'name'));

                    // properties
                    this.campaignItemProperties = campaignItemProperties.items;
                    this.productProperties = productProperties.items;
                    this.assetProperties = assetProperties.items;

                    this.initButtons();
                    this.setupFormGroup();
                },
                error: Toaster.handleApiError
            });
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.endpoint?._id ? 'Save' : 'Create');
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        this.fullModalService.setConfirmClose(true);

        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));

        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveEndpoint();
        });

        this.fullModalService.setModalActions([cancel, save]);
    }

    private setupFormGroup(): void {
        this.selectedModel.set(this.endpoint?.collectionName || this.models[0].getValue());
        this.selectedAction.set(this.endpoint?.action || this.actions[0].getValue());

        this.form = new FormGroup<EndpointFormModel>({
            model: new FormControl(this.selectedModel(), Validators.required),
            action: new FormControl(this.selectedAction(), Validators.required),
            path: new FormControl(this.endpoint?.identifierPath, Validators.required),
            field: new FormControl(this.endpoint?.identifierField, Validators.required),
            mappings: new FormArray([]),
            script: new FormControl(this.endpoint?.script)
        });

        if (this.selectedModel() === EWebhookProducerCollectionNames.CAMPAIGN_ITEM) {
            this.form.addControl('masterBriefing', new FormControl(!!this.endpoint?.importMasterBriefing, Validators.required));

            if (!this.endpoint?.importMasterBriefing) {
                this.form.addControl('campaignNamePath', new FormControl(this.endpoint?.campaignNamePath));

                if (this.selectedAction() === EProducerAction.UPSERT) {
                    this.form.addControl('campaignStartDatePath', new FormControl(this.endpoint?.campaignStartDatePath));
                    this.form.addControl('campaignEndDatePath', new FormControl(this.endpoint?.campaignEndDatePath));
                }
            }
        }

        if (this.selectedModel() === EWebhookProducerCollectionNames.ASSET) {
            this.form.addControl('assetPath', new FormControl(this.endpoint?.assetPath, Validators.required));
        }

        if (this.selectedAction() === EProducerAction.UPSERT && this.endpoint?.mappings) {
            for (const mapping of this.endpoint.mappings) {
                const propertyControlValue = PropertyControlComponent.getPropertyValueModel(mapping.property, this.properties(),
                    this.variants(), this.propertyControlOptions)
                this.identifierFields().find(field => field.getValue() === mapping.property);
                this.form.controls.mappings.push(new FormGroup<MappingFormModel>({
                    path: new FormControl(mapping.path, Validators.required),
                    property: new FormControl(propertyControlValue, Validators.required)
                }))
            }
        }

        combineLatest([
            this.form.statusChanges
                .pipe(
                    distinctUntilChanged(),
                    startWith(EFormStatus.INVALID),
                    map((status) => status === EFormStatus.VALID)
                ),
            this.lintDiagnoticsObservable
        ]).pipe(
            map(([formStatus, diagnotics]) => formStatus && diagnotics.length === 0),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((enabled) => {
            this.saveButton.disabled = !enabled;
        });
    }

    private saveEndpoint(): void {
        const endpoint = ConnectionEndpointBody.fromFormGroup(this.form, this.endpoint?._id);

        if (this.endpoint?._id) {
            if (this.form.controls.action.value === EProducerAction.DELETE) {
                endpoint.mappings = null;
                endpoint.script = null;
            }
            this.connectionApiService.updateEndpoint(this.modalData.connectionId, this.endpoint._id, endpoint)
                .pipe(finalize(() => this.saveButton.loading = false), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        Toaster.success('Producer endpoint updated successfully');
                        this.fullModalService.close(result);
                    },
                    error: (error) => Toaster.handleApiError(error)
                });
        } else { // create mode
            this.connectionApiService.createEndpoint(this.modalData.connectionId, endpoint)
                .pipe(finalize(() => this.saveButton.loading = false), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        Toaster.success('Producer endpoint created successfully');
                        this.fullModalService.close(result);
                    },
                    error: (error) => Toaster.handleApiError(error)
                });
        }
    }

    public addMapping(): void {
        this.form.controls.mappings.push(new FormGroup<MappingFormModel>({
            path: new FormControl<string>('', Validators.required),
            property: new FormControl<PropertyValueModel>(null, Validators.required)
        }));
    }

    public deleteMapping(index: number): void {
        this.form.controls.mappings.removeAt(index);
    }

    public onModelChange(model: EWebhookProducerCollectionNames) {
        if (this.selectedModel() === model) return;

        this.selectedModel.set(model);

        this.form.controls.field.patchValue(null);

        // reset mappings when collection is changed
        this.form.controls.mappings.clear();
        switch (model) {
            case EWebhookProducerCollectionNames.PRODUCT:
                this.form.removeControl('masterBriefing')
                this.form.removeControl('campaignNamePath');
                this.form.removeControl('campaignStartDatePath');
                this.form.removeControl('campaignEndDatePath');
                this.form.removeControl('assetPath');
                this.form.controls.script.patchValue(null);
                break;
            case EWebhookProducerCollectionNames.CAMPAIGN_ITEM:
                this.form.addControl('masterBriefing', new FormControl(false, Validators.required));
                this.form.addControl('campaignNamePath', new FormControl('', Validators.required));
                this.form.addControl('campaignStartDatePath', new FormControl('', Validators.required));
                this.form.addControl('campaignEndDatePath', new FormControl('', Validators.required));
                this.form.removeControl('assetPath');
                this.form.controls.script.patchValue(null);
                break;
            case EWebhookProducerCollectionNames.ASSET:
                this.form.removeControl('masterBriefing')
                this.form.removeControl('campaignNamePath');
                this.form.removeControl('campaignStartDatePath');
                this.form.removeControl('campaignEndDatePath');
                this.form.addControl('assetPath', new FormControl('', Validators.required));
                this.form.controls.script.patchValue(null);
                break;
            default :
                // show error message of for some reason the code reaches this point.
                ARLogger.error('Collection name not supported');
        }
    }

    public onActionChanged(action: EProducerAction): void {
        if (this.selectedAction() === action) return;

        this.selectedAction.set(action);

        switch (action) {
            case EProducerAction.UPSERT:
                if (!this.form.controls.mappings) {
                    this.form.addControl('mappings', new FormArray([]));
                }
                if (!this.form.controls.script) {
                    this.form.addControl('script', new FormControl('', Validators.required));
                }

                if (this.selectedModel() === EWebhookProducerCollectionNames.PRODUCT ||
                    this.selectedModel() === EWebhookProducerCollectionNames.ASSET) {
                    break;
                }

                if (this.form.controls.masterBriefing?.value) {
                    this.form.removeControl('campaignStartDatePath');
                    this.form.removeControl('campaignEndDatePath');
                } else {
                    if (!this.form.controls.campaignStartDatePath) {
                        this.form.addControl('campaignStartDatePath', new FormControl('', Validators.required));
                    }
                    if (!this.form.controls.campaignEndDatePath) {
                        this.form.addControl('campaignEndDatePath', new FormControl('', Validators.required));
                    }
                }
                break;
            case EProducerAction.DELETE:
                this.form.removeControl('campaignStartDatePath');
                this.form.removeControl('campaignEndDatePath');
                this.form.removeControl('mappings');
                this.form.removeControl('script');
                this.form.removeControl('assetPath');
                break;

        }
    }

    public onMasterBriefingChanged(masterBriefing: boolean): void {
        if (!masterBriefing) {
            // Set campaign specific import data
            if (!this.form.controls.campaignNamePath) {
                this.form.addControl('campaignNamePath', new FormControl('', Validators.required));
            }

            if (this.selectedAction() === EProducerAction.UPSERT) {
                if (!this.form.controls.campaignStartDatePath) {
                    this.form.addControl('campaignStartDatePath', new FormControl('', Validators.required));
                }
                if (!this.form.controls.campaignEndDatePath) {
                    this.form.addControl('campaignEndDatePath', new FormControl('', Validators.required));
                }
            }
        } else {
            // Remove campaign specific import data
            this.form.removeControl('campaignNamePath');
            this.form.removeControl('campaignStartDatePath');
            this.form.removeControl('campaignEndDatePath');
        }
    }

    private getScript(): string {
        switch (this.selectedModel()) {
            case EWebhookProducerCollectionNames.CAMPAIGN_ITEM:
                return defaultCampaignItemScript;
            case EWebhookProducerCollectionNames.PRODUCT:
                return defaultProductScript;
            case EWebhookProducerCollectionNames.ASSET:
                return defaultAssetScript;
        }

        return undefined;
    }

    public addScript(): void {
        this.form.patchValue({script: this.getScript()});
    }

    public removeScript(): void {
        this.form.patchValue({script: null});
    }
}
