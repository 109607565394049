import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {TabBarRoutableItem} from '../team-settings/tab-bar-routable-item.model';
import {AppConstants} from '../../../app.constants';
import {NavigationEnd, Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {filter, map} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'asset-data-management',
    templateUrl: 'asset-data-management.component.html',
    styleUrls: ['asset-data-management.component.scss'],
    standalone: false
})

export class AssetDataManagementComponent implements OnInit {

    public dataFieldsTab = new TabBarRoutableItem(
        'Data fields',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT, AppConstants.CONTEXT_URL.DATA_FIELDS],
        []
    );

    public assetFormTab = new TabBarRoutableItem(
        'Asset form',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT, AppConstants.CONTEXT_URL.ASSET_FORM],
        []
    );

    public filterFormTab = new TabBarRoutableItem(
        'Filters',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT, AppConstants.CONTEXT_URL.FILTERS],
        []
    );

    public tabs = [this.dataFieldsTab, this.assetFormTab, this.filterFormTab];
    public activeTab: TabBarRoutableItem;
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {
        if (this.tabs.length) {
            this.setActiveTab();

            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd),
                filter((event) =>
                    event.urlAfterRedirects === `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT}`),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe(() => {
                this.setActiveTab();
            });
        }
    }

    public checkTabPermissions(): void {
        this.tabs = this.tabs.filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));
        this.activeTab = this.tabs[0];
        this.updateUrl();
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        this.activeTab = this.tabs.find((tab) => tab.title === newTab?.title) || this.tabs[0];
        this.updateUrl();
    }

    public updateUrl(): void {
        if (this.activeTab) {
            this.router.navigate(this.activeTab.url, {skipLocationChange: false});
        }
    }

    private setActiveTab() {
        switch (this.router.url) {
            /* eslint-disable-next-line max-len */
            case `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT}/${AppConstants.CONTEXT_URL.DATA_FIELDS}`:
                this.activeTab = this.tabs[0];
                break;
            /* eslint-disable-next-line max-len */
            case `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT}/${AppConstants.CONTEXT_URL.ASSET_FORM}`:
                this.activeTab = this.tabs[1];
                break;
            /* eslint-disable-next-line max-len */
            case `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.ASSET_DATA_MANAGEMENT}/${AppConstants.CONTEXT_URL.FILTERS}`:
                this.activeTab = this.tabs[2];
                break;
            default:
                this.checkTabPermissions();
        }
    }
}
