<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>

        <ng-container *ngIf="form.controls.name.touched && form.controls.name.errors as error">
            <nuc-error *ngIf="error.required">Name required</nuc-error>
        </ng-container>
    </nuc-form-field>

    <nuc-form-field label="Connection type">
        <nuc-dropdown formControlName="connectionType"
                      [items]="connections"
                      placeholder="Select an option"
                      class="connection-selector">
        </nuc-dropdown>
    </nuc-form-field>

    <div class="image-container" *ngIf="connectionTypeControl.value">
        <img class="logo" [src]="logo" [alt]="connectionTypeControl.value + '_logo'">
    </div>
</form>

<ng-container [ngSwitch]="form.controls.connectionType.value">
    <form [formGroup]="detailForm" *ngSwitchCase="EConnectionType.MEDIA_VALET">
        <nuc-form-field label="Client id">
            <nuc-input formControlName="clientId"
                       type="string"
                       placeholder="Fill in your Client ID">
            </nuc-input>

            <ng-container *ngIf="detailForm.controls.clientId.touched && detailForm.controls.clientId.errors as error">
                <nuc-error *ngIf="error.required">Client ID required</nuc-error>
            </ng-container>
        </nuc-form-field>

        <nuc-form-field label="Client secret">
            <nuc-input formControlName="clientSecret"
                       type="text"
                       placeholder="Fill in your Client Secret">
            </nuc-input>

            <ng-container
                *ngIf="detailForm.controls.clientSecret.touched && detailForm.controls.clientSecret.errors as error">
                <nuc-error *ngIf="error.required">Client secret required</nuc-error>
            </ng-container>
        </nuc-form-field>
        <nuc-form-field label="Subscription key">
            <nuc-input formControlName="subscriptionKey"
                       type="text"
                       placeholder="Fill in your Primary subscription key">
            </nuc-input>

            <ng-container
                *ngIf="detailForm.controls.subscriptionKey.touched && detailForm.controls.subscriptionKey.errors as error">
                <nuc-error *ngIf="error.required">Subscription key required</nuc-error>
            </ng-container>
        </nuc-form-field>
    </form>

    <form [formGroup]="detailForm" *ngSwitchCase="EConnectionType.SUPERUNIE_ADAM">
        <nuc-form-field label="API key">
            <nuc-input formControlName="apiKey"
                       type="text"
                       placeholder="Fill in your API key">
            </nuc-input>

            <ng-container *ngIf="detailForm.controls.apiKey.touched && detailForm.controls.apiKey.errors as error">
                <nuc-error *ngIf="error.required">API key required</nuc-error>
            </ng-container>
        </nuc-form-field>
        <nuc-form-field label="Global Location Numbers (GLN)">
            <nuc-suggestive-input placeholder="Add a global location number"
                                  formControlName="globalLocationNumbers">
            </nuc-suggestive-input>
        </nuc-form-field>
    </form>

    <form [formGroup]="detailForm" *ngSwitchCase="EConnectionType.WEBHOOK_PRODUCER">
        <nuc-form-field label="Authentication token">
            <nuc-input formControlName="authenticationToken"
                       type="string"
                       placeholder="Fill in authentication token">
            </nuc-input>

            <ng-container
                *ngIf="detailForm.controls.authenticationToken.touched && detailForm.controls.authenticationToken.errors as error">
                <nuc-error *ngIf="error.required">Authentication token required</nuc-error>
            </ng-container>
        </nuc-form-field>

        <nuc-form-field label="Secret">
            <nuc-input formControlName="secret" type="string" placeholder="Fill in secret"></nuc-input>
        </nuc-form-field>
        <nuc-form-field>
            <nuc-checkbox label="Add timestamp" formControlName="timestamp" text="Add timestamp"></nuc-checkbox>
        </nuc-form-field>
    </form>
</ng-container>
