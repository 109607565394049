import {Component, inject, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../../../../app.constants';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnSize,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent, InputComponent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn, NucDialogConfigModel,
    NucDialogService, NUCDirectivesModule,
    NUCInputsModule,
    NUCTableModule,
    RDModule
} from '@relayter/rubber-duck';
import {combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import {
    ESegmentTrackEvents,
    ISegmentService,
    SEGMENT_SERVICE
} from '../../../../services/segment/segment.service.interface';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../../../api/services/last-viewed-items.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ProjectsDataProvider} from './projects.data-provider';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {TitleCasePipe} from '@angular/common';
import {ComponentsModule} from '../../../../components/components.module';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ProjectModel} from '../../../../models/api/project.model';
import {EProjectJobTypes, ProjectsApiService} from '../../../../api/services/projects.api.service';
import {IProjectFormData, ProjectFormComponent} from '../../../../forms/project-form/project-form.component';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {Toaster} from '../../../../classes/toaster.class';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';

@Component({
    selector: 'projects-overview',
    templateUrl: 'projects-overview.component.html',
    styleUrls: ['projects-overview.component.scss'],
    providers: [PaginatorService],
    imports: [
        ComponentsModule,
        NUCInputsModule,
        NUCTableModule,
        PipesModule,
        RDModule,
        InputComponent,
        NUCDirectivesModule
    ],
})

export class ProjectsOverviewComponent extends RLTableComponent implements OnInit {
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private titleCasePipe: TitleCasePipe = inject(TitleCasePipe);
    private projectsApiService: ProjectsApiService = inject(ProjectsApiService);
    private dialogService: NucDialogService = inject(NucDialogService);
    private fullModalService: FullModalService = inject(FullModalService);
    private userIsAllowedToPipe: UserIsAllowedToPipe = inject(UserIsAllowedToPipe);
    private paginatorService: PaginatorService = inject(PaginatorService);
    private lastViewedItemsService: LastViewedItemsService = inject(LastViewedItemsService);
    private monitoredJobsService: MonitoredJobsService = inject(MonitoredJobsService);

    public readonly context = ELastViewedItemsContext.PROJECT;
    public readonly tableId = 'projects-overview-table';

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Status',
            key: 'status',
            sortProperty: 'status',
            format: (value) => this.titleCasePipe.transform(value)
        },
        {
            title: 'Tags',
            key: 'tags',
            format: (value) => value?.join(', ')
        },
        {
            title: 'Variants',
            key: 'variants',
            format: (value) => value?.map(val => val.name).join(', ')
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }, {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    public items: ProjectModel[] = [];
    public actions: ITableAction[] = [];
    public applyLastViewedItemIds: boolean;
    public lastViewedItemIds: string[];

    public projectDataProvider = new ProjectsDataProvider(this.tableSortOptions);

    private defaultSortColumns = [{
        key: 'createdAt',
        sortProperty: 'createdAt',
        sortDuplicates: true,
        dataType: EColumnDataType.DATE
    }] as ITableColumn[];

    constructor(@Inject(SEGMENT_SERVICE) private segmentService: ISegmentService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.projectDataProvider.sortOptions.columns.push(...this.defaultSortColumns);
        this.projectDataProvider.sortOptions.sortOrder = 'desc';

        this.initFromRoute();
        this.initActions();
        this.listenToPanelControl(); // pagination and lastViewedItemsCheckbox
    }

    private initActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_PROJECT)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_PROJECT)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    private listenToPanelControl(): void {
        combineLatest([
            this.paginatorService.getPagination(this.tableId),
            this.lastViewedItemsService.getState(this.context).pipe(
                filter((res) => {
                    this.lastViewedItemIds = res.itemIds;
                    if (res.applied && !this.applyLastViewedItemIds && this.projectDataProvider.pageIndex !== 1) {
                        this.applyLastViewedItemIds = res.applied;
                        this.paginatorService.setPageIndex(this.tableId, 1);
                        return false; // filter out this state emission to avoid duplicate api call
                    }
                    this.applyLastViewedItemIds = res.applied;
                    return true;
                })
            )
        ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([pagination]) => {
            if (pagination.pageIndex === 1 || pagination.pageSize !== this.projectDataProvider.pageSize) {
                this.projectDataProvider.resetCursorArray(pagination.pageIndex);
            }

            this.projectDataProvider.pageIndex = pagination.pageIndex;
            this.projectDataProvider.pageSize = pagination.pageSize;

            this.router.navigate([AppConstants.CONTEXT_URL.PROJECTS, this.createMatrixUrl()]);

            this.getProjects();
        });
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PROJECT)) {
            this.router.navigate([AppConstants.PROJECT_PATH, event.item._id], {skipLocationChange: false});
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.projectDataProvider.searchValue = params['search'];
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    private getProjects(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PROJECTS)) {
            this.projectDataProvider.filterValues = {};

            if (this.applyLastViewedItemIds) this.projectDataProvider.filterValues._id = this.lastViewedItemIds;

            this.projectDataProvider.retrieveData();
        }
    }

    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(null, null, null, null, this.projectDataProvider.searchValue || '');
    }

    public onSearchBarValueUpdated(): void {
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public openProjectForm (project?: ProjectModel): void {
        const data = {project} as IProjectFormData;
        const config = new FullModalConfig(
            project ?
                'Edit Project' :
                'Add Project',
            project ?
                'Update the information of the Project.' :
                'Enter the information of the Project.',
            data);
        config.confirmClose = true;

        this.fullModalService.open(ProjectFormComponent, config)
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => {
            if (result) {
                !project && this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PROJECT) ?
                    this.router.navigate([AppConstants.PROJECT_PATH, result._id]) :
                    this.getProjects();
            }
        });
    }

    public handleTableAction(event: IActionClickEvent): void {
        const project = event.item as ProjectModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT:
                this.openProjectForm(project);
                break;
            case AppConstants.TABLE_ACTION_TYPES.DELETE:
                this.openDeleteDialog(project);
                break;
        }
    }

    private openDeleteDialog(project: ProjectModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete project',
            `Please confirm that you wish to delete ${project.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            deleteDialog.close();
        });
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteProject(project);
        });
    }

    public deleteProject(project: ProjectModel): void {
        const jobData = {
            projectId: project._id
        };

        this.projectsApiService.postJob(EProjectJobTypes.DELETE_PROJECT_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (scheduleJob: JobModel) => {
                    this.monitoredJobsService.getItemMonitor(scheduleJob._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.segmentService.track(ESegmentTrackEvents.TRACK_REMOVE_PROJECT);

                                this.getProjects();
                                this.router.navigate([AppConstants.PROJECT_PATH]);
                            }
                        });
                },
                error: Toaster.handleApiError
            });
    }
}
