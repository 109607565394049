import {NgModule} from '@angular/core';
import {DropdownComponent, DropdownMultiSelectComponent, InputComponent, NUCDirectivesModule, RDModule} from '@relayter/rubber-duck';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../../components/components.module';
import {ContentRulesComponent} from './content-rules.component';
import {ContentRuleFormComponent} from './content-rule-form/content-rule-form.component';
import {PipesModule} from '../../../pipes/pipes.module';

@NgModule({
    imports: [
        RDModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        CommonModule,
        PipesModule,
        DropdownMultiSelectComponent,
        DropdownComponent,
        InputComponent,
        NUCDirectivesModule
    ],
    providers: [],
    declarations: [ContentRulesComponent, ContentRuleFormComponent],
    exports: [ContentRulesComponent]
})
export class ContentRulesModule {
}
