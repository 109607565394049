@if (PERMISSIONS.GET_TEAM_SETTING_DATA_FILTER | userIsAllowedTo) {
    @if (dataSubscription?.closed && !!formGroup.controls.dataFilters.controls[collection]) {
        <form [formGroup]="formGroup.controls.dataFilters">
            <nuc-button-bar>
                @if ((PERMISSIONS.POST_TEAM_SETTING_DATA_FILTER | userIsAllowedTo) &&
                    (PERMISSIONS.PATCH_TEAM_SETTING_DATA_FILTER | userIsAllowedTo)
                ) {
                    <nuc-button-primary text="Save changes" (click)="saveDataFilter()">
                    </nuc-button-primary>
                }
            </nuc-button-bar>

            <div [formGroupName]="collection">
                <data-filter-template
                    [filtersForm]="formGroup.controls.dataFilters.controls[collection]"
                    [filters]="filters"/>
            </div>
        </form>
    }
} @else {
    <no-permission-state-component></no-permission-state-component>
}
