<div class="description">
    <nuc-label>Variant data</nuc-label>
    <p>The variant of the data field will be used if available</p>
</div>

<div class="description">
    <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant(s)</nuc-label>
    <nuc-dropdown-multiselect class="variant"
                              placeholder="Select variants"
                              [data]="selectedVariants"
                              (dataChange)="onVariantsChanged($event)"
                              [items]="variants">
    </nuc-dropdown-multiselect>
</div>

<ng-container *ngIf="variantPresetsFormArray?.controls.length > 0">
    <hr>

    <div class="description">
        <nuc-label>Preset per variant</nuc-label>
        <p>It is optional to set a preset per selected variant</p>
    </div>

    <ng-container *ngFor="let control of variantPresetsFormArray.controls; last as isLast">
        <static-content-template-variant-preset
            [formGroup]="control"
            [engineType]="engineType"
            [channel]="channel"
            [variants]="variants"/>
        <hr *ngIf="!isLast">
    </ng-container>

</ng-container>
