import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectModel} from '../../../../models/api/project.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../classes/toaster.class';
import {ProjectsApiService} from '../../../../api/services/projects.api.service';
import {
    FullModalConfig,
    FullModalService,
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCTabBarModule, RDModule
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {TitleCasePipe} from '@angular/common';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ComponentsModule} from '../../../../components/components.module';
import {Subscription} from 'rxjs';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {WorkflowModel} from '../../../../models/api/workflow.model';
import {WorkflowFormComponent} from '../../../../forms/workflow-form/workflow-form.component';
import {filter} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EPropertySettingsContext} from '../../../../components/property-settings/property-settings.service';
import {AdvancedFiltersDataService} from '../../../../api/services/advanced-filters.data-service';
import {WorkflowsApiService} from '../../../../api/services/workflows.api.service';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {NewCursorArray} from '../../../../api/new-api-cursor';
import {TableSortOptions} from '../../../../api/table-sort-options';

@Component({
    selector: 'project-detail',
    templateUrl: './project-detail.component.html',
    styleUrl: './project-detail.component.scss',
    imports: [
        ComponentsModule,
        NUCTabBarModule,
        NUCButtonBarModule,
        NUCButtonsModule,
        RDModule,
        PipesModule,
        TitleCasePipe
    ],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class ProjectDetailComponent extends RLBaseComponent implements OnInit {
    public readonly viewId = 'project-workflows-overview';
    protected readonly EPropertySettingsContext = EPropertySettingsContext;

    public route: ActivatedRoute = inject(ActivatedRoute);
    public router: Router = inject(Router);
    public projectsApiService: ProjectsApiService = inject(ProjectsApiService);
    public workflowsApiService: WorkflowsApiService = inject(WorkflowsApiService);
    public fullModalService: FullModalService = inject(FullModalService);
    public paginatorService: PaginatorService = inject(PaginatorService);

    public projectId: string;
    public project: ProjectModel;

    public workflowsSubscription: Subscription;
    public workflows: WorkflowModel[];

    public searchValue: string;
    public disableNextPage = true;
    public pageIndex: number;
    public pageSize: number;

    public readonly storageKey: string;
    public tableSortOptions: TableSortOptions = new TableSortOptions();
    public newApiCursor = new NewCursorArray(1, this.tableSortOptions);

    public constructor(protected userSettingsStorageService: UserSettingsStorageService) {
        super();
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }

    public ngOnInit(): void {
        this.initFromRoute();

        this.getProject();

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();

                this.getWorkflows();
            });
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        this.projectId = params.project_id;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.setPageIndex(pageIndex);
    }

    private updateUrl(): void {
        this.router.navigate([AppConstants.CONTEXT_URL.PROJECTS, this.projectId, this.createMatrixUrl()], {replaceUrl: true});
    }

    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, null, null, this.searchValue || '');
    }

    public getProject(): void {
        this.projectsApiService.findOne(this.projectId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (result) => this.project = result,
                    error: Toaster.handleApiError
                }
            );
    }

    public getWorkflows(): void {
        if (this.workflowsSubscription) this.workflowsSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.workflowsSubscription = this.workflowsApiService.getWorkflows(
            this.projectId, this.pageSize, offset, cursor, this.searchValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.workflows = result.items;
                    this.disableNextPage = !result.hasNext;

                    if (this.workflows.length > 0) {
                        const workflow = this.workflows[this.workflows.length - 1];
                        this.newApiCursor.setCursor(this.pageIndex, workflow);
                    }
                },
                    error: Toaster.handleApiError
                }
            );
    }

    public openAddWorkflowModal(): void {
        const modalData = { projectId: this.project._id };
        const modalConfig = new FullModalConfig('Add workflow', 'Setup your workflow details.', modalData);
        modalConfig.confirmClose = true;

        const modal = this.fullModalService.open(WorkflowFormComponent, modalConfig);
        modal.afterClosed().pipe(filter((refresh: boolean) => refresh)).subscribe(() => {
            this.getWorkflows();
        });
    }

    public setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.viewId, pageIndex);
    }

    public onSearch(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;
            this.setPageIndex();
        }
    }
}
