<div class="header">
    <h1>{{ afterEffectsProjectFile?.name }}</h1>
</div>

@if (compositions?.length) {
    <nuc-dropdown [nullOption]="false"
                  [items]="compositions"
                  [total]="totalCompositions"
                  [data]="selectedCompositionId"
                  (dataChange)="compositionChanged($event)">
    </nuc-dropdown>
}

@if(tabs.length) {
    <nuc-tab-bar [tabs]="tabs"
                 [(activeTab)]="activeTab"
                 (activeTabChange)="onActiveTabChanged($event)">
    </nuc-tab-bar>
    <hr>
    <router-outlet></router-outlet>
}
