import {Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalConfig,
    FullModalService
} from '@relayter/rubber-duck';
import {FormBuilderComponent, IFormBuilderModalData} from '../form-builder/form-builder.component';
import {EFormContext, FormDetailModel} from '../../../../models/api/form.model';
import {FormService} from '../../../../api/services/form.service';
import {filter, map, switchMap} from 'rxjs/operators';
import {forkJoin, of, Subscription} from 'rxjs';
import {AppConstants} from '../../../../app.constants';
import {ActivatedRoute} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {VariantService} from '../../../../api/services/variant.service';
import {VariantModel} from '../../../../models/api/variant.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup} from '@angular/forms';
import {
    DataFieldsInformationFormComponent
} from '../../../../forms/data-fields-information-form-component/data-fields-information-form.component';

@Component({
    selector: 'rl-form-overview',
    templateUrl: 'form-overview.component.html',
    styleUrls: ['form-overview.component.scss'],
    standalone: false
})

export class FormOverviewComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @ViewChild('formPreview') public formPreviewComponent: TemplateRef<DataFieldsInformationFormComponent>;

    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    private context: EFormContext;

    public formConfig: FormDetailModel;
    public dataSubscription: Subscription;
    public form: FormGroup;

    public variants: VariantModel[];

    constructor(private fullModalService: FullModalService,
                private formService: FormService,
                private variantService: VariantService,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {
        this.context = this.route.snapshot.data['context'];
        this.getData();
    }

    private getData(): void {
        this.dataSubscription = forkJoin([
            this.formService.getForms(this.context, 1)
                .pipe(
                    map((result) => result.items[0]),
                    switchMap((form) => form ? this.formService.getForm(form._id) : of(null))
                ),
            this.userIsAllowedToPipe.transform(this.PERMISSIONS.GET_VARIANTS) ? this.variantService.getVariants() : of(null),
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([formConfig, variantsResult]) => {
                    this.formConfig = formConfig;
                    this.variants = variantsResult?.items;
                    this.form = new FormGroup({}); // always starts with a clean form
                },
                error: Toaster.handleApiError
            })
    }

    public onEditFormClick(): void {
        const modalData = {context: this.context, formId: this.formConfig?._id} as IFormBuilderModalData;

        let modalTitle, modalDescription;

        switch (this.context) {
            case EFormContext.PRODUCT:
                modalTitle = 'Edit product form';
                modalDescription = 'Customize your product form.';
                break;
            case EFormContext.BRIEFING:
                modalTitle = 'Edit briefing form';
                modalDescription = 'Customize your briefing form.';
                break;
            case EFormContext.ASSET:
                modalTitle = 'Edit asset form';
                modalDescription = 'Customize your asset form.';
                break;
            default:
                modalTitle = `Unknown context for form-builder: ${this.context}`;
                modalDescription = 'Not implemented';
                break;
        }

        const config = new FullModalConfig(modalTitle, modalDescription, modalData);
        config.confirmClose = true;

        this.fullModalService.open(FormBuilderComponent, config)
            .afterClosed()
            .pipe(
                filter((result) => !!result)
            )
            .subscribe(() => this.getData());
    }

    public openFormPreviewModal(): void {
        const config = new FullModalConfig(
            `Preview ${this.formConfig.context} form`,
            'This form is only viewable. Filling out this form has no use.');

        const closeAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Close'));
        closeAction.observable.subscribe(() => this.fullModalService.close());
        config.addAction(closeAction);

        this.fullModalService.open(this.formPreviewComponent, config);
    }
}
