import {ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MasterPageModel} from '../../../../../../models/api/master-page.model';
import {DesignLibraryModel} from '../../../../../../modules/static-content-rulesets/models/api/design-library.model';
import {StaticContentRulesetModel} from '../../../../../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {Toaster} from '../../../../../../classes/toaster.class';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {ITableColumn} from '@relayter/rubber-duck';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {EChannel} from '../../../../../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ITemplatePresetForm} from '../../static-content-template-preset/static-content-template-preset.component';
import {EEngineType} from '../../../../../../models/api/template.model';
import {DesignLibraryApiService} from '../../../../../../api/services/design-library.api.service';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {map} from 'rxjs/operators';
import {QueryParams} from '../../../../../../classes/query-params';
import {StaticContentRulesetApiService} from '../../../../../../api/services/static-content-ruleset.api.service';
import {MasterPageApiService} from '../../../../../../api/services/master-page.api.service';

export interface ITemplateVariantPresetForm {
    variant: FormControl<string>;
    masterPage: FormControl<string>;
    designLibrary: FormControl<string>;
    ruleset: FormControl<string>;
}
@Component({
    selector: 'static-content-template-variant-preset',
    templateUrl: 'template-variant-preset.component.html',
    styleUrls: ['template-variant-preset.component.scss'],
    standalone: false
})
export class TemplateVariantPresetComponent implements OnInit, OnChanges {
    private destroyRef = inject(DestroyRef);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef)
    @Input() public formGroup: FormGroup<ITemplatePresetForm | ITemplateVariantPresetForm>;
    @Input() public channel: EChannel;
    @Input() public engineType: EEngineType;
    @Input() public variants: VariantModel[];
    @Output() public masterPageChange = new EventEmitter<MasterPageModel>();
    protected readonly EEngineType = EEngineType;

    public selectedVariant: VariantModel;
    public selectedMasterPage: MasterPageModel;
    public selectedLibrary: DesignLibraryModel;
    public selectedRuleset: StaticContentRulesetModel;

    public masterPages: MasterPageModel[] = [];
    public masterPageSubscription: Subscription;
    private masterPagePage = 0;
    public totalMasterPages: number;

    public libraries: DesignLibraryModel[] = [];
    public librarySubscription: Subscription;
    private libraryPage = 0;
    public totalLibraries: number;

    public rulesets: StaticContentRulesetModel[] = [];
    public rulesetSubscription: Subscription;
    private rulesetPage = 0;
    public totalFormatRulesets: number;

    public nameColumn: ITableColumn = {
        title: 'Name',
        key: 'name',
        sortProperty: 'name'
    };
    public tableSortOptions = new TableSortOptions([this.nameColumn], 'asc')

    constructor(private masterPageApiService: MasterPageApiService,
                private designLibraryApiService: DesignLibraryApiService,
                private formatRulesetApiService: StaticContentRulesetApiService) {
    }

    public ngOnInit(): void {
        // when channel changes, we should get new master pages
        this.initData();

        this.formGroup.controls.masterPage.valueChanges
            .pipe(
                map(masterPageId => this.masterPages.find(masterPage => masterPage._id === masterPageId)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(masterPage => {
                this.selectedMasterPage = masterPage;
                this.onMasterPageChange();
            });

        this.formGroup.controls.designLibrary.valueChanges
            .pipe(
                map(libraryId => this.libraries.find(library => library._id === libraryId)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(library => this.onLibraryChanged(library));

        this.formGroup.controls.ruleset.valueChanges
            .pipe(
                map(rulesetId => this.rulesets.find(ruleset => ruleset._id === rulesetId)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(ruleSet => this.selectedRuleset = ruleSet);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.channel) {
            this.getMasterPages({reset: true} as IDropdownRequestDataEvent);
        }
        if (changes.engineType) {
            this.getDesignLibraries({reset: true} as IDropdownRequestDataEvent);
        }
    }

    private initData(): void {
        // variant is not a control in template preset form
        if (this.formGroup.value['variant']) {
            this.selectedVariant = this.variants.find(variant => variant.key === this.formGroup.value['variant']);
        }

        if (this.formGroup.value.designLibrary) {
            if (this.formGroup.controls.ruleset.disabled) this.formGroup.controls.ruleset.enable();
            this.getRulesets();
        } else {
            this.formGroup.controls.ruleset.disable();
        }
    }

    public getMasterPages(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.masterPages = [];
            this.masterPagePage = 0;
            this.totalMasterPages = 0;
            this.masterPageSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.masterPageSubscription && !this.masterPageSubscription.closed) {
            return;
        }

        const queryParams = new QueryParams()
            .setSortingParams(this.tableSortOptions)
            .setSearchParams(event?.search)
            .addParam('channel', this.channel);

        this.masterPageSubscription = this.masterPageApiService.findAll(queryParams)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (masterPages) => {
                    this.masterPages = masterPages;
                    this.totalMasterPages = masterPages.length;
                    this.changeDetectorRef.markForCheck();

                    if (!this.selectedMasterPage && this.formGroup.value.masterPage) {
                        this.selectedMasterPage = this.masterPages.find(masterPage => masterPage._id === this.formGroup.value.masterPage);
                        this.onMasterPageChange();
                    }
                });
    }

    private onMasterPageChange(): void {
        this.masterPageChange.emit(this.selectedMasterPage);
    }

    public getDesignLibraries(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.libraryPage = 0;
            this.libraries = [];
            this.totalLibraries = 0;
            this.librarySubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.librarySubscription && !this.librarySubscription.closed) {
            return;
        }

        const engineTypes = this.engineType ? [this.engineType] : [];
        const queryParams = new QueryParams()
            .setSortingParams(this.tableSortOptions)
            .setSearchParams(event?.search)
            .addParam('engineTypes', engineTypes);

        this.librarySubscription = this.designLibraryApiService
            .findAll(queryParams)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (libraries) => {
                    this.libraryPage += 1;
                    this.libraries = libraries;
                    this.totalLibraries = libraries.length;
                    this.changeDetectorRef.markForCheck();

                    if (!this.selectedLibrary && this.formGroup.value.designLibrary) {
                        this.selectedLibrary = this.libraries.find(library => library._id === this.formGroup.value.designLibrary);
                    }
                });
    }

    public getRulesets(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.rulesets = [];
            this.rulesetPage = 0;
            this.totalFormatRulesets = 0;
            this.rulesetSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.rulesetSubscription && !this.rulesetSubscription.closed) {
            return;
        }

        const queryParams = new QueryParams()
            .setSortingParams(this.tableSortOptions)
            .setSearchParams(event?.search)
            .addParam('libraryId', this.formGroup.value.designLibrary);
        this.rulesetSubscription = this.formatRulesetApiService.findAll(queryParams)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (ruleSets) => {
                    this.rulesetPage += 1;
                    this.rulesets = ruleSets;
                    this.totalFormatRulesets = ruleSets.length;
                    this.changeDetectorRef.markForCheck();

                    if (!this.selectedRuleset && this.formGroup.value.ruleset) {
                        this.selectedRuleset = this.rulesets.find(ruleset => ruleset._id === this.formGroup.value.ruleset);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onLibraryChanged(library: DesignLibraryModel): void {
        this.selectedLibrary = library;
        this.rulesetSubscription?.unsubscribe();

        this.rulesetPage = 0;
        this.rulesets = [];
        this.totalFormatRulesets = 0;

        if (!library || !this.selectedRuleset?.libraries.includes(this.selectedLibrary?._id)) {
            this.formGroup.patchValue({ruleset: null});
        }

        if (this.selectedLibrary) {
            if (this.formGroup.controls.ruleset.disabled) {
                this.formGroup.controls.ruleset.enable({emitEvent: false});
            }
            this.getRulesets();
        } else {
            this.formGroup.controls.ruleset.disable({emitEvent: false});
        }
    }
}
