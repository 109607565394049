import {Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation} from '@angular/core';
import {
    ENotificationActionType,
    ENotificationType,
    ENotificationStatus,
    NotificationActionModel,
    NotificationModel
} from '../../../models/api/notification.model';
import {EJobStatus} from '../../../models/api/job.model';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {ComponentsModule} from '../../../components/components.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {SafeNotificationHtmlPipe} from './safe-notification-html.pipe';
import {RDModule} from '@relayter/rubber-duck';

export interface INotificationActionEvent {
    notification: NotificationModel;
    action: NotificationActionModel;
}

@Component({
    selector: 'rl-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        ComponentsModule,
        PipesModule,
        SafeNotificationHtmlPipe,
        RDModule
    ]
})
export class NotificationItemComponent {
    public RLDatePipe = RLDatePipe;

    public ENotificationStatus = ENotificationStatus;
    public ENotificationType = ENotificationType;
    public EJobStatus = EJobStatus;
    public ENotificationActionType = ENotificationActionType;

    @Input() public notification: NotificationModel;
    @HostBinding('class.selected') @Input() selected: boolean;
    @Input() public showFullDate: boolean;

    @Output() public actionClicked = new EventEmitter<INotificationActionEvent>();
    @Output() public archiveButtonClicked = new EventEmitter<void>();
}
