@if (formGroup) {
    <form [formGroup]="formGroup">
        <div>
            <h2>Preset</h2>
            <hr>

            <nuc-form-field label="Name">
                <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Tags">
                <nuc-suggestive-input
                    formControlName="tags"
                    (searchTermChange)="onTagChanged($event)"
                    [options]="tagOptions"
                    placeholder="Add tags">
                </nuc-suggestive-input>
            </nuc-form-field>

            <nuc-form-field label="Engine type">
                <nuc-dropdown
                    formControlName="engineType"
                    placeholder="Select an engine type"
                    [nullOption]="false"
                    [items]="animatedContentEngineTypes"
                    [required]="true">
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="Channel">
                <nuc-dropdown
                    formControlName="channel"
                    [nullOption]="false"
                    [items]="channelOptions"
                    [required]="true">
                </nuc-dropdown>
            </nuc-form-field>

            <animated-content-template-variant-preset-component
                [formGroup]="formGroup.controls.preset">
            </animated-content-template-variant-preset-component>

        </div>
        @if (variantsEnabled) {
            <div>
                <h2>Variants</h2>
                <p>
                    Add one or more variants with their appropriate After Effects project file and animated content ruleset.
                </p>
                <hr>

                <nuc-form-field>
                    <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant(s)</nuc-label>
                    <nuc-dropdown-multiselect class="variant"
                                              placeholder="Select variants"
                                              [data]="selectedVariants"
                                              (dataChange)="onVariantsChanged($event)"
                                              [items]="variants">
                    </nuc-dropdown-multiselect>
                </nuc-form-field>

                @if (variantPresetFormArray?.controls.length > 0) {
                    <ng-container formArrayName="variantsPresets">
                        @for (control of variantPresetFormArray.controls; track control) {
                            <animated-content-template-variant-preset-component
                                [formGroup]="control"
                                [selectedVariant]="getVariant(control.value.variant)"/>
                            @if (!$last) {
                                <hr>
                            }
                        }
                    </ng-container>
                }
            </div>
        }
    </form>
}
