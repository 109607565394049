import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InviteService} from '../../../../api/services/invites.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {Invite} from '../../../../models/api/invite.model';
import {Subject, Subscription} from 'rxjs';
import {TableActionTypeModel} from '../../../../models/ui/table-action-type.model';
import {Clipboard} from '@angular/cdk/clipboard';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    NucDialogConfigModel,
    NucDialogService,
    DialogActionModel,
    ITableColumn, ITableAction, IActionClickEvent, ISortOptionEvent, ESortOrder
} from '@relayter/rubber-duck';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {UserManagementDataService} from '../user-management.data-service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';

@Component({
    selector: 'rl-pending-invites',
    templateUrl: 'pending-invites.component.html',
    styleUrls: ['pending-invites.component.scss'],
    providers: [PaginatorService],
    standalone: false
})
export class PendingInvitesComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'pending-invites-overview';
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public disableNextPage = true;

    public inviteColumns: ITableColumn[] = [
        {
            title: 'Email',
            key: 'email',
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'formattedRoles'
        },
        {
            title: 'Expires',
            key: 'expiresIn',
            sortProperty: 'createdAt',
            format: (expiresIn) => {
                return RLDatePipe.format(new Date(expiresIn), RLDatePipe.dateFormats.TABLE_DETAILED);
            }
        }
    ];
    public actions: ITableAction[];
    private copyLinkAction = new TableActionTypeModel('Copy invite link', 'nucicon_link');

    public invites: Invite[];
    public inviteSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private inviteService: InviteService,
                private clipboard: Clipboard,
                private dialogService: NucDialogService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private userManagementDataService: UserManagementDataService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.userManagementDataService.refreshInvites$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(() => this.getPendingInvites());

        this.subscribeToRouteParams();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;

                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getPendingInvites();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private subscribeToRouteParams(): void {
        this.route.params.pipe(takeUntil(this.onDestroySubject)).subscribe(params => {
            const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            const sortProperty = params['sortProperty'];
            const sortOrder = params['sortOrder'] ? params['sortOrder'] : null;
            const searchValue = params['search'] ? params['search'] : null;

            // Only on change get new data
            if (pageIndex !== this.pageIndex ||
                sortProperty !== this.sortProperty ||
                sortOrder !== this.sortOrder ||
                searchValue !== this.searchValue) {
                this.pageIndex = pageIndex;
                this.sortProperty = sortProperty;
                this.sortOrder = sortOrder;
                this.searchValue = searchValue;

                this.setPageIndex(this.pageIndex);
            }

            this.updateUrl();
        });
    }

    private setTableActions(): void {
        this.actions = [this.copyLinkAction];
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_INVITE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

    }

    private updateUrl(): void {
        this.router.navigate([this.getMatrixUrl()], {relativeTo: this.route, replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    private getPendingInvites(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_INVITES)) return;

        if (this.inviteSubscription && !this.inviteSubscription.closed) {
            this.inviteSubscription.unsubscribe();
        }

        this.inviteSubscription = this.inviteService.getInvites(
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            this.sortProperty,
            this.sortOrder,
            this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (res: ARPagedResponseDataModel<Invite>) => {
                    this.total = res.total;
                    this.invites = res.items;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                },
                Toaster.handleApiError
            );
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const item = event.item as Invite;

        if (item instanceof Invite && event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.onDeleteInviteClicked(item);
        }
        if (item instanceof Invite && event.action === this.copyLinkAction) {
            this.copyInviteLinkToClipboard(item);
        }
    }

    private onDeleteInviteClicked(invite: Invite): void {
        const dialogConfig = new NucDialogConfigModel(
            'Delete invite',
            'Please confirm that you wish to delete this invite. The user won\'t be able to use this invite to register anymore.');

        const dialog = this.dialogService.openDialog(dialogConfig);
        const deleteButton = new DialogButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete');
        const deleteAction = new DialogActionModel(new Subject(), deleteButton);

        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancelAction = new DialogActionModel(new Subject(), cancelButton);

        dialogConfig.actions.push(cancelAction, deleteAction);

        cancelAction.observable.subscribe(() => dialog.close());
        deleteAction.observable.pipe(
            tap(() => deleteButton.loading = true),
            switchMap(() => this.inviteService.deleteInvite(invite._id))
        ).subscribe((res) => {
                dialog.close();
                if (res) {
                    Toaster.success('Invite successfully deleted');
                    this.getPendingInvites();
                } else {
                    Toaster.error('Could not delete invite');
                }
            }
        );
    }

    private copyInviteLinkToClipboard(invite: Invite): void {
        this.clipboard.copy(invite.link) ?
            Toaster.success('Copied to clipboard!') :
            Toaster.error('Could not copy to clipboard');
    }
}
