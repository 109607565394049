import {Component, inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnDataType, EColumnSize,
    EColumnType,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent, InputComponent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NUCButtonBarModule,
    NUCButtonsModule,
    NucDialogConfigModel,
    NucDialogService, NUCDirectivesModule,
    NUCEmptyStateModule,
    NUCInputsModule,
    NUCTableModule
} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {filter} from 'rxjs/operators';
import {Toaster} from '../../../../../classes/toaster.class';
import {ComponentsModule} from '../../../../../components/components.module';
import {Subscription} from 'rxjs';
import {RLDatePipe} from '../../../../../pipes/rl-date.pipe';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {AppConstants} from '../../../../../app.constants';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {MatrixUrlParams} from '../../../../../models/ui/matrix-url-params.model';
import {
    AnimatedContentTemplateFormComponent,
    IAnimatedContentTemplateModalData
} from '../../../../../forms/animated-content-template-form/animated-content-template-form.component';
import {TemplatesApiService} from '../../../../../api/services/templates.api.service';
import {EEngineType, TemplateDetailModel, TemplateModel} from '../../../../../models/api/template.model';
import {EmptyValuePipe} from '../../../../../pipes/empty-value.pipe';

@Component({
    selector: 'animated-content-template-overview-component',
    templateUrl: 'animated-content-template-overview.component.html',
    styleUrls: ['animated-content-template-overview.component.scss'],
    imports: [
        NUCButtonBarModule,
        NUCButtonsModule,
        PipesModule,
        ComponentsModule,
        NUCEmptyStateModule,
        NUCInputsModule,
        NUCTableModule,
        InputComponent,
        NUCDirectivesModule
    ],
    providers: [PaginatorService]
})
export class AnimatedContentTemplateOverviewComponent extends RLTableComponent implements OnInit  {
    public tableId = 'animated-content-template-overview-table';

    private fullModalService = inject(FullModalService);
    private paginatorService = inject(PaginatorService);
    private userIsAllowedPipe = inject(UserIsAllowedToPipe);
    private templatesApiService = inject(TemplatesApiService);
    private dialogService = inject(NucDialogService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    protected readonly ESelectionMode = ESelectionMode;
    public dataSubscription: Subscription;

    public items: TemplateModel[];
    public actions: ITableAction[] = [];

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Engine type',
            key: 'engineType',
            sortProperty: 'engineType',
            size: EColumnSize.SMALL
        },
        {
            title: 'Channel',
            key: 'channel',
            sortProperty: 'channel',
            size: EColumnSize.SMALL
        },
        {
            title: 'Tags',
            key: 'tags',
            sortProperty: 'tags',
            format: (v) => v.join(', ')
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Created by',
            key: 'createdBy',
            sortProperty: 'createdBy',
            sortDuplicates: true,
            type: EColumnType.DEFAULT,
            format: (value) => EmptyValuePipe.transform(value?.fullName, EmptyValuePipe.defaultValues.DELETED_USER)
        }
    ];

    constructor(userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        if (this.userIsAllowedPipe.transform(this.permissions.POST_TEMPLATE) &&
            this.userIsAllowedPipe.transform(this.permissions.GET_TEMPLATES)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

        this.initFromRoute();
        this.listenToPagination();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getData();
            });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.searchValue);
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.ANIMATED_CONTENT_TEMPLATES, matrixUrl], {replaceUrl: true});
    }

    private getData(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.templatesApiService.find(this.pageSize, offset, this.tableSortOptions, cursor, this.searchValue,
                { engineTypes: [EEngineType.AFTER_EFFECTS] })
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public openAnimatedContentTemplateModal(animatedContentTemplate?: TemplateDetailModel): void {
        const modalConfig = animatedContentTemplate ?
            new FullModalConfig('Update animated content template',
                'Define your animated content template.') :
            new FullModalConfig('Add animated content template',
                'Define your animated content template.');
        modalConfig.confirmClose = true;
        modalConfig.data = { ...(animatedContentTemplate && {template: animatedContentTemplate})} as IAnimatedContentTemplateModalData;

        this.fullModalService.open(AnimatedContentTemplateFormComponent, modalConfig)
            .afterClosed()
            .pipe(
                filter((refresh: boolean) => refresh),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => {
                if (result) {
                    this.setPageIndex();
                }
            });
    }

    private openDeleteAnimatedContentTemplateDialog(animatedContentTemplate: TemplateDetailModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete animated content template',
            `Please confirm that you wish to delete ${animatedContentTemplate.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            this.templatesApiService.deleteOne(animatedContentTemplate._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result: boolean) => {
                        if (result) {
                            Toaster.success('Animated content template successfully deleted');
                            this.getData();
                        }
                    },
                    error: Toaster.handleApiError
                });

            deleteDialog.close();
        });
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onActionClicked(event: IActionClickEvent): void {
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.templatesApiService.getTemplateDetails(event.item._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: item => this.openAnimatedContentTemplateModal(item),
                        error: Toaster.handleApiError
                    });
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.templatesApiService.getTemplateDetails(event.item._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: item => this.openDeleteAnimatedContentTemplateDialog(item),
                        error: Toaster.handleApiError
                    });
                break;
            }
            default:
                return Toaster.notYetImplementedError();
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;
        this.setPageIndex();
    }
}
