import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RolesService} from '../../../../api/services/roles.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {RoleDetailContainerService} from '../role-detail-container.service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {ESortOrder, ISortOptionEvent, ITableColumn} from '@relayter/rubber-duck';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {Subscription} from 'rxjs';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UserModel} from '../../../../models/api/user.model';

@Component({
    selector: 'rl-role-users-component',
    templateUrl: 'role-users.component.html',
    styleUrls: ['role-users.component.scss'],
    providers: [PaginatorService],
    standalone: false
})

export class RoleUsersComponent extends RLTableComponent implements OnInit {
    public tableId = 'role-users-overview';
    public roleId: string;
    public items: UserModel[] = [];
    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            sortProperty: 'email'
        }
    ];

    public isLoading = false;
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public usersSubscription = new Subscription();
    public disableNextPage = true;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private rolesService: RolesService,
                private roleDetailContainerService: RoleDetailContainerService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.roleId = this.roleDetailContainerService.getRoleId();

        this.subscribeToRouteParams();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (pagination) => {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;

                    this.updateUrl();

                    this.getUsersForRole();
                }
            });
    }

    private subscribeToRouteParams(): void {
        this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (params) => {
                const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
                const sortProperty = params['sortProperty'];
                const sortOrder = params['sortOrder'] ? params['sortOrder'] : null;
                const searchValue = params['search'] ? params['search'] : null;

                // Only on change get new data
                if (pageIndex !== this.pageIndex ||
                    sortProperty !== this.sortProperty ||
                    sortOrder !== this.sortOrder ||
                    searchValue !== this.searchValue) {
                    this.pageIndex = pageIndex;
                    this.sortProperty = sortProperty;
                    this.sortOrder = sortOrder;
                    this.searchValue = searchValue;

                    this.setPageIndex(this.pageIndex);
                }

                this.updateUrl();
            }
        })
    }

    private updateUrl(): void {
        this.router.navigate([this.getMatrixUrl()], {relativeTo: this.route, replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    /**
     * Get users for role with roleId
     */
    private getUsersForRole(): void {
        if (!this.roleId || !this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ROLE_USERS)) return;

        if (this.usersSubscription && !this.usersSubscription.closed) {
            this.usersSubscription.unsubscribe();
        }

        if (this.roleId) {
            this.usersSubscription = this.rolesService.getUsersForRole(this.roleId,
                this.pageSize,
                (this.pageIndex - 1) * this.pageSize,
                this.sortProperty,
                this.sortOrder,
                this.searchValue
            ).pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: // Front end page index starts from 1 instead of 0, api starts from 0.
                        (users: ARPagedResponseDataModel<UserModel>) => {
                            this.total = users.total;
                            this.items = users.items;
                            this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                        },
                    error: Toaster.handleApiError
                }
            );
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }
}
