import {inject, Injectable} from '@angular/core';
import {RelayterTableDataProvider} from '../../../../classes/relayter-table-data-provider.service';
import {TableSortOptions} from '../../../../api/table-sort-options';
import {ProjectsApiService} from '../../../../api/services/projects.api.service';
import {ProjectModel} from '../../../../models/api/project.model';
import {finalize} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../classes/toaster.class';

@Injectable()
export class ProjectsDataProvider extends RelayterTableDataProvider<ProjectModel> {
    private projectsApiService = inject(ProjectsApiService);

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);
    }

    public retrieveData(): void {
        this.loading = true;

        this.projectsApiService.find(
            this.pageSize,
            0,
            this.sortOptions,
            this.apiCursor.getCursor(this.pageIndex),
            this.searchValue,
            this.filterValues
        ).pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
