import {Component, Inject, OnInit} from '@angular/core';
import {FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {StaticContentRulesetItemModel} from '../models/api/static-content-ruleset-item.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AnchorOption, ScaleOption} from '../static-content-ruleset.constants';
import {StaticContentRulesetService} from '../../../api/services/static-content-ruleset.service';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {
    BaseRulesetItemFormComponent,
    ERulesetContext,
    IFormatRulesetComponentData
} from '../../../forms/rule-form/base-ruleset-item-form.component';
import {Toaster} from '../../../classes/toaster.class';
import {DesignLibraryItemModel} from '../models/api/design-library-item.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {StaticContentRulesetItemGroupModel} from '../models/api/static-content-ruleset-item-group.model';
import {ApiConstants} from '../../../api/api.constant';
import {SortDirection} from '@angular/material/sort';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StringUtil} from '../../../classes/string-util';

export interface IFormatRulesetItemFormComponentData extends IFormatRulesetComponentData {
    ruleSetId: string;
    item?: StaticContentRulesetItemModel;
    ruleProperties: RulePropertyModel[];
    libraryItems: DesignLibraryItemModel[];
}

@Component({
    selector: 'static-content-ruleset-item-form',
    templateUrl: './static-content-ruleset-item-form.component.html',
    styleUrls: ['./static-content-ruleset-item-form.component.scss'],
    standalone: false
})
export class StaticContentRulesetItemFormComponent extends BaseRulesetItemFormComponent implements OnInit {
    public layerControl = new FormControl(null, Validators.required);
    public itemGroupControl = new FormControl(null);
    public xControl = new FormControl(0, Validators.required);
    public yControl = new FormControl(0, Validators.required);
    public anchorControl = new FormControl(AnchorOption.TOP_LEFT.getValue(), Validators.required);
    public wControl = new FormControl(null);
    public hControl = new FormControl(null);
    public scaleControl = new FormControl(null);

    public anchorOptions = AnchorOption.OPTIONS;
    public scaleOptions = ScaleOption.OPTIONS;

    public modalData: IFormatRulesetItemFormComponentData;

    public searchValue: string;

    public libraryItems: DropdownItem<DesignLibraryItemModel>[] = [];
    public itemGroups: StaticContentRulesetItemGroupModel[] = [];

    constructor(fullModalService: FullModalService,
                private ruleSetService: StaticContentRulesetService,
                protected dataCollectionService: DataCollectionService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: IFormatRulesetItemFormComponentData) {
        super(fullModalService, dataCollectionService);
        this.modalData = modalData;
        // Immutable set of items from the InDesign libraries of the format rule set
        this.libraryItems = modalData.libraryItems.map(item => new DropdownItem(item.getTitle(), item));
        this.formGroup = new FormGroup({
            name: this.nameControl,
            libraryItem: this.itemControl,
            layer: this.layerControl,
            itemGroup: this.itemGroupControl,
            x: this.xControl,
            y: this.yControl,
            w: this.wControl,
            h: this.hControl,
            scale: this.scaleControl,
            anchor: this.anchorControl,
            conditions: this.conditionGroups,
            values: this.valueGroups,
        });
    }

    public ngOnInit() {
        this.layerProperty = this.modalData.context === ERulesetContext.ITEMS ? 'items.layer' : 'assetItems.layer';
        this.getLayers();

        super.ngOnInit();
        this.getItemGroups();
    }

    protected initForm(): void {
        const modalData = this.modalData as IFormatRulesetItemFormComponentData;
        if (modalData.item) {
            const foundLibraryItem = this.modalData.libraryItems.find((item) => item.name === modalData.item.libraryItem);
            this.tags = foundLibraryItem?.tags.map((tag) => new DropdownItem<string>(tag, tag)) || [];
            const patch = {
                ...modalData.item,
                libraryItem: foundLibraryItem,
                itemGroup: null,
                x: modalData.item.defaultX,
                y: modalData.item.defaultY,
                w: modalData.item.defaultWidth,
                h: modalData.item.defaultHeight,
                anchor: modalData.item.anchor,
                scale: modalData.item.scale,
                layer: [new DropdownItem(modalData.item.layer, modalData.item.layer)]
            };

            this.formGroup.patchValue(patch);
        }
        super.initForm();
    }

    protected saveRule(): void {
        const conditions = this.getConditions();
        const values = this.getValues();

        const ruleSetItem = new StaticContentRulesetItemModel(
            this.formGroup.value.name,
            this.formGroup.value.libraryItem?.name,
            this.formGroup.value.layer[0].getValue(),
            this.formGroup.value.itemGroup ? this.formGroup.value.itemGroup[0]?.getValue() : undefined,
            Number(this.formGroup.value.x),
            Number(this.formGroup.value.y),
            this.formGroup.value.scale ? Number(this.formGroup.value.w) : undefined,
            this.formGroup.value.scale ? Number(this.formGroup.value.h) : undefined,
            this.formGroup.value.scale ? this.formGroup.value.scale : undefined,
            this.formGroup.value.anchor,
            conditions,
            values);

        const modalData = this.modalData as IFormatRulesetItemFormComponentData;

        const observable = this.modalData.item
            ? this.ruleSetService.updateStaticContentRulesetLibraryItem(modalData.ruleSetId, modalData.item._id, ruleSetItem)
            : this.ruleSetService.postStaticContentRulesetLibraryItem(modalData.ruleSetId, ruleSetItem);

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    Toaster.success(`Successfully ${this.modalData.item ? 'updated' : 'created'} rule: ${ruleSetItem.name}`);
                    this.fullModalService.close(result);
                },
                error: Toaster.handleApiError
            });
    }

    public searchItemGroups(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.itemGroups = [];
            this.getItemGroups();
        }
    }

    public getItemGroups(offset = 0): void {
        this.ruleSetService.getStaticContentRulesetItemGroups(
            this.modalData.ruleSetId,
            20,
            offset,
            'name',
            ApiConstants.API_QUERY_VALUE_ASC as SortDirection,
            this.searchValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    this.itemGroups = results.items;
                    // when creating a new item, item is not in the modalData
                    const value = this.itemGroups.find((i) =>
                        i._id === this.modalData.item?.itemGroup);
                    if (value) this.formGroup.patchValue({itemGroup: [value]});

                },
                error: Toaster.handleApiError
            });
    }

    public getLibraryItems(event: IDropdownRequestDataEvent): void {
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.libraryItems = this.modalData.libraryItems.filter((item) => item.getValue().match(regex)?.length > 0)
                .map(item => new DropdownItem(item.getTitle(), item))
        } else {
            this.libraryItems = this.modalData.libraryItems
                .map(item => new DropdownItem(item.getTitle(), item));
        }
    }
}
