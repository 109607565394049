import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormControlStatus, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    BUTTON_TYPE,
    ButtonConfig,
    DropdownComponent,
    DropdownMultiSelectComponent,
    FullModalActionModel,
    FullModalService,
    InputComponent,
    NUC_FULL_MODAL_DATA,
    NUCComponentsModule,
    NUCInputsModule,
    NUCLabelModule
} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VariantModel} from '../../models/api/variant.model';
import {Toaster} from '../../classes/toaster.class';
import {forkJoin, startWith} from 'rxjs';
import {ModelUtil} from '../../classes/model.util';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {
    AnimatedContentTemplateVariantFormGroup,
    AnimatedContentTemplateVariantPresetComponent
// eslint-disable-next-line max-len
} from '../../pages/relayter/templates/animated-content-template/animated-content-template-variant-preset/animated-content-template-variant-preset.component';
import {ComponentsModule} from '../../components/components.module';
import {EChannel, EDataFieldCollectionName, EFormStatus} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {EEngineType, TemplateBodyModel, TemplateDetailModel} from '../../models/api/template.model';
import {TemplatesApiService} from '../../api/services/templates.api.service';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {VariantsApiService} from '../../api/services/variants.api.service';

export interface IAnimatedContentTemplateModalData {
    template: TemplateDetailModel
}

interface AnimatedContentTemplatePreset {
    afterEffectsProjectFile: FormControl<string>;
    animatedContentRuleset: FormControl<string>;
}

export interface IAnimatedContentTemplateFormGroup {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    engineType: FormControl<EEngineType>;
    channel: FormControl<EChannel>;
    preset: FormGroup<AnimatedContentTemplatePreset>;
    variantsPresets: FormArray<FormGroup<AnimatedContentTemplateVariantFormGroup>>;
}

@Component({
    selector: 'animated-content-template-form',
    templateUrl: './animated-content-template-form.component.html',
    styleUrl: './animated-content-template-form.component.scss',
    imports: [
        ComponentsModule,
        NUCComponentsModule,
        NUCInputsModule,
        ReactiveFormsModule,
        AnimatedContentTemplateVariantPresetComponent,
        NUCLabelModule,
        DropdownComponent,
        InputComponent,
        DropdownMultiSelectComponent
    ],
    standalone: true
})
export class AnimatedContentTemplateFormComponent implements OnInit {
    private templatesApiService = inject(TemplatesApiService);
    private fullModalService = inject(FullModalService);
    private variantsApiService = inject(VariantsApiService);
    private dataFieldsService = inject(DataFieldsApiService)
    private destroyRef: DestroyRef = inject(DestroyRef);
    public channelOptions = [new DropdownItem(EChannel.DIGITAL, EChannel.DIGITAL)];

    private readonly template: TemplateDetailModel;

    // full modal related
    private saveButton: ButtonConfig;

    // form related
    public formGroup: FormGroup<IAnimatedContentTemplateFormGroup>;

    public variantPresetFormArray: FormArray<FormGroup<AnimatedContentTemplateVariantFormGroup>> = new FormArray([]);

    public tagOptions: DropdownItem<string>[] = [];

    public variants: VariantModel[] = [];
    public selectedVariants: string[] = [];
    public variantsEnabled = false;

    public animatedContentEngineTypes = [
        new DropdownItem(EEngineType.AFTER_EFFECTS, EEngineType.AFTER_EFFECTS)
    ];

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IAnimatedContentTemplateModalData) {
        if (this.modalData?.template) this.template = this.modalData.template;
    }

    public ngOnInit(): void {
        this.getData();
    }

    private getData(): void {
        forkJoin([
            this.variantsApiService.findAll(),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variants, dataFields]) => {
                    this.variantsEnabled = dataFields.some(field => field.enableVariants);
                    this.variants = variants;

                    this.initForm();
                    this.initButtons();
                },
                error: Toaster.handleApiError
            })
    }

    private initForm(): void {
        if (this.modalData.template?.variantPresets?.length) {
            this.modalData.template?.variantPresets.forEach((variantPreset) => {
                const variant = this.variants.find(variant => variant._id === variantPreset.variant._id);

                const variantPresetFormGroup = new FormGroup<AnimatedContentTemplateVariantFormGroup>({
                    variant: new FormControl(variant.getValue(), Validators.required),
                    afterEffectsProjectFile: new FormControl(variantPreset.animatedContent.afterEffectsProjectFile._id, Validators.required),
                    animatedContentRuleset: new FormControl(variantPreset.animatedContent.animatedContentRuleset._id, Validators.required),
                })
                this.variantPresetFormArray.push(variantPresetFormGroup);
            })
        }

        if (this.modalData.template?.variantPresets) {
            this.selectedVariants = this.modalData.template.variantPresets.map(preset =>
                this.variants.find(variant => variant._id === preset.variant._id)?.getValue());
        }

        const tags = this.modalData.template?.tags?.map((v) => new DropdownItem(v, v));

        this.formGroup = new FormGroup<IAnimatedContentTemplateFormGroup>({
            name: new FormControl(this.modalData.template?.name, Validators.required),
            tags: new FormControl(tags),
            engineType: new FormControl({value: EEngineType.AFTER_EFFECTS, disabled: true}, Validators.required),
            channel: new FormControl({value: EChannel.DIGITAL, disabled: true}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            preset: new FormGroup<AnimatedContentTemplatePreset>(
                {
                    afterEffectsProjectFile: new FormControl(
                        this.modalData.template?.animatedContent.afterEffectsProjectFile._id,
                        Validators.required),
                    animatedContentRuleset: new FormControl(
                        {value: this.modalData.template?.animatedContent.animatedContentRuleset._id, disabled: true},
                        Validators.required),
                }
            ),
            variantsPresets: this.variantPresetFormArray
        })
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.onSaveAnimatedContentTemplate());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== EFormStatus.VALID);
    }

    private onSaveAnimatedContentTemplate(): void {
        this.saveButton.loading = true;

        const body = ModelUtil.createApiBody(
            TemplateBodyModel.fromAnimatedContentTemplateFormGroup(this.formGroup, this.variants),
            this.template?._id
        );

        if (this.template) {
            this.templatesApiService.put(this.template._id, body)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Animated content template updated successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.templatesApiService.create(body)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Animated content template created successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    // Determine which variant is added/removed
    public onVariantsChanged(variants: string[]): void {
        // Check Removed
        const deletedVariant = this.selectedVariants
            .find(selectedVariant => !variants.find(variant => variant === selectedVariant));
        if (deletedVariant) {
            this.variantPresetFormArray.controls.forEach((control, index) => {
                if (control.value.variant === deletedVariant) {
                    this.variantPresetFormArray.removeAt(index);
                }
            });
        }

        // Check Added
        const addedVariant = variants.find(variant => !this.selectedVariants.find(selectedVariant => selectedVariant === variant));
        this.selectedVariants = variants;

        if (addedVariant) {
            this.createAndInsertVariantPresetFormGroup(addedVariant)
        }
    }

    public createAndInsertVariantPresetFormGroup(addedVariantKey: string): void {
        const variantPresetFormGroup = new FormGroup<AnimatedContentTemplateVariantFormGroup>({
            variant: new FormControl(addedVariantKey, Validators.required),
            afterEffectsProjectFile: new FormControl(null, Validators.required),
            animatedContentRuleset: new FormControl(null, Validators.required),
        });
        const insertAt = this.variants.findIndex(variant => variant.getValue() === addedVariantKey);
        this.variantPresetFormArray.insert(insertAt, variantPresetFormGroup);
    }

    public onTagChanged(tag: string): void {
        this.tagOptions = tag && tag.length && tag.trim().length ? [new DropdownItem<string>(tag.trim(), tag.trim())] : [];
    }

    public getVariant(key: string): VariantModel {
        return this.variants.find(variant => variant.key === key);
    }
}
