import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnSize,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableColumn,
    NucDialogConfigModel, NucDialogService
} from '@relayter/rubber-duck';
import {ActivatedRoute, Router} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {AppConstants} from '../../../app.constants';
import {filter, takeUntil} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {ContentRuleModel} from '../../../models/api/content-rule.model';
import {Subject, Subscription} from 'rxjs';
import {ContentRuleFormComponent, IContentRuleFormData} from './content-rule-form/content-rule-form.component';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {ContentRuleService, EContentRuleJobTypes} from '../../../api/services/content-rule.service';
import {Toaster} from '../../../classes/toaster.class';
import {ARLogger} from '@relayter/core';
import {ContentRuleCollection} from './content-rule.constants';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {GroupModel} from '../../../models/api/group.model';
import ContentRuleApiService from '../../../api/services/content-rule.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-content-rules-component',
    templateUrl: 'content-rules.component.html',
    styleUrls: ['content-rules.component.scss'],
    providers: [PaginatorService],
    standalone: false
})
export class ContentRulesComponent extends RLTableComponent implements OnInit, OnDestroy {
    private dialogService = inject(NucDialogService);
    private contentRuleApiService = inject(ContentRuleApiService);

    public tableId = 'content-rules';
    public collections = ContentRuleCollection.COLLECTIONS;
    public contentRulesSubscription: Subscription;
    public contentRules: ContentRuleModel[];
    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            size: EColumnSize.LARGE,
            sortProperty: 'name'
        },
        {
            title: 'Collection',
            key: 'collectionName',
            size: EColumnSize.BASE,
            sortProperty: 'collectionName'
        },
        {
            title: 'Values',
            key: 'values',
            format: (values: string[]) => values?.join(', ')
        },
        {
            title: 'Content groups',
            key: 'groups',
            format: (values: GroupModel[]) => values?.map(value => value.name).join(', ')
        },
        {
            title: 'Date applied',
            key: 'appliedAt',
            sortProperty: 'appliedAt',
            size: EColumnSize.SMALL,
            format: (date: Date) => RLDatePipe.format(date, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            size: EColumnSize.SMALL,
            format: (date: Date) => RLDatePipe.format(date, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            size: EColumnSize.SMALL,
            format: (date: Date) => RLDatePipe.format(date, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];
    public actionTypes = [];
    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public totalItemCount: number;
    private onDestroySubject = new Subject<void>();
    public disableNextPage = true;

    public permissions = AppConstants.PERMISSIONS;

    constructor(private contentRuleService: ContentRuleService,
                private fullModalService: FullModalService,
                private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private route: ActivatedRoute,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.subscribeToRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getContentRules();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private subscribeToRoute(): void {
        this.route.params.pipe(takeUntil(this.onDestroySubject)).subscribe((params) => {
            this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            this.sortOrder = params.sortOrder;
            this.sortProperty = params.sortProperty;
            this.searchValue = params.search;

            this.updateUrl();
            this.setPageIndex(this.pageIndex);
        });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.UPDATE_CONTENT_RULE)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }

        if (this.userIsAllowedToPipe.transform(this.permissions.DELETE_CONTENT_RULE)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    public updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.SETTINGS,
            AppConstants.CONTEXT_URL.CONTENT_RULES,
            this.getMatrixUrl()
        ], {replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public openContentRuleForm(contentRule?: ContentRuleModel): void {
        const modalData = {contentRule} as IContentRuleFormData;
        const config = new FullModalConfig(
            contentRule ?
                'Edit content rule' :
                'Add new content rule',
            contentRule ?
                'Edit the information for the selected content rule.' :
                'Fill in the information below to create a new content rule.',
            modalData);
        config.confirmClose = true;
        this.fullModalService.open(ContentRuleFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => this.getContentRules());
    }

    private getContentRules(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CONTENT_RULES)) return;

        if (this.contentRulesSubscription) this.contentRulesSubscription.unsubscribe();

        this.contentRulesSubscription = this.contentRuleService
            .getContentRules(this.pageSize, (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder, this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                    this.contentRules = this.formatData(result.items);
                    this.totalItemCount = result.total;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.totalItemCount;
                },
                (error) => Toaster.handleApiError(error));

    }

    private formatData(items: ContentRuleModel[]): ContentRuleModel[] {
        for (const item of items) {
            item.collectionName = this.collections.find((collection) => collection.getValue().collectionName === item.collectionName).getTitle();
        }
        return items;
    }

    public onApplyRulesButtonClicked(): void {
        this.contentRuleService.postJob(EContentRuleJobTypes.APPLY_CONTENT_RULES).subscribe(
            (result) => ARLogger.debug('Job scheduled: ' + result._id),
            (error) => Toaster.handleApiError(error));
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const item = event.item as ContentRuleModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.openContentRuleForm(item);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.openDeleteDialog(item)
                break;
            }
            default:
                return Toaster.notYetImplementedError();
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }

    private openDeleteDialog(item: ContentRuleModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete content rule',
            `Please confirm that you wish to delete '${item.name}'. Make sure you apply content rules to make the change effective.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteContentRule(item);
        });
    }

    private deleteContentRule(item: ContentRuleModel): void {
        this.contentRuleApiService.deleteOne(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: boolean) => {
                    if (res) {
                        Toaster.success('Content rule successfully deleted');
                        this.paginatorService.setPageIndex(this.tableId, this.pageIndex);
                    } else {
                        Toaster.error('There was an error while deleting the content rule');
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
