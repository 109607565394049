import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    ConnectionMappingModel,
    ConnectionModel,
    ProductSynchronisationBody,
    ProductSynchronisationModel
} from '../../../../models/api/connection.model';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {ConnectionService} from '../../../../api/services/connection.service';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {Toaster} from '../../../../classes/toaster.class';
import {EDataFieldCollectionName, EFormStatus} from '../../../../app.enums';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StringUtil} from '../../../../classes/string-util';
import {
    ProductSynchronisationMappingFormComponent
} from './product-synchronisation-mapping-form/product-synchronisation-mapping-form.component';
import {NgForOf} from '@angular/common';

export interface IConnectionSynchronisationFormComponentData {
    connectionId: string;
    synchronisationData?: ProductSynchronisationModel;
}

class ProductSynchronisationFormatForm {
    enabled: FormControl<boolean>;
    mapping: FormArray<FormGroup<ProductMappingFormGroup>>;
    removeLeadingZeros: FormControl<boolean>;
    productDataField: FormControl<string>;
}

class ProductMappingFormGroup {
    dataField: FormControl<string>;
    propertyPath: FormControl<string>;
}

class ProductMapping {
    dataField: DropdownItem<string>;
    propertyPath: string;
}

@Component({
    selector: 'superunie-adam-product-synchronisation-form',
    templateUrl: './superunie-adam-product-synchronisation-form.component.html',
    styleUrls: ['./superunie-adam-product-synchronisation-form.component.scss'],
    imports: [ReactiveFormsModule, RDModule, ProductSynchronisationMappingFormComponent, NgForOf, DropdownComponent]
})
export class SuperunieAdamProductSynchronisationFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    public form: FormGroup<ProductSynchronisationFormatForm>;
    public mappingFormArray: FormArray<FormGroup<ProductMappingFormGroup>>;

    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;
    private productSynchronisation: ProductSynchronisationModel;

    protected dataFields: DropdownItem<string>[];
    public dataFieldOptions: DropdownItem<string>[] = [];

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IConnectionSynchronisationFormComponentData,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsApiService,
                private connectionService: ConnectionService) {
        this.productSynchronisation = this.modalData.synchronisationData;
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
        this.getDataFields();
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.productSynchronisation?._id ? 'Save' : 'Create');
        this.saveButton.disabled = true;
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        this.fullModalService.setConfirmClose(true);

        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));

        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveProductSynchronisation();
        });

        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        this.mappingFormArray = new FormArray<FormGroup<ProductMappingFormGroup>>([], Validators.required)

        this.form = new FormGroup<ProductSynchronisationFormatForm>({
            enabled: new FormControl(this.productSynchronisation?.enabled || false),
            mapping: this.mappingFormArray,
            removeLeadingZeros: new FormControl(this.productSynchronisation?.removeLeadingZeros || false),
            productDataField: new FormControl(null, Validators.required),
        });

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: string) => this.saveButton.disabled = status !== EFormStatus.VALID);
    }

    private getDataFields(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (dataFields) => {
                    this.dataFields = dataFields.map((field) => new DropdownItem(field.name, field._id));
                    this.dataFieldOptions = this.dataFields;
                    this.patchDataFieldOption();
                },
                error: Toaster.handleApiError
            });
    }

    private patchDataFieldOption(): void {
        this.productSynchronisation?.mapping?.forEach((map) => {
            const dataField = this.dataFields.find((dataFieldOption) =>
                dataFieldOption.getValue() === map.dataField);

            this.addMapping({ dataField, propertyPath: map.propertyPath })
        });

        if (this.productSynchronisation?.productDataField) {
            const dataField = this.dataFields.find((dataFieldOption) =>
                dataFieldOption.getValue() === this.productSynchronisation.productDataField._id);

            this.form.patchValue(
                {productDataField: dataField.getValue()}
            );
        }
    }

    private saveProductSynchronisation(): void {
        const formResult = this.form.value;

        const sync = new ProductSynchronisationBody(
            formResult.enabled,
            formResult.mapping as ConnectionMappingModel[],
            formResult.productDataField,
            formResult.removeLeadingZeros
        )

        if (this.productSynchronisation) {
            this.connectionService.updateProductSynchronisation(this.modalData.connectionId, this.productSynchronisation._id, sync)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (result: ConnectionModel) => {
                        this.fullModalService.close(result);
                        Toaster.success('Synchronisation updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.connectionService.createProductSynchronisation(this.modalData.connectionId, sync)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (result: ConnectionModel) => {
                        this.fullModalService.close(result);
                        Toaster.success('Synchronisation created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public addMapping(mapping?: ProductMapping) {
        this.mappingFormArray.push(new FormGroup<ProductMappingFormGroup>({
            dataField: new FormControl(mapping?.dataField?.getValue() || null, Validators.required),
            propertyPath: new FormControl(mapping?.propertyPath || null, Validators.required),
        }));
    }

    public deleteMapping(index: number): void {
        if (this.mappingFormArray.controls[index]) this.mappingFormArray.removeAt(index);
    }

    public searchProductDataFields(event: IDropdownRequestDataEvent): void {
        this.dataFieldOptions = this.filterDropdownItems(this.dataFields, event.search);
    }

    private filterDropdownItems(sourceData: DropdownItem<string>[], search: string): DropdownItem<string>[] {
        try {
            const regex = new RegExp(StringUtil.escapeRegExp(search), 'i');
            if (search) {
                return sourceData.filter((item) => item.getTitle().match(regex)?.length > 0);
            } else {
                return sourceData;
            }
        } catch(err) {
            return sourceData;
        }
    }
}
