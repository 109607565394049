import {Component, signal, WritableSignal} from '@angular/core';
import {TabBarItemModel} from '../../../../../models/ui/tab-bar-item.model';
import {EWorkflowConfigurationProperty} from '../workflow-configuration-property.config';
import {WorkflowConfigurationsDetailsService} from '../workflow-configurations-details.service';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../../classes/toaster.class';
import {AppConstants} from '../../../../../app.constants';

@Component({
    selector: 'workflow-configuration-properties-component',
    templateUrl: 'workflow-configuration-properties.component.html',
    styleUrls: ['workflow-configuration-properties.component.scss'],
    standalone: false
})
export class WorkflowConfigurationPropertiesComponent extends RLBaseComponent {
    // tab bar related
    public tabBarItems: TabBarItemModel<EWorkflowConfigurationProperty>[] =
        Object.values(EWorkflowConfigurationProperty).map((prop, index) => new TabBarItemModel(prop, index, prop));

    private _selectedTab: TabBarItemModel<EWorkflowConfigurationProperty>;

    public get selectedTab(): TabBarItemModel<EWorkflowConfigurationProperty> {
        return this._selectedTab;
    }

    public propertyMetadata: WritableSignal<{property: string, stepId?: string, componentId?: string}> = signal(undefined);

    public set selectedTab(tab: TabBarItemModel<EWorkflowConfigurationProperty>) {
        if (tab !== this._selectedTab && this.tabBarItems.find((t) => t.title === tab.title)) {
            this._selectedTab = tab;
            this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOW, AppConstants.CONTEXT_URL.CONFIGURATIONS,
                this.workflowConfigurationsDetailsService.workflowConfigurationId(), tab.title.toLowerCase()]);
            // every time we set the tab, we get new data
            this.propertyMetadata.set({property: tab.data});
            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
        }
    }

    constructor(private route: ActivatedRoute,
                private router: Router,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {
        super();

        // first time set the tabbar from the url
        const propertyFromURL = this.route.snapshot.params.property;
        const selectedTab = this.tabBarItems.find(
            (tab) => tab.title.toLowerCase() === propertyFromURL);
        if (!selectedTab) Toaster.error(`Could not find workflow configuration property: ${propertyFromURL}`);
        this.selectedTab = selectedTab || this.tabBarItems[0];
    }
}
