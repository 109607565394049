import {Component, inject, OnInit} from '@angular/core';
import {
    EColumnDataType,
    EColumnSize,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IItemClickEvent, ISortOptionEvent,
    ITableColumn,
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCTableModule
} from '@relayter/rubber-duck';
import {Subscription} from 'rxjs';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {OAuthClientModel} from '../../../../../models/api/oauth-client.model';
import {OAuthClientApiService} from '../../../../../api/services/oauth-client.api.service';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {ComponentsModule} from '../../../../../components/components.module';
import {
    OAuthClientFormComponent
} from '../../../../../forms/oauth-client-form/oauth-client-form.component';
import {AppConstants} from '../../../../../app.constants';
import {Router} from '@angular/router';
import {Toaster} from '../../../../../classes/toaster.class';
import {RLDatePipe} from '../../../../../pipes/rl-date.pipe';

@Component({
    selector: 'oauth-clients-overview-component',
    templateUrl: './oauth-clients-overview.component.html',
    styleUrls: ['./oauth-clients-overview.component.scss'],
    imports: [
        NUCButtonsModule,
        NUCButtonBarModule,
        PipesModule,
        NUCTableModule,
        ComponentsModule
    ],
    providers: [PaginatorService]
})
export class OAuthClientsOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'oauth-clients-overview-table';
    private fullModalService = inject(FullModalService);
    private oauthClientService = inject(OAuthClientApiService);
    private paginatorService = inject(PaginatorService);
    private router = inject(Router);

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Grant types',
            key: 'grant_types',
            size: EColumnSize.LARGE,
            format: (v) => v.join(', ')
        },
        {
            title: 'Scopes',
            key: 'scopes',
            size: EColumnSize.LARGE,
            format: (v) => v.join(', ')
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];
    public oauthClients: OAuthClientModel[] = [];
    public disableNextPage = true;
    public subscription: Subscription;
    public pageIndex: number;
    public pageSize: number;
    public loading: boolean;
    public selectionMode: ESelectionMode = ESelectionMode.EMIT;

    constructor() {
        super(inject(UserSettingsStorageService));
    }

    public ngOnInit(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (this.pageSize !== pagination.pageSize || this.pageIndex !== pagination.pageIndex) {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;
                }

                this.getOAuthClients();
            });
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public getOAuthClients(): void {
        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);

        this.subscription = this.oauthClientService.find(this.pageSize, undefined, this.tableSortOptions, cursor)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.oauthClients = result.items;
                    this.disableNextPage = !result.hasNext;

                    if (this.oauthClients.length > 0) {
                        const item = this.oauthClients[this.oauthClients.length - 1];
                        this.newApiCursor.setCursor(this.pageIndex, item);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public openOAuthClientModal() {
        const title = 'Add OAuth client';
        const modalConfig = new FullModalConfig(title, 'Fill in the details.', {});
        modalConfig.confirmClose = true;
        this.fullModalService.open(OAuthClientFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.paginatorService.setPageIndex(this.tableId, 1);
                }
            });
    }

    public onItemClicked(event: IItemClickEvent): void {
        this.router.navigate([AppConstants.OAUTH_CLIENTS_PATH, event.item._id], {skipLocationChange: false});
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(event);
        this.newApiCursor.reset(1, this.tableSortOptions);
        this.paginatorService.setPageIndex(this.tableId, 1);
    }
}
