import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {ComponentsModule} from '../../../../../../components/components.module';
import {
    BUTTON_TYPE,
    FullModalConfig,
    FullModalService,
    NUCButtonBarModule,
    NUCButtonsModule,
    NucDialogConfigModel,
    NucDialogService,
    NUCEmptyStateModule,
    NUCTooltipModule
} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../../../pipes/pipes.module';
import {
    ConnectionEndpointModel,
    ConnectionModel,
    EConnectionType,
    EWebhookProducerCollectionNames
} from '../../../../../../models/api/connection.model';
import {ActivatedRoute} from '@angular/router';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../../classes/toaster.class';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {
    IProducerWebhookEndpointFormComponentData,
    ProducerWebhookEndpointFormComponent
} from '../../../../../../forms/connection-form/producer-webhook/producer-webhook-endpoint-form/producer-webhook-endpoint-form.component';
import {CollectionDisplayNamePipe} from '../../../../../../pipes/collection-display-name.pipe';
import {environment} from '../../../../../../../environments/environment';
import {ConnectionApiService} from '../../../../../../api/services/connection.api.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'standard-connection-detail-webhook-producer',
    imports: [
        ComponentsModule,
        NUCButtonBarModule,
        NUCButtonsModule,
        NUCEmptyStateModule,
        NUCTooltipModule,
        PipesModule,
        CollectionDisplayNamePipe
    ],
    templateUrl: './connection-detail-webhook-producer.component.html',
    styleUrl: './connection-detail-webhook-producer.component.scss'
})
export class ConnectionDetailWebhookProducerComponent extends RLBaseComponent implements OnInit {
    protected readonly EWebhookProducerCollectionNames = EWebhookProducerCollectionNames;
    protected readonly API_SERVER = environment.API_SERVER;

    private activeRoute = inject(ActivatedRoute);
    private connectionApiService = inject(ConnectionApiService)
    private fullModalService = inject(FullModalService);
    private dialogService = inject(NucDialogService);

    private connectionId: string;
    public connection = signal<ConnectionModel>(null);
    public endpoints = computed<ConnectionEndpointModel[]>(() => this.connection()?.endpoints || []);

    public loading: boolean;
    public logo = ConnectionModel.getLogo(EConnectionType.WEBHOOK_PRODUCER);

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
        });
    }

    private getConnection(): void {
        this.loading = true;
        this.connectionApiService.findOne(this.connectionId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => this.connection.set(connection),
                error: Toaster.handleApiError
            });
    }

    public editConnection(): void {
        this.loading = true;
        // Get fresh copy of the connection
        this.connectionApiService.findOne(this.connectionId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (connection: ConnectionModel) => {
                    this.connection.set(connection);

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection: this.connection()});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public addEndpoint(): void {
        const modalConfig = new FullModalConfig('Add producer endpoint',
            'Select and configure a producer endpoint.', {connectionId: this.connectionId} as IProducerWebhookEndpointFormComponentData);
        modalConfig.confirmClose = true;
        const modalRef = this.fullModalService.open(ProducerWebhookEndpointFormComponent, modalConfig);

        modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            res ? this.getConnection() : null;
        });
    }

    public openDeleteDialog(endpointId: string): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete producer endpoint',
            'Please confirm that you wish to delete this producer endpoint.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteEndpoint(endpointId);
        });
    }

    private deleteEndpoint(endpointId: string) {
        this.loading = true;
        this.connectionApiService.deleteEndpoint(this.connectionId, endpointId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Producer endpoint removed successfully');
                    this.getConnection();
                },
                error: Toaster.handleApiError
            });
    }

    public editWebhookEndpoint(endpointId: string) {
        this.loading = true;
        // Get fresh copy of the endpoint
        this.connectionApiService.findOne(this.connectionId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection.set(connection);

                    const currentEndpoint = this.endpoints().find(endpoint => endpoint._id === endpointId);
                    if (!currentEndpoint) {
                        Toaster.error('Endpoint not found');
                        return;
                    }
                    const modalConfig = new FullModalConfig('Edit producer endpoint',
                        'Configure producer endpoint.', {
                            connectionId: this.connectionId,
                            endpointData: currentEndpoint
                        } as IProducerWebhookEndpointFormComponentData);
                    const modalRef = this.fullModalService.open(ProducerWebhookEndpointFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                        res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }
}
