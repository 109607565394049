import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ProjectModel} from '../../models/api/project.model';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent, DropdownMultiSelectComponent,
    FullModalActionModel,
    FullModalService, InputComponent,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ProjectsApiService} from '../../api/services/projects.api.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {EFormStatus} from '../../app.enums';
import {Toaster} from '../../classes/toaster.class';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {ModelUtil} from '../../classes/model.util';

export interface IProjectFormData {
    project?: ProjectModel;
}

interface ProjectFormGroup {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    status: FormControl<string>;
    variants: FormControl<string[]>;
}

@Component({
    selector: 'project-form-component',
    templateUrl: 'project-form.component.html',
    styleUrls: ['project-form.component.scss'],
    imports: [
        ReactiveFormsModule,
        RDModule,
        InputComponent,
        DropdownComponent,
        DropdownMultiSelectComponent
    ]
})

export class ProjectFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    private fullModalService: FullModalService = inject(FullModalService);
    private projectsApiService = inject(ProjectsApiService);
    private variantService = inject(VariantService);
    public modalData: IProjectFormData = inject(NUC_FULL_MODAL_DATA);

    public saveConfig: ButtonConfig;

    private readonly project: ProjectModel;
    public formGroup: FormGroup<ProjectFormGroup>

    public statusOptions = [
        new DropdownItem('New', 'NEW'),
        new DropdownItem('Briefing', 'BRIEFING'),
        new DropdownItem('Production', 'PRODUCTION'),
        new DropdownItem('Ready', 'READY'),
        new DropdownItem('Done', 'DONE')
    ];
    public tagOptions: DropdownItem<string>[];
    public variants: VariantModel[] = [];

    constructor() {
        this.project = this.modalData.project;
    }

    public ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.saveProject());

        this.fullModalService.setModalActions([cancelAction, saveAction]);

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
    }

    private initForm(): void {
        this.formGroup = new FormGroup<ProjectFormGroup>({
            name: new FormControl(this.project?.name || '', Validators.required),
            tags: new FormControl(this.project?.tags.map((tag) => new DropdownItem(tag, tag)) || []),
            status: new FormControl(this.statusOptions.find((status) => status.getValue() === this.project?.status)?.getValue(), Validators.required),
            variants: new FormControl([])
        })

        if (this.project) this.formGroup.controls.variants.disable({ emitEvent: false });

        this.getVariants();
    }

    public getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    this.variants = results.items;

                    if (this.project?.variants?.length) {
                        const selectedVariants = this.variants.filter((variant) =>
                            this.project.variants.some((projectVariant) => projectVariant._id === variant._id))
                            .map(variant => variant.key);
                        this.formGroup.patchValue({variants: selectedVariants});
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public saveProject(): void {
        const formValue = this.formGroup.getRawValue();
        const body = ModelUtil.createApiBody({
            name: formValue.name,
            tags: formValue.tags.map((tag: DropdownItem<string>) => tag.getValue()),
            status: formValue.status,
            variants: this.variants.filter(variant => formValue.variants.includes(variant.key))
                .map(variant => variant._id)
        }, this.project?._id);

        const projectObservable = this.project
            ? this.projectsApiService.put(this.project._id, body)
            : this.projectsApiService.create(body);

        projectObservable.subscribe({
            next: (project: ProjectModel) => {
                const operation = this.project ? 'updated' : 'created';
                Toaster.success(`Successfully ${operation} project`);
                this.fullModalService.close(project);
            },
            error: Toaster.handleApiError
        });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }
}
