import {autoserialize, autoserializeAs} from 'cerialize';
import {DataFieldModel} from './data-field.model';
import {FormGroup} from '@angular/forms';
import {
    EndpointFormModel
} from '../../forms/connection-form/producer-webhook/producer-webhook-endpoint-form/producer-webhook-endpoint-form.component';
import {RegexFilterModel} from './regex-filter.model';

export enum EProducerAction {
    UPSERT = 'UPSERT',
    DELETE = 'DELETE'
}

export enum EConnectionType {
    MEDIA_VALET = 'MEDIA_VALET',
    WEBHOOK_CONSUMER = 'WEBHOOK_CONSUMER',
    SUPERUNIE_ADAM = 'SUPERUNIE_ADAM',
    WEBHOOK_PRODUCER = 'WEBHOOK_PRODUCER'
}

export enum EWebhookEvent {
    CAMPAIGN_CREATED = 'CAMPAIGN_CREATED',
    PRODUCT_UPDATED = 'PRODUCT_UPDATED',
    ASSET_CREATED = 'ASSET_CREATED',
    ASSET_UPDATED = 'ASSET_UPDATED',
}

export enum EWebhookProducerCollectionNames {
    CAMPAIGN_ITEM = 'RLCampaignItem',
    PRODUCT = 'RLProduct',
    ASSET = 'RLAsset',
}

export class WebhookEvent {
    @autoserialize public Name: string;
}

export class WebhookMetaData {
    @autoserialize public dataFieldName: string;
    @autoserialize public name: string;
}

export class WebhookModel {
    @autoserialize public _id: string;
    @autoserializeAs(WebhookEvent) public Events: WebhookEvent[];
    @autoserialize public categories: string[];
    @autoserialize public SubscriptionId: string;
    @autoserialize public Status: string;
    @autoserializeAs(Object) public Data: { webhook: string };
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];
}

export class WebhookPostBody {
    @autoserialize public event: string;
    @autoserialize public categories: string[];
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(event: string, categories: string[], metaData?: WebhookMetaData[]) {
        this.event = event;
        this.categories = categories;
        this.metaData = metaData;
    }
}

export class WebhookPatchBody {
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(metaData: WebhookMetaData[]) {
        this.metaData = metaData;
    }
}

export class ConsumerSettingsModel {
    @autoserialize public secret: string;
    @autoserialize public authentication: string;
}

export class SynchronisationModel {
    @autoserialize public images: ImageSynchronisationModel[];
    @autoserialize public products: ProductSynchronisationModel[];
}

export class ImageSynchronisationModel {
    @autoserialize public _id: string;
    @autoserialize public enabled: boolean;
    @autoserialize public regexFilter: RegexFilterModel;
    @autoserialize public productDataField: DataFieldModel;
    @autoserialize public removeLeadingZeros: boolean;
}

export class ImageSynchronisationBody {
    @autoserialize public enabled: boolean;
    @autoserialize public regexFilter: RegexFilterModel;
    @autoserialize public productDataField: string;
    @autoserialize public removeLeadingZeros: boolean;

    constructor(enabled: boolean,
                regexFilter: RegexFilterModel,
                productDataField: string,
                removeLeadingZeros: boolean) {
        this.enabled = enabled;
        this.removeLeadingZeros = removeLeadingZeros;
        this.regexFilter = regexFilter || null;
        this.productDataField = productDataField || null;
    }
}

export class ProductSynchronisationModel {
    @autoserialize public _id: string;
    @autoserialize public enabled: boolean;
    @autoserialize public mapping: ConnectionMappingModel[];
    @autoserialize public productDataField: DataFieldModel;
    @autoserialize public removeLeadingZeros: boolean;
}

export class ProductSynchronisationBody {
    @autoserialize public enabled: boolean;
    @autoserialize public mapping: ConnectionMappingModel[];
    @autoserialize public productDataField: string;
    @autoserialize public removeLeadingZeros: boolean;

    constructor(enabled: boolean,
                mapping: ConnectionMappingModel[],
                productDataField: string,
                removeLeadingZeros: boolean) {
        this.enabled = enabled;
        this.mapping = mapping || null;
        this.productDataField = productDataField || null;
        this.removeLeadingZeros = removeLeadingZeros;
    }
}

export class ConnectionMappingModel {
    @autoserialize public dataField: string;
    @autoserialize public propertyPath: string;
    constructor(dataField: string, propertyPath: string) {
        this.dataField = dataField;
        this.propertyPath = propertyPath;
    }
}

export class ConnectionEventModel {
    @autoserialize public _id: string;
    @autoserialize public event: EWebhookEvent;
    @autoserialize public endpoint: string;
}

export class ConnectionEventBody {
    @autoserialize public event: EWebhookEvent;
    @autoserialize public endpoint: string;

    constructor(event: EWebhookEvent, endpoint: string) {
        this.event = event;
        this.endpoint = endpoint;
    }
}

export class ConnectionEventPatchBody {
    @autoserialize public event: EWebhookEvent;
    @autoserialize public endpoint: string;

    constructor(event?: EWebhookEvent, endpoint?: string) {
        if (event) this.event = event;
        if (endpoint) this.endpoint = endpoint;
    }
}

export class ConnectionEndpointMappingModel {
    @autoserialize public path: string;
    @autoserialize public property: string;
    constructor(path: string, property: string) {
        this.path = path;
        this.property = property;
    }
}

export class ConnectionEndpointModel {
    @autoserialize public _id: string;
    @autoserialize public collectionName: EWebhookProducerCollectionNames;
    @autoserialize public action: EProducerAction;
    @autoserialize public identifierPath: string;
    @autoserialize public identifierField: string;
    @autoserialize public importMasterBriefing: boolean;
    @autoserialize public campaignNamePath: string;
    @autoserialize public campaignStartDatePath: string;
    @autoserialize public campaignEndDatePath: string;
    @autoserialize public assetPath: string;
    @autoserialize public script: string;
    @autoserializeAs(ConnectionEndpointMappingModel) public mappings: ConnectionEndpointMappingModel[];
}

export class ConnectionModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    // MEDIA_VALET related properties
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public authUrl: string;
    @autoserialize public hasRefreshToken: boolean;
    @autoserialize public subscriptionKey: string;
    @autoserializeAs(WebhookModel) public webhooks: WebhookModel[];
    // WEBHOOK_CONSUMER related properties
    @autoserializeAs(ConnectionEventModel) public events: ConnectionEventModel[];
    @autoserializeAs(ConsumerSettingsModel) public consumerSettings: ConsumerSettingsModel;
    // SUPERUNIE_ADAM related properties
    @autoserialize public apiKey: string;
    @autoserialize public globalLocationNumbers: string[];
    @autoserializeAs(SynchronisationModel) public synchronisations: SynchronisationModel;
    // WEBHOOK_PRODUCER related properties
    @autoserialize public authenticationToken: string;
    @autoserialize public secret: string;
    @autoserialize public timestamp: boolean;
    @autoserializeAs(ConnectionEndpointModel) public endpoints: ConnectionEndpointModel[];

    public static getLogo(connectionType: EConnectionType): string {
        switch (connectionType) {
            case EConnectionType.MEDIA_VALET:
                return '/assets/images/logos/media_valet_logo.png';
            case EConnectionType.WEBHOOK_CONSUMER:
                return '/assets/images/logos/webhook_consumer_logo.png';
            case EConnectionType.WEBHOOK_PRODUCER:
                return '/assets/images/logos/webhook_producer_logo.png';
            case EConnectionType.SUPERUNIE_ADAM:
                return '/assets/images/logos/superunie_adam_logo.jpeg';
            default:
                return '';
        }
    }
}

export class ConnectionApiModel {
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public subscriptionKey: string;
    @autoserialize public apiKey: string;
    @autoserialize public globalLocationNumbers: string[];
    @autoserializeAs(SynchronisationModel) public synchronisations: SynchronisationModel;
    @autoserialize public authenticationToken: string;
    @autoserialize public secret: string;
    @autoserialize public timestamp: boolean;

    constructor(name: string, type: EConnectionType, clientId?: string, clientSecret?: string,
                subscriptionKey?: string, apiKey?: string, globalLocationNumbers?: string[]) {
        this.name = name;
        this.connectionType = type;
        if (clientId) this.clientId = clientId;
        if (clientSecret) this.clientSecret = clientSecret;
        if (subscriptionKey) this.subscriptionKey = subscriptionKey;
        if (apiKey) this.apiKey = apiKey;
        if (globalLocationNumbers) this.globalLocationNumbers = globalLocationNumbers;
    }
}

export class ConnectionEndpointBody {
    @autoserialize public collectionName: EWebhookProducerCollectionNames;
    @autoserialize public action: EProducerAction;
    @autoserialize public identifierPath: string;
    @autoserialize public identifierField: string;
    @autoserialize public importMasterBriefing: boolean;
    @autoserialize public campaignNamePath: string;
    @autoserialize public campaignStartDatePath: string;
    @autoserialize public campaignEndDatePath: string;
    @autoserialize public assetPath: string;
    @autoserialize public script: string;
    @autoserializeAs(ConnectionEndpointMappingModel) public mappings: ConnectionEndpointMappingModel[];

    static fromFormGroup(formGroup: FormGroup<EndpointFormModel>, id?: string): ConnectionEndpointBody {
        if (!formGroup.valid) throw 'Form not valid.';

        const body = new ConnectionEndpointBody();

        body.collectionName = formGroup.controls.model.value;
        body.action = formGroup.controls.action.value;
        body.identifierPath = formGroup.controls.path.value;
        body.identifierField = formGroup.controls.field.value;
        body.importMasterBriefing = formGroup.controls.masterBriefing?.value || (id ? null : undefined);
        body.campaignNamePath = formGroup.controls.campaignNamePath?.value || (id ? null : undefined);
        body.campaignStartDatePath = formGroup.controls.campaignStartDatePath?.value || (id ? null : undefined);
        body.campaignEndDatePath = formGroup.controls.campaignEndDatePath?.value || (id ? null : undefined);
        if (formGroup.controls.assetPath?.value)
            body.assetPath = formGroup.controls.assetPath.value;
        body.script = formGroup.controls.script?.value || (id ? null : undefined);
        if (formGroup.controls.mappings?.value) body.mappings = formGroup.controls.mappings.value.map(
            (mapping) => new ConnectionEndpointMappingModel(mapping.path, mapping.property.path));
        return body;
    }
}

export class ConnectionPatchModel {
    @autoserialize public apiKey: string;
    @autoserialize public hasRefreshToken: boolean;

    constructor(apiKey?: string) {
        this.apiKey = apiKey;
    }
}
