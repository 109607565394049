import {autoserializeAs, inheritSerialization} from 'cerialize';
import {ICollectionViewItem} from '../interfaces/collection-view-item.interface';
import {AssetModel, EAssetType} from './asset.model';
import {ICardMediaType} from '@relayter/rubber-duck';
import {PublicationModel, PublicationPostModel} from './publication.model';

@inheritSerialization(PublicationModel)
export class WorkflowModel extends PublicationModel implements ICollectionViewItem {
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;

    getSubtitle(): string {
        return '';
    }

    getThumbnailAsset(): AssetModel {
        return undefined;
    }

    getThumbnailMedia(): ICardMediaType {
        return {
            url: 'assets/images/empty_states/default-small.svg',
            type: EAssetType.IMAGE
        };
    }

    getThumbnailURL(): string {
        return '';
    }
}

export class WorkflowPostModel extends PublicationPostModel {}
