import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {WorkflowModel, WorkflowPostModel} from '../../models/api/workflow.model';
import {ARPagedResponseDataModel} from '@relayter/core';
import {NewCursor} from '../new-api-cursor';
import {ApiPostRequest} from '../requests/api-post.request';

@Injectable({
    providedIn: 'root'
})
export class WorkflowsApiService extends ApiService<WorkflowModel> {
    constructor() {
        super([ApiConstants.API_GROUP_WORKFLOWS], WorkflowModel);
    }

    public getWorkflows(
        projectId: string,
        limit?: number,
        offset?: number,
        cursor?: NewCursor,
        searchValue?: string,
    ): Observable<ARPagedResponseDataModel<WorkflowModel>> {
        const request = new ApiPagedRequest(
            this.groups, WorkflowModel, limit, offset, undefined, cursor, searchValue);
        request.additionalQueryParams.addParam('projectId', projectId);

        return this.apiRequestService.find(request);
    }

    public postWorkflowForProject(projectId: string, workflowBody: WorkflowPostModel) {
        const request = new ApiPostRequest(
            this.groups, WorkflowModel, workflowBody);
        request.additionalQueryParams.addParam('projectId', projectId);

        return this.apiRequestService.post(request);
    }
}
